import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  ControlConfig,
  DynamicFormComponent,
} from '@wp-back-office/shared/dynamic-components';

@Component({
  selector: 'wp-back-office-template-form',
  templateUrl: './template-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateFormComponent implements OnInit {
  jsonForm = new FormGroup<any>({});
  controls?: ControlConfig[] = [];
  /**
   * Componente de informacion de contacto.
   */
  @ViewChild(DynamicFormComponent, { static: false })
  public formProductGeneralInformationComponent?: DynamicFormComponent;
  valid: boolean;

  constructor(private fb: FormBuilder) {
    this.valid = false;
    this.jsonForm = this.fb.group({
      json: [''],
    });
  }

  ngOnInit(): void {
    this.jsonForm.valueChanges.subscribe(data => {
      const format = data.json.replace(/\\n/g, '<br />');
      try {
        this.controls = JSON.parse(format);
        this.valid = true;
      } catch (e) {
        this.valid = false;
      }
    });
  }
}
