<mat-form-field *ngIf="showInput" class="input-tasks-default dropdown" [ngClass]="{'table-actions': actionsTable}">
    <mat-select [(value)]="selectItemDropdown" (selectionChange)="selectDropdown()" [disabled]="stateDisabled">
        <mat-option *ngFor="let cat of listCatalogue; index as i;" [value]="cat.code">
            {{cat.description | uppercase}}
        </mat-option>
    </mat-select>
    <div class="actions">
        <mat-icon (click)="changeDropdown()" aria-hidden="false" aria-label="Example home icon">edit</mat-icon>
    </div>
</mat-form-field>

<div *ngIf="!showInput">
    <div *ngIf="catalogue">
        {{label.description}}
    </div>
</div>