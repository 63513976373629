<mat-card class="pt-2 pb-2">
  <wp-back-office-dynamic-header-table
    [title]="'Ingrese un json para renderizar'"
    [isCreate]="false"></wp-back-office-dynamic-header-table>
  <mat-card-content>
    <form [formGroup]="jsonForm">
      <mat-form-field appearance="outline">
        <mat-label>Json entrada</mat-label>
        <textarea matInput formControlName="json"></textarea>
      </mat-form-field>
    </form>
  </mat-card-content>
</mat-card>

<mat-card class="pt-2 pt-4 mt-4">
  <mat-card-content>
    <div
      *ngIf="valid === false; else form"
      class="d-flex justify-content-center">
      <h1>El valor del json no es valido</h1>
    </div>
    <ng-template #form>
      <h1>Valores del formulario</h1>
      <p>
        {{
          formProductGeneralInformationComponent?.formGroupGeneric?.value | json
        }}
      </p>
      <mat-divider></mat-divider>
      <h1>Formulario</h1>
      <wp-back-office-dynamic-form
        [controls]="this.controls"></wp-back-office-dynamic-form>
    </ng-template>
  </mat-card-content>
</mat-card>
