import { ComponentType } from '@angular/cdk/portal';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Type,
  ViewChild,
} from '@angular/core';
import { FactoryDialogComponentDirective } from '../../directive/factory-dialog-component.directive';

/**
 * Plantilla para dialogos con eventos submit.
 */
@Component({
  selector: 'wp-back-office-dynamic-dialog-submit',
  templateUrl: './dynamic-dialog-submit.component.html',
  styleUrls: ['./dynamic-dialog-submit.component.scss'],
})
export class DynamicDialogSubmitComponent implements OnInit, AfterViewInit {
  /**
   * Entrada del titulo.
   */
  @Input()
  public title?: string;
  /**
   * Informacion de la tabla.
   */
  @Input()
  public inputComponent?: ComponentType<any>;
  /**
   * Informacion de la tabla.
   */
  @Input()
  public disabledSubmit: boolean;
  /**
   * Directiva para el contenedor.
   */
  @ViewChild(FactoryDialogComponentDirective, { static: false })
  public container?: FactoryDialogComponentDirective;


  /**
   * Crea una isntancia de DynamicDialogSubmitComponent.
   */
  constructor(public changeDetectorRef: ChangeDetectorRef) {
    this.title = 'Prueba';
    this.disabledSubmit = false;
  }

  /**
   * A callback method that is invoked immediately after the default change detector.
   */
  public ngOnInit(): void {}

  /**
   *A callback method that is invoked immediately after Angular has completed initialization of a component's view. It is invoked only once when the view is instantiated.
   */
  public ngAfterViewInit(): void {
    if (this.inputComponent) {
      this.container?.viewContainerRef.clear();
      const componentRef = this.container?.viewContainerRef.createComponent(
        this.inputComponent
      );
      // componentRef?.instance.editValue.pipe.subscribe((resp: string) => {

      // });
    }
  }
}
