/**
 *Interfaz de tabla concesionarios.
 */
export interface BranchOffices<T> {
  /**
   * Código de la oficina.
   */
  id: number;
  /**
   * Descripción de la oficina.
   */
  name: string;
  /**
   * Nombre de la marca.
   */
  nit: string;
  /**
   * Tipo de mercado.
   */
  description_market: string;
  /**
   * Grupo.
   */
  group: string;
  /**
   * Estado.
   */
  enabled: boolean;
  /**
   * Datos.
   */
  object: T
}

/**
 *Interfaz de bancos.
 */
export interface Banks {
  /**
   * Código del banco.
   */
  code_bank: number;
  /**
   * Descripción del banco.
   */
  description: string;
  /**
   * Fecha de creación.
   */
  created_date: string;
  /**
   * Fecha de actualización.
   */
  updated_date: string;
}

/**
 *Interfaz de grupos de oficina.
 */
export interface OfficeGroups {
  /**
   * Código del grupo de oficina.
   */
  code_office_group: number;
  /**
   * Descripción del grupo de oficina.
   */
  description: string;
}

/**
 *Interfaz de tabla concesionarios.
 */
export interface TableBeneficiaries {
  /**
   * Nombre del beneficiario.
   */
  name: string;
  /**
   * Identificación del beneficiario.
   */
  id: string;
  /**
   * Tipo de mercado.
   */
  service: string;
}

/**
 *Interfaz de beneficiarios.
 */
export interface Beneficiaries {
  /**
   * Nombre del beneficiario.
   */
  first_name: string;
  /**
   * Apellido del beneficiario.
   */
  first_surname: string;
  /**
   * Tipo de identificacion del beneficiario.
   */
  identification_type: string;
  /**
   * Número de identificación.
   */
  identification_number: string;
  /**
   * Código del servicio.
   */
  code_service: string;
}

/**
 *Interfaz de marcados.
 */
export interface Markets {
  /**
   * Código del mercado.
   */
  code_market: number;
  /**
   * Descripción del mercado.
   */
  description: string;
  /**
   * Tipo de precio.
   */
  type_price: number;
  /**
   * Fecha de creación.
   */
  created_date: string;
  /**
   * Fecha de actualización.
   */
  updated_date: string;
}
