export * from './contents/catalogue-type-documents.reducers';
export * from './contents/catalogue-type-customers.reducers';
export * from './contents/catalogue-countries.reducers';
export * from './contents/catalogue-level-of-schooling.reducers';
export * from './contents/catalogue-type-of-welling.reducers';
export * from './contents/catalogue-marital-status.reducers';
export * from './contents/catalogue-income-frequency.reducers';
export * from './contents/catalogue-currency-type.reducers';
export * from './contents/catalogue-type-job.reducers';
export * from './contents/catalogue-type-of-egress.reducers';
export * from './contents/catalogue-asset-type.reducers';
export * from './contents/catalogue-type-of-phone.reducers';
export * from './contents/catalogue-type-of-liabilities.reducers';
export * from './contents/catalogue-type-bank-accounts.reducers';
export * from './contents/catalogue-banks.reducers';
export * from './contents/catalogue-documents-approval-status.reducers';
export * from './contents/catalogue-departments.reducers';
export * from './contents/catalogue-cities.reducers';
export * from './contents/catalogue-family-relationships.reducers';
export * from './contents/catalogue-gender.reducers';
export * from './contents/catalogue-legalreview-approval-status.reducers';
export * from './contents/catalogue-level1-approval-status.reducers';
export * from './contents/catalogue-reference-return-reasons.reducers';
export * from './contents/catalogue-type-address.reducers';
export * from './contents/catalogue-verificationkyc-approval-status.reducers';
export * from './contents/catalogue-applicant-type.reducers';
export * from './contents/catalogue-type-of-references.reducers';
export * from './contents/catalogue-products.reducers';
export * from './contents/catalogue-terms.reducers';
export * from './contents/catalogue-loan-applicant.reducer';
export * from './contents/catalogue-product-category.reducers';
export * from './contents/catalogue-product-type.reducers';
export * from './contents/catalogue-amortization-credits.reducers';
export * from './contents/catalogue-vehicle-segment.reducers';
export * from './contents/catalogue-type-person.reducers';
export * from './contents/catalogue-customer-classification.reducers';
export * from './contents/catalogue-funding-type.reducers';
export * from './contents/catalogue-reference-rate.reducers';
export * from './contents/catalogue-income-type.reducers';
export * from './contents/catalogue-first-extra-quote.reducers';
export * from './contents/catalogue-type-person-2.reducers';
export * from './contents/catalogue-unit-amount.reducers';
export * from './contents/catalogue-unit-quota.reducers';
export * from './contents/catalogue-type-service.reducers';
export * from './contents/catalogue-type-plan.reducers';
export * from './contents/catalogue-type-market.reducers';
export * from './contents/catalogue-query.reducers';
export * from './contents/catalogue-legalization-documents.reducers';
export * from './contents/catalogue-sbs-cities.reducers';
export * from './contents/catalogue-sbs-gender.reducers';
export * from './contents/catalogue-cities-co.reducers';
export * from './contents/catalogue-score-buro.reducers';
export * from './contents/catalogue-armore-level.reducers';
export * from './contents/catalogue-default-values-insurers.reducers';
export * from './contents/catalogue-list-insurers.reducers';
export * from './contents/catalogue-general.reducers';
