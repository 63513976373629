import { ActionReducerMap } from '@ngrx/store';

import * as reducers from './reducers';

/**
 * Modelo estado de procesos.
 */
export interface ProcessState {
  /**
   * Iniciar tarea.
   */
  StartProcess?: reducers.StartProcessState;
  /**
   * Tareas completadas.
   */
  TaskSuccess?: reducers.TaskSuccessState;
  /**
   * Iniciar tarea.
   */
  TaskStart?: reducers.StartTaskState;
  /**
   * Formularios tareas.
   */
  TaskSchema?: reducers.TaskSchemaState;
  /**
   * Objecto de radicacion.
   */
  Radication?: reducers.StepProcessState;
  /**
   * Objecto de radicacion.
   */
  Payload?: reducers.PayloadState;
  /**
   * Verificacion de documentos.
   */
  CheckContactInfo: reducers.CheckContactInfoState;
}

export const ProcessReducers: ActionReducerMap<ProcessState> = {
  StartProcess: reducers.StartProcessReducer,
  TaskSuccess: reducers.TaskSuccessReducer,
  TaskStart: reducers.StartTaskReducer,
  TaskSchema: reducers.TaskSchemaReducer,
  Radication: reducers.StepProcessReducer,
  Payload: reducers.PayloadReducer,
  CheckContactInfo: reducers.CheckContactInfoReducer,
};
