<div class="actions">
  <div class="btn-action">
    <button (click)="closeTask()" class="button-primary " mat-stroked-button>
      <div class="btn-container">
        <div class="loading">
          <div [ngStyle]="{'opacity': loadingCancel ? 1 : 0}" class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div [ngStyle]="{'opacity': !loadingCancel ? 1 : 0}" class="content">Salir</div>
      </div>
    </button>
  </div>
  <div class="btn-action">
    <button class="button-primary " mat-stroked-button>
      <div class="btn-container">
        <div class="loading">
          <div [ngStyle]="{'opacity': loadingSave ? 1 : 0}" class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div [ngStyle]="{'opacity': !loadingSave ? 1 : 0}" class="content">Guardar</div>
      </div>
    </button>
  </div>
  <div class="btn-action">
    <button [disabled]="loadingComplete" (click)="completeTask()" class="button-primary " mat-stroked-button>
      <div class="btn-container">
        <div class="loading">
          <div [ngStyle]="{'opacity': loadingComplete ? 1 : 0}" class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div [ngStyle]="{'opacity': !loadingComplete ? 1 : 0}" class="content">Completar</div>
      </div>
    </button>
  </div>
</div>

<ng-template #mymodal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Bootstrap Modal</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    Resultado de las acciones: <b>{{messageModal}}</b>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="successTask()">Ok</button>
  </div>
</ng-template>

