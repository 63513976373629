/**
 * Enumerador del modo.
 */
export enum MODE {
  ADD = 'add',
  EDIT = 'edit',
  VIEW = 'view',
  CREATE = 'create',
  LOG = 'log',
}
