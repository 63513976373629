import { Catalogue } from './catalogue.interface';

/**
 * Modelo catalogo SBS.
 */
export interface CatalogueSBS extends Catalogue {
  /**
   * Valor.
   */
  sbs_code: string;
}
