import { Catalogue } from '@wp-back-office/core/commons-backoffice';

/**
 * Genera catalogos de prueba.
 * @param length - Longitud.
 * @param catalogue - Nombre del catalogo.
 * @param prefix - Prefijo.
 * @returns - Catalogos.
 */
export function mockCatalogue(
  length: number,
  catalogue: string,
  prefix = 'MOCK'
): Catalogue[] {
  return Array(length)
    .fill(undefined)
    .map((_, index) => ({
      code: index.toString(),
      description: `${prefix}_${index}_${catalogue}`,
    }));
}
