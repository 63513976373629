<ng-template [ngIf]="controlConfig && controlConfig.key && controlConfig.type">
  <wp-back-office-dynamic-table-crud
    #tableID
    [mode]="controlConfig.tableControlConfig?.options?.actions === undefined ? mode.VIEW : mode.ADD"
    [title]="controlConfig.tableControlConfig?.title || ''"
    [subtitle]="controlConfig.tableControlConfig?.subtitle || ''"
    [formTitle]="controlConfig.tableControlConfig?.formTitle || ''"
    [isCreate]="controlConfig.tableControlConfig?.isCreate || false"
    [columns]="controlConfig.tableControlConfig?.columns || []"
    [options]="controlConfig.tableControlConfig?.options"
    [data]="value"
    [addButtonPosition]="
      controlConfig.tableControlConfig?.addButtonPosition || 'top'
    "
    [addButtonTitle]="controlConfig.tableControlConfig?.addButtonTitle || ''"
    [controls]="
      controlConfig.tableControlConfig?.controls || []
    "></wp-back-office-dynamic-table-crud>
</ng-template>
