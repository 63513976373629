import { Component, Input, OnInit } from '@angular/core';
import { UpdateDataTaskService } from '@wp-back-office/core/workflow-sdk';
import { CatalogueTasksService } from '@wp-back-office/core/commons-backoffice';
import { takeUntil, Subject } from 'rxjs';

@Component({
  selector: 'app-input-dropdown',
  templateUrl: './input-dropdown.component.html',
  styleUrls: ['./input-dropdown.component.scss'],
})
export class InputDropdownComponent implements OnInit {
  @Input() label: any;
  @Input() key = '';
  @Input() actionsTable = false;
  @Input() catalogue!: any;

  listCatalogue: any[] = [];
  textInput: any = '';
  stateDisabled = true;
  enabledInputs: string[] = [];

  showInput = false;
  editableInput = false;

  selectItemDropdown = '';

  /**
   * Destructor sujeto
   */
  private destroy$ = new Subject();

  constructor(
    private updateDataTaskSerrvice: UpdateDataTaskService,

  ) {
    this.enabledInputs = this.updateDataTaskSerrvice.updateInputs;
  }

  ngOnInit(): void {
    this.textInput = this.label;

    this.enabledInputs.forEach((input: string) => {
      if (this.key.startsWith(input)) {
        this.showInput = true;
      }
    });

    this.listCatalogue = [this.label];
    this.selectItemDropdown = this.label.code;
  }

  /**
   * A callback method that performs custom clean-up, invoked immediately before a directive, pipe, or service instance is destroyed.
   */
  public ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  selectDropdown() {
    this.textInput = this.listCatalogue.find(
      (ev: any) => ev.code === this.selectItemDropdown
    );
    this.successChange();
  }

  successChange() {
    this.stateDisabled = true;

    this.updateDataTaskSerrvice.dataUpdateTask.next({
      key: this.key,
      value: this.textInput,
    });
  }

  changeDropdown() {
    this.stateDisabled = false;
    // this.catalogueService
    //   .getCatalogueTasks(
    //     this.catalogue.entity,
    //     this.catalogue.entityId,
    //     this.catalogue.parent
    //   )
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((ev: any[]) => {
    //     this.listCatalogue = [this.label, ...ev];
    //   });
  }
}
