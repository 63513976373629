import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  TableColumns,
  DynamicTableComponent,
  TableConfig,
  InputModel,
  TableButtonAction,
  ControlConfig,
  TableOptions,
} from '@wp-back-office/shared/dynamic-components';
import { Subject, takeUntil } from 'rxjs';
import { DialogAddDataComponent } from '../modals/dialog-add-data/dialog-add-data.component';
import { MODE } from '../../../../../../../app/admin-products/src/lib/models/mode.enum';
import { AsociatedPep } from '@wp-back-office/shared/process-components';

/**
 * Tabla crud dinamica.
 */
@Component({
  selector: 'wp-back-office-dynamic-table-crud',
  templateUrl: './dynamic-table-crud.component.html',
})
export class DynamicTableCrudComponent implements OnInit, OnDestroy {
  /**
   * Componente tablas dinamicas.
   */
  @ViewChild(forwardRef(() => DynamicTableComponent), {
    static: true,
  })
  public dynamicTableComponent!: DynamicTableComponent;

  /**
   * Tipo de componente.
   */
  @Input()
  public title: string;

  /**
   * Tipo de componente.
   */
  @Input()
  public subtitle: string;

  /**
   * Tipo de componente.
   */
  @Input()
  public formTitle: string;

  /**
   * Boton de crear o no.
   */
  @Input()
  public isCreate: boolean;

  /**
   * Tipo de componente.
   */
  @Input()
  public columns: TableColumns[] = [];

  /**
   * Opciones de la tabla.
   */
  @Input()
  public options!: TableOptions | undefined;

  /**
   * Oculta el header.
   */
  @Input()
  public addButtonPosition!: 'top' | 'bottom' | 'hidden';

  /**
   * Titulo del boton de agregar.
   */
  @Input()
  public addButtonTitle!: string;

  /**
   * Tipo de componente.
   */
  @Input()
  public controls: ControlConfig[];

  /**
   * Data de la tabla.
   */
  @Input()
  public set data(value: any[]) {
    this.replaceData(value || []);
  }

  /**
   * Data de inicialización para la tabla.
   */
  @Input()
  public defaultData:
    | Record<
        string,
        string | number | boolean | Record<string, string | number | boolean>
      >[]
    | AsociatedPep[]
    | undefined;

  /**
   * Input con los datos por defecto de la tabla.
   */
  @Input()
  public mode: MODE;

  /**
   * Evento submit de la tabla envio de datos.
   */
  @Output()
  public dataTableSubmit = new EventEmitter<string>();

  /**
   * Emite la data de la fila editada.
   */
  @Output()
  public dataChanged = new EventEmitter<any>();

  /**
   * Destructor sujeto.
   */
  public destroy$ = new Subject();

  /**
   * Tabla del componente dynamic-table.
   */
  public appTableConfig!: TableConfig;

  /**
   * Loader de la tabla.
   */
  public loading!: boolean;

  /**
   * Entrada de configuracion edición de la tabla.
   */
  public inputEdit?: InputModel;

  /**
   * Entrada de configuracion vista de la tabla.
   */
  public inputView?: InputModel;

  /**
   * Crea una instancia de la clase.
   * @param matDialog - Servicio de dialogs dinamicos.
   */
  constructor(private matDialog: MatDialog) {
    this.formTitle = '';
    this.loading = false;
    this.controls = [];
    this.title = '';
    this.subtitle = '';
    this.isCreate = true;
    this.mode = MODE.ADD;
    this.defaultData = [];
  }

  /**
   * Se ejecuta inicia la aplicacion.
   */
  public ngOnInit(): void {
    this.appTableConfig = this.initTable;
    this.inputEdit = {
      component: DialogAddDataComponent,
      dialogConfig: {
        disableClose: true,
        width: '85%',
        maxWidth: '700px',
        data: {
          formTitle: this.formTitle,
          controls: this.controls,
        },
      },
    };
    this.inputView = {
      component: DialogAddDataComponent,
      dialogConfig: {
        disableClose: true,
        width: '85%',
        maxWidth: '700px',
        data: {
          closeOnOtpVerification: true,
          buttonAddText: 'Aceptar',
          formTitle: this.formTitle,
          controls: this.controls.map(control => {
            return {
              ...control,
              validators: {
                ...control.validators,
                readonly: true,
              },
            };
          }),
        },
      },
    };
  }

  /**
   * Se ejecutar al renderizar el componente.
   */
  public ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  /**
   * Enivar data a la tabla.
   * @param data - JSON data.
   */
  public setData(data: any) {
    if (this.dynamicTableComponent) {
      this.dynamicTableComponent.onAdd([data]);
    }
    this.onSubmit();
  }

  /**
   * Enivar toda data a la tabla.
   * @param data - JSON data.
   */
  public replaceData(data: any[]) {
    if (this.dynamicTableComponent) {
      this.dynamicTableComponent.onReplace(data);
    }
    this.onSubmit();
  }

  /**
   * Enivar data a la tabla.
   * @param index - JSON data.
   */
  public deleteRow(index: number) {
    if (this.dynamicTableComponent) {
      this.dynamicTableComponent.onDelete(index);
    }
    this.onSubmit();
  }

  /**
   * Obtener data a la tabla.
   * @returns String.
   */
  public getData() {
    if (this.dynamicTableComponent) {
      return this.dynamicTableComponent.getData();
    }
    return undefined;
  }

  /**
   * Agregar un dato a la tabla.
   */
  public onAdd() {
    this.matDialog
      .open(DialogAddDataComponent, {
        disableClose: true,
        width: '85%',
        maxWidth: '700px',
        data: {
          formTitle: this.formTitle,
          controls: this.controls,
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        if (data) {
          this.setData(data.row);
        }
      });
  }

  /**
   * Metodo para editar un row de la tabla.
   * @param event - Accion de la tabla.
   */
  public onEdit(event: TableButtonAction) {
    if (this.dynamicTableComponent && event.index && event.row) {
      this.dynamicTableComponent.onEdit(event.index, event.row);
    }
  }

  /**
   * Evento de retorno de datos tabla.
   */
  public onSubmit() {
    this.dataTableSubmit.emit(JSON.stringify(this.getData()));
  }

  /**
   * Inicializa la tabla.
   * @returns TableConfig.
   */
  public get initTable() {
    const table: TableConfig = {
      columns: this.columns,
      data: this.defaultData,
      options: this.options || {
        classes: 'table-stripped',
        stickyHeader: true,
        removableColumn: {
          confirmation: true,
          confirmationText: 'Desea eliminar esta fila?',
        },
        stickyActions: true,
        sizes: {
          ngStyle: {
            'max-height': 'calc(100vh)',
            'min-height': 'calc(20vh)',
          },
        },
        noDataText: 'No hay datos.',
      },
    };
    if (this.mode !== MODE.VIEW) {
      table.options = {
        ...table.options,
        actions: {
          edit: {
            color: 'accent',
          },
          delete: {
            color: 'warn',
          },
        },
      };
    }
    return table;
  }
}
