import { ThemePalette } from '@angular/material/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { ControlConfig } from '../../dynamic-form';

/**
 * Modelo de configuracion Dialogo.
 */
export interface DialogConfig {
  /**
   * Tipo de Dialog.
   */
  type?: DialogType;
  /**
   * Titulo del modal.
   */
  header?: BasicConfig;
  /**
   * Cuerpo del modal.
   */
  body?: BasicConfig;
  /**
   * Pie de pagina del modal.
   */
  footer?: BasicConfig;
  /**
   * Botones del modal.
   */
  actions?: DialogActions[];
  /**
   * Configuracion del modal.
   */
  config?: MatDialogConfig;
}

/**
 * Modelo de configuracion Dialogo sin configuracion de angular material.
 */
export interface DialogConfigUpdate {
  /**
   * Tipo de Dialog.
   */
  type?: DialogType;
  /**
   * Titulo del modal.
   */
  header?: BasicConfig;
  /**
   * Cuerpo del modal.
   */
  body?: BasicConfig;
  /**
   * Pie de pagina del modal.
   */
  footer?: BasicConfig;
  /**
   * Botones del modal.
   */
  actions?: DialogActions[];
}

/**
 * Configuracion del body, header y footer.
 */
export interface BasicConfig {
  /**
   * Contenido.
   */
  text?: string;
  /**
   * Contenido.
   */
  html?: string;
  /**
   * Contenido.
   */
  script?: string;
  /**
   * Alineacion del texto.
   */
  textAlign?: TextAlignment;
  /**
   * Codigo de mensaje.
   */
  messageCode?: MessageCode;
}

/**
 * Botones de los Dialogs.
 */
export interface DialogActions {
  /**
   * Texto del boton.
   */
  text: string;
  /**
   * Valor de la accion del boton.
   */
  actionValue: any;
  /**
   * Color del boton ("primary" | "accent" | "warn" | undefined).
   */
  color?: ThemePalette;
  /**
   * Color del boton ("primary" | "accent" | "warn" | undefined).
   */
  principal?: boolean;
  /**
   * Icono del boton https://fonts.google.com/icons.
   */
  icon?: string;
}

/**
 * Tipos de Dialog.
 */
export type DialogType =
  | 'error'
  | 'success'
  | 'info'
  | 'warning'
  | 'question'
  | 'loading';

/**
 * Codigos de mensaje que retorna el servidor.
 */
export type MessageCode =
  | 'PROCESSING'
  | 'UNAUTHORIZED_ACCESS'
  | 'CREDIT_ANALYSIS'
  | 'NEXT_TASK';

/**
 * Alineacion del texto.
 */
export type TextAlignment =
  | 'text-left'
  | 'text-right'
  | 'text-center'
  | 'text-justify';
