import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { delay, of, Subject, take, takeUntil, tap } from 'rxjs';
import { isEqual } from 'lodash';

import {
  ControlConfig,
  SelectResponse,
} from '@wp-back-office/shared/dynamic-components';
import { Catalogue } from '@wp-back-office/core/store';

import { DynamicFormService } from '../../../../services/dynamic-form.service';
import { MatSelect } from '@angular/material/select';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { repeatUntil } from '@wp-back-office/core/workflow-sdk';
import { CataloguesStore } from 'libs/core/commons-backoffice/src/lib/services/catalogues-store';

/**
 * Controles de seleccion.
 */
@Component({
  selector: 'wp-back-office-form-control-drop-down',
  templateUrl: './form-control-drop-down.component.html',
  styleUrls: ['./form-control-drop-down.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormControlDropDownComponent),
      multi: true,
    },
  ],
})
export class FormControlDropDownComponent
  implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor
{
  /**
   * Id unico del formulario.
   */
  @Input()
  public uniqueIdForm!: string;
  /**
   * Configuracion del control.
   */
  @Input()
  public set control(value: ControlConfig | undefined) {
    if (value) {
      this.controlConfig = value;
      this._formControl = this.dynamicFormService.setValidators(value);
      this.cdRef.detectChanges();
    }
  }

  /**
   * Carga del formulario.
   */
  @Input()
  public loading: boolean;

  /**
   * Evento de formulario valido.
   */
  @Input()
  public value!: any;

  /**
   * Valores iniciales.
   */
  @Input()
  public getCatalogue?: boolean = true;

  /**
   * Evento de formulario.
   */
  @Output()
  public selectInput: EventEmitter<SelectResponse> =
    new EventEmitter<SelectResponse>();
  /**
   * Evento de formulario.
   */
  @Output()
  public infiniteScroll: EventEmitter<SelectResponse> =
    new EventEmitter<SelectResponse>();

  /**
   * Evento de actualizacion de opciones.
   */
  @Output()
  public updateOptions: EventEmitter<any[]> = new EventEmitter<any[]>();
  /**
   * Form control del campo.
   */
  public _formControl: FormControl;

  /**
   * Form control del campo.
   */
  public autoCompleteControl: FormControl;

  /**
   * Configuracion del control variable.
   */
  public controlConfig!: ControlConfig;

  /**
   * Opciones flltradas.
   */
  public filteredOptions: any[];

  /**
   * Destructor observables.
   */
  private destroy$: Subject<boolean>;

  /**
   * Verifica si la seleccion de todos los elementos visibles esta activa.
   */
  public selectAllenabled!: boolean;

  /**
   * Tiempo de espera para el evento scroll.
   */
  public scrollEventTimeout!: boolean;

  /**
   * Posicion vertical.
   */
  private currentVerticalPosition: number;

  /**
   * Select.
   */
  @ViewChild(MatSelect) public select!: MatSelect;

  /**
   * Virtual scroll viewport.
   */
  @ViewChild(CdkVirtualScrollViewport)
  public viewport!: CdkVirtualScrollViewport;

  /**
   * Crea una instancia de la clase.
   * @param dynamicFormService - Servicio de formularios dinamicos.
   * @param cdRef - Detector de cambios.
   * @param cataloguesStore - Servicio de catalogos.
   */
  constructor(
    public dynamicFormService: DynamicFormService,
    public cdRef: ChangeDetectorRef,
    private cataloguesStore: CataloguesStore
  ) {
    this.loading = false;
    this._formControl = new FormControl('');
    this.autoCompleteControl = new FormControl();
    this.destroy$ = new Subject();
    this.scrollEventTimeout = true;
    this.currentVerticalPosition = 0;
    this.filteredOptions = [];
  }

  /**
   * Se ejecutar al iniciar el componente.
   */
  public ngOnInit() {
    if (this.controlConfig) {
      this._formControl = this.dynamicFormService.setValidators(
        this.controlConfig
      );

      this._formControl.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: any) => {
          this.onChange(data);
          this.reorderArray(data);
          if (this._formControl.touched) {
            this.onTouch(data);
          }

          if (
            this.controlConfig.validators?.validListOption &&
            data?.code &&
            !this.controlConfig.options?.find(val => val.code === data?.code)
          ) {
            this._formControl.patchValue(undefined);
          }
        });

      if (this.controlConfig?.value) {
        this.writeValue(this.controlConfig.value);
      }

      if (this.value) {
        this.writeValue(this.value);
      }
    }
  }

  /**
   * Inicializa las opciones.
   */
  public loadCatalogue() {
    // CONSULTA DE CATALOGOS
    if (
      (!this.controlConfig?.keyOptions?.disableLocal &&
        this.controlConfig?.keyOptions?.entity &&
        this.getCatalogue) ||
      (!this.controlConfig?.keyOptions?.disableLocal &&
        this.controlConfig?.keyOptions?.entity &&
        !this.getCatalogue)
    ) {
      this.controlConfig = {
        ...this.controlConfig,
        validators: {
          ...this.controlConfig?.validators,
          loading: true,
        },
      };

      this.cataloguesStore
        .getCatalogueService({
          control: {
            controlConfig: this.controlConfig,
          },
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe((ev: any[]) => {
          const filter = this.controlConfig?.validators?.filterCatalogOptions;
          const controlOptions = filter !== undefined && filter.keyValue !== ''
            ? ev.filter(e => e[filter.keyValue] === filter.equalsTo)
            : ev;

          this.controlConfig = {
            ...this.controlConfig,
            validators: {
              ...this.controlConfig?.validators,
              loading: false,
            },
            options: [...controlOptions],
          };

          this.controlConfig.options = [...controlOptions];
          this.updateOptions.emit([...controlOptions]);
          setTimeout(() => {
            this.valideOptions();
            this.cdRef.detectChanges();
          }, 100);
        });
    } else {
      this.valideOptions();
    }
  }
  /**
   * Se ejecuta al renderizar el componente.
   */
  public ngAfterViewInit(): void {
    this.select.openedChange
      .pipe(
        tap(() => {
          this.reorderArray(this._formControl.value);
          if (this.loading) {
            this.select?.close();
          }
        }),
        delay(100)
      )
      .subscribe(() => {
        if (this.viewport) {
          this.viewport?.checkViewportSize();
        }
      });

    this.loadCatalogue();
  }

  /**
   * Reorganiza las opciones.
   * @param value - Valor del control.
   */
  public reorderArray(value: Catalogue) {
    if (!this.controlConfig.validators?.multiple) {
      const array = [...(this.controlConfig.options || [])];

      const customOrder = this.controlConfig.validators?.defaultOrder;
      if (customOrder !== undefined) {
        array.sort((a, b) => {
          const aconst = a?.[customOrder];
          const bconst = b?.[customOrder];
          if (aconst !== undefined && bconst !== undefined) {
            if (aconst < bconst) return -1 ;
            if (aconst > bconst) return 1;
          }
          return 0;
        });
      } else {
        array.sort((a, b) => {
          if (a?.description < b?.description) {
            return -1;
          }
          if (a?.description > b?.description) {
            return 1;
          }
          return 0;
        });
      }

      this.controlConfig.options = array;

      if (array.length > 0) {
        const index = array.findIndex(
          objeto => objeto.code === value?.code || ''
        );

        if (index >= 0) {
          const tempOption = array[index];
          array.splice(index, 1);
          array.unshift(tempOption);
          this.controlConfig.options = array;
        }
      }
      this.cdRef.detectChanges();
    }
  }

  /**
   * Valida si hay una sola opcion en la lista.
   */
  public valideOptions() {
    if (
      this.controlConfig?.validators?.required &&
      this.controlConfig?.options?.length === 1 &&
      this.controlConfig?.options[0]?.code &&
      !this.controlConfig?.uniqueOptionAutoFillDeactivated
    ) {
      this._formControl.patchValue(this.controlConfig?.options[0]);
    }
    const controlValue = this._formControl.value;
    if (
      controlValue &&
      typeof controlValue === 'object' &&
      controlValue?.code &&
      !controlValue?.description
    ) {
      repeatUntil(
        () => (this.controlConfig.options || []).length > 0,
        () => {
          const value = this.getOptionFromCode(controlValue);
          if (value && value?.code && value?.description) {
            this._formControl.patchValue(value);
          }
          return of();
        },
        { attempts: 10, initial_time: 1000, time: 1000 }
      )
        .pipe(takeUntil(this.destroy$), take(1))
        .subscribe();
    }
    setTimeout(() => {
      this.reorderArray(controlValue);
      this.filteredOptions = this.filter(this.autoCompleteControl.value || '');
      this.cdRef.detectChanges();
    }, 1000);
  }

  /**
   * Se ejecuta al destruir el componente.
   */
  public ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  /**
   * Actualiza los dropdown con el id del objeto.
   * @param c1 - Valor inicial.
   * @param c2 - Valor final.
   * @returns Estado booleano.
   */
  public compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.code === c2.code : c1 === c2;
  }

  /**
   * Emite un evento al seleccionar un valor en un select en el output selectInput.
   * @param data - Valor seleccionado.
   * @param controlKey - Nombre del contol.
   */
  public onSelect(data: any, controlKey: string | undefined) {
    this.selectInput.emit({
      keycontrol: controlKey || '',
      value: data,
    });
  }

  /**
   * Emite un evento al hacer scroll hacia abajo en un select en el output infiniteScroll.
   * @param options - Opciones actuales.
   * @param controlKey - Nombre del control.
   */
  public onInfiniteScroll(options: any, controlKey: string | undefined): void {
    this.infiniteScroll.emit({
      keycontrol: controlKey || '',
      value: {
        code: '',
        description: '',
      },
      options: options,
    });
  }

  /**
   * A.
   * @param event -.
   * @param options -.
   * @param key -.
   */
  public onScroll(event: any, options: any, key: any) {
    const scrollTop = event.srcElement.scrollTop;
    /**
     * Abajo sin movimiento horizontal.
     */
    if (scrollTop > this.currentVerticalPosition) {
      this._valideBottomPosition(event, options, key);
    }
    this.currentVerticalPosition = scrollTop;
  }

  /**
   * Valida si el contenedor de la tabla está abajo.
   * @param event - Evento Scroll.
   * @param options -.
   * @param key -.
   */
  private _valideBottomPosition(event: any, options: any, key: any): void {
    const obj = event.srcElement;
    if (
      this.difference(obj.scrollTop, obj.scrollHeight - obj.offsetHeight) <= 200
    ) {
      if (this.scrollEventTimeout) {
        this.scrollEventTimeout = false;
        setTimeout(() => {
          this.scrollEventTimeout = true;
        }, 600);
        this.infiniteScroll.emit({
          keycontrol: key || '',
          value: {
            code: '',
            description: '',
          },
          options: options,
        });
      }
    }
  }

  /**
   * Validar diferencia entre dos numeros.
   * @param num1 - Primer numero.
   * @param num2 - Segundo numero.
   * @returns Number.
   */
  public difference(num1: number, num2: number) {
    return num1 > num2 ? num1 - num2 : num2 - num1;
  }

  /**
   * Comentario.
   * @param event - Evento evento del metodo onOpen.
   */
  public onOpened(event: boolean): void {
    if (event) {
      const child = document.querySelector('.mat-select-panel-wrap');
      child?.parentElement?.classList.add('mat-select-cdk-pane');
      child?.parentElement?.parentElement?.previousElementSibling?.classList.add(
        'backdrop-show'
      );
    }
  }

  /**
   * Verirfica si es cambiado.
   * @param obj - Callback.
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  public onChange = (obj: any) => {};

  /**
   * Verirfica si es tocado.
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onTouch: any = () => {};

  /**
   * Verirfica si es escrito.
   * @param obj - Callback.
   * @throws Error.
   */
  public writeValue(obj: any): void {
    if (obj && obj?.code && !obj?.description) {
      repeatUntil(
        () => (this.controlConfig.options || []).length > 0,
        () => {
          const value = this.getOptionFromCode(obj);
          if (value && value?.code && value?.description) {
            this._formControl.patchValue(value);
          } else {
            this._formControl.patchValue(obj);
          }
          return of();
        },
        { attempts: 10, initial_time: 1000, time: 1000 }
      )
        .pipe(takeUntil(this.destroy$), take(1))
        .subscribe();
    } else {
      this._formControl.patchValue(obj);
    }
    setTimeout(() => {
      if (!this.getCatalogue || this.controlConfig.keyOptions?.disableLocal) {
        this.controlConfig = {
          ...this.controlConfig,
          options:
            this.controlConfig.options && this.controlConfig.options?.length > 1
              ? this.controlConfig.options
              : [obj],
        };
      }
      this.cdRef.detectChanges();
      this.reorderArray(this._formControl.value);
    }, 1000);
  }

  /**
   * Verirfica si es cambiado.
   * @param fn - Callback.
   * @throws Error.
   */
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  /**
   * Verirfica si es tocado.
   * @param fn - Callback.
   * @throws Error.
   */
  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  /**
   * Deshabilitar control.
   * @param isDisabled - Bandera.
   */
  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this._formControl.disable();
    } else {
      this._formControl.enable();
    }
  }

  /**
   * Retorna un catalogo con su code.
   * @param obj - Catalogo.
   * @returns Catalogue | undefined.
   */
  public getOptionFromCode(obj: Catalogue): Catalogue | undefined {
    if (obj && typeof obj === 'object' && obj?.code && !obj?.description) {
      if (!isNaN(Number(obj.code))) {
        return (this.controlConfig.options || []).find(
          opt => Number(opt.code) === Number(obj.code)
        );
      } else {
        return (this.controlConfig.options || []).find(
          opt => `${opt.code}` === `${obj.code}`
        );
      }
    } else {
      return obj;
    }
  }

  /**
   * Filtro interno del autoCompleteControl.
   * @param value - Valor a filtrar.
   * @param options - Opciones a filtrar || Opcional.
   * @returns - Catalogue[].
   */
  public filter(value: string, options?: Catalogue[]): any[] {
    const filterValue = value.toLowerCase();
    return (options || this.controlConfig.options || []).filter(option =>
      option?.description?.toLowerCase()?.includes(filterValue)
    );
  }

  /**
   * Seleccionar y deseleccionar las opciones visibles.
   */
  public selectAll() {
    if (!this.selectAllenabled) {
      this.selectAllenabled = true;
      if (this.controlConfig.type === 'DropDownFilter') {
        this._formControl.patchValue(this.filteredOptions || []);
      } else {
        this._formControl.patchValue(this.controlConfig.options || []);
      }
    } else {
      this.selectAllenabled = false;
      this._formControl.patchValue([]);
    }
  }

  /**
   * Valida si dos valores son iguales.
   * @param val - Valor 1.
   * @param val2 - Valor 2.
   * @returns Boolean.
   */
  public isEqual(val: any, val2: any): boolean {
    return isEqual(val, val2);
  }

  /**
   * Pone el foco en el filtro.
   */
  public focus() {
    document.getElementById('inputAC' + this.controlConfig.key || '')?.focus();
    this.resetFilter();
  }

  /**
   * Reinicia el filtro.
   */
  public resetFilter() {
    this.autoCompleteControl.patchValue('');
    this.filteredOptions = this.filter(this.autoCompleteControl.value || '');
  }
}
