<form
  #form="ngForm"
  class="row {{ justify || '' }}"
  [formGroup]="formGroupGeneric"
  [autocomplete]="autoComplete ? 'on' : 'off'">
  <ng-template [ngIf]="controlsInput && formGroupGeneric.controls">
    <ng-container *ngFor="let control of controlsInput; index as i">
      <ng-template [ngIf]="control">
        <ng-container [ngSwitch]="control.type">
          <ng-template
            [ngIf]="control.type === 'Section' || control.type === 'Divider'">
            <div
              class="{{ control.classes || '' }}"
              [hidden]="control.hidden"
              [ngClass]="
                'col-xxl-' +
                (control.col?.xxl || '12') +
                (' col-xl-' + (control.col?.xl || '12')) +
                (' col-lg-' + (control.col?.lg || '12')) +
                (' col-md-' + (control.col?.md || '12')) +
                (' col-sm-' + (control.col?.sm || '12')) +
                (' col-xs-' + (control.col?.xs || '12')) +
                (' col-' + (control.col?.xs || '12'))
              "
              class="form-column"
              [class.has-tooltip]="control.tooltip">
              <ng-container *ngSwitchCase="'Section'">
                <div class="d-flex flex-wrap">
                  <mat-card-subtitle class="subtitle">{{
                    control.label
                  }}</mat-card-subtitle>
                  <mat-icon
                    *ngIf="control?.tooltip"
                    class="subtitle"
                    [matTooltip]="control.tooltip || ''">
                    info
                  </mat-icon>
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="'Divider'">
                <mat-divider class="mt-0 mb-3 mx-auto"></mat-divider>
              </ng-container>
            </div>
          </ng-template>

          <ng-template
            [ngIf]="
              control.key && formGroupGeneric.controls[control.key.trim()]
            ">
            <div
              class="{{ control.classes || '' }}"
              [class.button-layout-radio]="control?.radioConfig?.buttonLayout"
              [hidden]="
                loading ||
                (hideDisabled &&
                  formGroupGeneric.controls[control.key.trim()].disabled) ||
                control.hidden
              "
              [class.has-error]="
                formGroupGeneric.controls &&
                formGroupGeneric.controls[control.key.trim() || ''].hasError(
                  'noMatch'
                )
              "
              [class.input-readonly]="
                control.validators?.readonly ||
                formGroupGeneric.controls[control.key.trim()].disabled ||
                false
              "
              [ngClass]="
                'col-xxl-' +
                (control.col?.xxl || '12') +
                (' col-xl-' + (control.col?.xl || '12')) +
                (' col-lg-' + (control.col?.lg || '12')) +
                (' col-md-' + (control.col?.md || '12')) +
                (' col-sm-' + (control.col?.sm || '12')) +
                (' col-xs-' + (control.col?.xs || '12')) +
                (' col-' + (control.col?.xs || '12'))
              "
              class="form-column"
              [class.has-tooltip]="control.tooltip"
              [class.addres]="control.type === 'AddressLocation'"
              [class.has-action]="control.action">
              <ng-container
                *ngIf="
                  control.type !== 'File' &&
                  control.type !== 'CheckBox' &&
                  control.type !== 'AddressLocation' &&
                  control.type !== 'GeographicLocation'
                ">
                <button
                  class="copy"
                  mat-icon-button
                  (click)="
                    textCopied(
                      getValue(
                        formGroupGeneric.controls[control.key.trim()].value
                      )
                    )
                  "
                  *ngIf="
                    control.validators?.readonly ||
                    formGroupGeneric.controls[control.key.trim()].disabled ||
                    false
                  "
                  [cdkCopyToClipboard]="
                    getValue(
                      formGroupGeneric.controls[control.key.trim()].value
                    )
                  "
                  [matTooltip]="'Copiar'">
                  <mat-icon>content_copy</mat-icon>
                </button>
              </ng-container>

              <ng-container *ngIf="control.topLabel">
                <mat-label>{{ control.topLabel }}</mat-label>
              </ng-container>
              <ng-container *ngSwitchCase="'Text'">
                <wp-back-office-form-control-text
                  [loading]="loading || false"
                  [formControl]="
                    convertToFormControl(
                      formGroupGeneric.controls[control.key.trim() || '']
                    )
                  "
                  [control]="control"
                  [uniqueIdForm]="uniqueIdControlPrefix"
                  [typeControl]="'text'"
                  (controlFocus)="onFocus($event, control)"
                  (controlFocusOut)="onFocusOut($event, control)">
                </wp-back-office-form-control-text>
              </ng-container>

              <ng-container *ngSwitchCase="'TextArea'">
                <wp-back-office-form-control-text-area
                  [formControl]="
                    convertToFormControl(
                      formGroupGeneric.controls[control.key.trim() || '']
                    )
                  "
                  [control]="control"
                  [uniqueIdForm]="uniqueIdControlPrefix">
                </wp-back-office-form-control-text-area>
              </ng-container>

              <ng-container *ngSwitchCase="'Email'">
                <wp-back-office-form-control-text
                  [formControl]="
                    convertToFormControl(
                      formGroupGeneric.controls[control.key.trim() || '']
                    )
                  "
                  [control]="control"
                  [uniqueIdForm]="uniqueIdControlPrefix"
                  [typeControl]="'email'"
                  (controlFocusOut)="onFocusOut($event, control)">
                </wp-back-office-form-control-text>
              </ng-container>

              <ng-container *ngSwitchCase="'Number'">
                <wp-back-office-form-control-text
                  [formControl]="
                    convertToFormControl(
                      formGroupGeneric.controls[control.key.trim() || '']
                    )
                  "
                  [control]="control"
                  [uniqueIdForm]="uniqueIdControlPrefix"
                  [typeControl]="'number'"
                  (controlFocus)="onFocus($event, control)"
                  (controlFocusOut)="onFocusOut($event, control)">
                </wp-back-office-form-control-text>
              </ng-container>

              <ng-container *ngSwitchCase="'Password'">
                <wp-back-office-form-control-text
                  [formControl]="
                    convertToFormControl(
                      formGroupGeneric.controls[control.key.trim() || '']
                    )
                  "
                  [control]="control"
                  [uniqueIdForm]="uniqueIdControlPrefix"
                  [typeControl]="'password'"
                  (controlFocusOut)="onFocusOut($event, control)">
                </wp-back-office-form-control-text>
              </ng-container>

              <ng-container *ngSwitchCase="'CodeVerification'">
                <wp-back-office-form-control-code-verification
                  [control]="control"
                  [uniqueIdForm]="uniqueIdControlPrefix"
                  [formControl]="
                    convertToFormControl(
                      formGroupGeneric.controls[control.key.trim() || '']
                    )
                  "
                  (submitValue)="onCodeVerification($event)"
                  (reSendCode)="onReSendCode(control)"
                  (timeOutOTP)="onTimeOutOTP($event)">
                </wp-back-office-form-control-code-verification>
              </ng-container>

              <ng-container *ngSwitchCase="'Currency'">
                <wp-back-office-form-control-currency
                  [formControl]="
                    convertToFormControl(
                      formGroupGeneric.controls[control.key.trim() || '']
                    )
                  "
                  [control]="control"
                  [uniqueIdForm]="uniqueIdControlPrefix"
                  (controlFocus)="onFocus($event, control)"
                  (controlFocusOut)="onFocusOut($event, control)">
                </wp-back-office-form-control-currency>
              </ng-container>

              <ng-container *ngSwitchCase="'DatePicker'">
                <wp-back-office-form-control-date-picker
                  [formControl]="
                    convertToFormControl(
                      formGroupGeneric.controls[control.key.trim() || '']
                    )
                  "
                  [control]="control"
                  [uniqueIdForm]="uniqueIdControlPrefix">
                </wp-back-office-form-control-date-picker>
              </ng-container>

              <ng-container *ngSwitchCase="'DateTime'">
                <wp-back-office-form-control-date-time
                  [formControl]="
                    convertToFormControl(
                      formGroupGeneric.controls[control.key.trim() || '']
                    )
                  "
                  [control]="control"
                  [uniqueIdForm]="uniqueIdControlPrefix">
                </wp-back-office-form-control-date-time>
              </ng-container>

              <ng-container *ngSwitchCase="'File'">
                <wp-back-office-form-control-file
                  [formControl]="
                    convertToFormControl(
                      formGroupGeneric.controls[control.key.trim() || '']
                    )
                  "
                  [control]="control"
                  [uniqueIdForm]="uniqueIdControlPrefix">
                </wp-back-office-form-control-file>
              </ng-container>

              <ng-container *ngSwitchCase="'DropDown'">
                <wp-back-office-form-control-drop-down
                  [formControl]="
                    convertToFormControl(
                      formGroupGeneric.controls[control.key.trim() || '']
                    )
                  "
                  [getCatalogue]="getCatalogue"
                  [loading]="loading || false"
                  [control]="control"
                  [uniqueIdForm]="uniqueIdControlPrefix"
                  (updateOptions)="updateOptionsByIndex($event, i)"
                  (infiniteScroll)="
                    onInfiniteScroll(
                      control.options,
                      control.key.trim(),
                      control
                    )
                  "
                  [value]="
                    formGroupGeneric.value[control.key.trim() || ''] ||
                    undefined
                  "
                  (selectInput)="onSelect($event.value, control.key.trim())">
                </wp-back-office-form-control-drop-down>
              </ng-container>

              <ng-container *ngSwitchCase="'DropDownFilter'">
                <wp-back-office-form-control-drop-down
                  [formControl]="
                    convertToFormControl(
                      formGroupGeneric.controls[control.key.trim() || '']
                    )
                  "
                  [getCatalogue]="getCatalogue"
                  [control]="control"
                  [uniqueIdForm]="uniqueIdControlPrefix"
                  (updateOptions)="updateOptionsByIndex($event, i)"
                  (infiniteScroll)="
                    onInfiniteScroll(
                      control.options,
                      control.key.trim(),
                      control
                    )
                  "
                  [value]="
                    formGroupGeneric.value[control.key.trim() || ''] ||
                    undefined
                  "
                  (selectInput)="onSelect($event.value, control.key.trim())">
                </wp-back-office-form-control-drop-down>
              </ng-container>

              <ng-container *ngSwitchCase="'AutoComplete'">
                <wp-back-office-form-control-autocomplete
                  [formControl]="
                    convertToFormControl(
                      formGroupGeneric.controls[control.key.trim() || '']
                    )
                  "
                  [getCatalogue]="getCatalogue"
                  [loading]="loading || false"
                  [control]="control"
                  [uniqueIdForm]="uniqueIdControlPrefix"
                  [value]="
                    formGroupGeneric.value[control.key.trim() || ''] ||
                    undefined
                  "
                  (updateOptions)="updateOptionsByIndex($event, i)"
                  (infiniteScroll)="
                    onInfiniteScroll(
                      control.options,
                      control.key.trim(),
                      control
                    )
                  "
                  (controlFocus)="onFocus($event, control)"
                  (controlFocusOut)="onFocusOut($event, control)"
                  (selectInput)="
                    onSelect($event.value, control.key.trim())
                  "></wp-back-office-form-control-autocomplete>
              </ng-container>

              <ng-container *ngSwitchCase="'CheckBox'">
                <wp-back-office-form-control-check-box
                  [formControl]="
                    convertToFormControl(
                      formGroupGeneric.controls[control.key.trim() || '']
                    )
                  "
                  [control]="control"
                  [uniqueIdForm]="uniqueIdControlPrefix">
                </wp-back-office-form-control-check-box>
              </ng-container>

              <ng-container *ngSwitchCase="'Radio'">
                <wp-back-office-form-control-radio-button
                  [formControl]="
                    convertToFormControl(
                      formGroupGeneric.controls[control.key.trim() || '']
                    )
                  "
                  [horizontal]="control.radioConfig?.horizontal || false"
                  [control]="control"
                  [value]="
                    formGroupGeneric.value[control.key.trim() || ''] ||
                    undefined
                  "
                  [uniqueIdForm]="uniqueIdControlPrefix">
                </wp-back-office-form-control-radio-button>
              </ng-container>

              <ng-container *ngSwitchCase="'ButtonToggleGroup'">
                <wp-back-office-form-control-botton-toggle-group
                  [formControl]="
                    convertToFormControl(
                      formGroupGeneric.controls[control.key.trim() || '']
                    )
                  "
                  [control]="control"
                  [uniqueIdForm]="uniqueIdControlPrefix">
                </wp-back-office-form-control-botton-toggle-group>
              </ng-container>

              <ng-container *ngSwitchCase="'GeographicLocation'">
                <wp-back-office-template-geographic-location
                  wpBackOfficeCustomValidatorDirective
                  [formControl]="
                    convertToFormControl(
                      formGroupGeneric.controls[control.key.trim() || '']
                    )
                  "
                  [control]="control"
                  [uniqueIdForm]="uniqueIdControlPrefix"
                  [value]="
                    formGroupGeneric.value[control.key.trim() || ''] ||
                    undefined
                  "></wp-back-office-template-geographic-location>
              </ng-container>

              <ng-container *ngSwitchCase="'AddressLocation'">
                <wp-back-office-template-address-location
                  [formControl]="
                    convertToFormControl(
                      formGroupGeneric.controls[control.key.trim() || '']
                    )
                  "
                  [control]="control"
                  [uniqueIdForm]="
                    uniqueIdControlPrefix
                  "></wp-back-office-template-address-location>
              </ng-container>

              <ng-container *ngSwitchCase="'Dependent'">
                <wp-back-office-template-control-dependence
                  [formControl]="
                    convertToFormControl(
                      formGroupGeneric.controls[control.key.trim() || '']
                    )
                  "
                  [hideDisabled]="hideDisabled"
                  [control]="control"
                  [uniqueIdForm]="uniqueIdControlPrefix"
                  [value]="
                    formGroupGeneric.value[control.key.trim() || ''] ||
                    undefined
                  "></wp-back-office-template-control-dependence>
              </ng-container>

              <ng-container *ngSwitchCase="'TableControl'">
                <wp-back-office-form-control-table
                  [formControl]="
                    convertToFormControl(
                      formGroupGeneric.controls[control.key.trim() || '']
                    )
                  "
                  [control]="control"
                  [uniqueIdForm]="
                    uniqueIdControlPrefix
                  "></wp-back-office-form-control-table>
              </ng-container>

              <button
                mat-icon-button
                [color]="control.action.color || 'primary'"
                *ngIf="control.action"
                [matTooltip]="control.action.tooltip || ''"
                class="action-form-button"
                (click)="sendAction(control.action.returnValue)">
                <mat-icon>{{ control.action.icon }}</mat-icon>
              </button>

              <button
                mat-mini-fab
                class="delete-button"
                [color]="'warn'"
                *ngIf="formGenerator"
                (click)="deleteFormItem(i)">
                <mat-icon>delete</mat-icon>
              </button>

              <button
                mat-mini-fab
                class="edit-button"
                [color]="'accent'"
                *ngIf="formGenerator"
                (click)="edit.emit({control, index: i })">
                <mat-icon>edit</mat-icon>
              </button>
              <mat-icon
                *ngIf="control?.tooltip"
                class="subtitle tooltip-icon"
                [matTooltip]="control.tooltip || ''"
                [matTooltipClass]="'tooltip-selection'"
                [matTooltipHideDelay]="200"
                [matTooltipShowDelay]="200"
                >info
              </mat-icon>

              <mat-hint
                class="control-hint {{ control.hintClass || '' }}"
                *ngIf="control.hint"
                >{{ control.hint }}
              </mat-hint>
            </div>

            <div
              class="loading-card"
              *ngIf="loading"
              [hidden]="
                hideDisabled &&
                formGroupGeneric.controls[control.key.trim()].disabled
              "
              [ngClass]="
                'col-xxl-' +
                (control.col?.xxl || '12') +
                (' col-xl-' + (control.col?.xl || '12')) +
                (' col-lg-' + (control.col?.lg || '12')) +
                (' col-md-' + (control.col?.md || '12')) +
                (' col-sm-' + (control.col?.sm || '12')) +
                (' col-xs-' + (control.col?.xs || '12'))
              ">
              <div class="loading-card-content">
                <div
                  *ngIf="control.type === 'Radio'"
                  class="radio-label-loading-spacing"></div>
                <h2></h2>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </ng-template>
    </ng-container>
  </ng-template>
</form>
<ng-container *ngIf="buttonSubmit ? true : false">
  <div class="d-flex justify-content-end">
    <button
      mat-flat-button
      [matTooltip]="
        buttonSubmit && buttonSubmit.tooltip ? buttonSubmit.tooltip : ''
      "
      [disabled]="formGroupGeneric.invalid"
      color="accent"
      (click)="onSubmit()">
      {{ buttonSubmit ? buttonSubmit.label : '' }}
    </button>
  </div>
</ng-container>
<ng-container *ngIf="buttonSubmit ? true : false">
  <div class="d-flex justify-content-end">
    <button
      mat-flat-button
      [matTooltip]="
        buttonSubmit && buttonSubmit.tooltip ? buttonSubmit.tooltip : ''
      "
      [disabled]="formGroupGeneric.invalid"
      color="accent"
      (click)="onSubmit()">
      {{ buttonSubmit ? buttonSubmit.label : '' }}
    </button>
  </div>
</ng-container>

<mat-error
  class="error-match"
  [class.opacity-0]="
    !(formGroupGeneric.errors && formGroupGeneric.errors['noMatch'])
  "
  [innerHTML]="
    formGroupGeneric.errors && formGroupGeneric.errors['noMatch']
      ? formGroupGeneric.errors['noMatch']?.message || ''
      : ''
  ">
</mat-error>
