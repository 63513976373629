<div class="container-fluid">
  <wp-back-office-dynamic-form
    #dynamicFormID
    [hideDisabled]="true"
    [controls]="dynamicFormConfiguration"
    (changeValue)="onChangeDynamicForm($event)"
    (infiniteScroll)="onInfiniteScroll($event)">
  </wp-back-office-dynamic-form>
  <div class="d-flex justify-content-end" *ngIf="viewButtons">
    <wp-back-office-dynamic-button
      *ngIf="stepIndex > 0"
      [buttonSelector]="'mat-stroked-button'"
      (ButtonClick)="onBackStep()"
      class="mx-2 mat-stroked-button.mat-accent"
      [text]="'Atrás'"
      [color]="'accent'">
    </wp-back-office-dynamic-button>
    <wp-back-office-dynamic-button
      *ngIf="stepIndex !== stepsLength"
      [buttonSelector]="'mat-flat-button'"
      [disabled]="!dynamicStepperService.isValid(dynamicForm)"
      (ButtonClick)="onNextStep()"
      [text]="'Siguiente'"
      [color]="'accent'">
    </wp-back-office-dynamic-button>
  </div>
</div>