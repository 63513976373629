/**
 * Modelo de rutas catalogue.
 */
export interface CataloguePath {
  /**
   * Ruta.
   */
  path?: string;
  /**
   * Ruta 1.
   */
  path1?: string;
  /**
   * Ruta 2.
   */
  path2?: string;
  /**
   * Codigo.
   */
  code?: string;
  /**
   * Codigo 1.
   */
  code1?: string;
  /**
   * Codigo 2.
   */
  code2?: string;
}
