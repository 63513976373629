import { ComponentType } from '@angular/cdk/portal';

/**
 * Modelo catalogo.
 */
export interface Catalogue {
  /**
   * Identificador.
   */
  code: string;
  /**
   * Valor.
   */
  description: string;
  /**
   * Id.
   */
  id?: string;
  /**
   * Componente.
   */
  component?: ComponentType<any>;
  /**
   * Entidad.
   */
  entityCode?: string;
}

/**
 * Modelo catalogo.
 */
export interface CatalogueCustomID {
  /**
   * Identificador primario.
   */
  id: string;
  /**
   * Identificador BD.
   */
  code: string;
  /**
   * Valor.
   */
  description: string;
}

/**
 * Tipos de catálogos.
 */
export type CatalogueType =
  | 'APPLICANTTYPE'
  | 'LOAN-APPLICANTS'
  | 'TYPEOFIDENTIFICATION'
  | 'COUNTRIES'
  | 'STATES'
  | 'CITIES'
  | 'BANKS'
  | 'DOCUMENTS-APPROVAL-STATUS'
  | 'DOCUMENTS-RETURN-REASONS'
  | 'BANKACCOUNTTYPE'
  | 'TYPEOFDWELLING'
  | 'FAMILYRELATIONSHIPS'
  | 'GENDER'
  | 'LEGALREVIEW-APPROVAL-STATUS'
  | 'LEVEL1-APPROVAL-STATUS'
  | 'REFERENCE-RETURN-REASONS'
  | 'TYPEADDRESS'
  | 'TYPEIDENTIFICATION'
  | 'TYPEJOB'
  | 'VERIFICATIONKYC-APPROVAL-STATUS'
  | 'LEVELOFSCHOOLING'
  | 'MARITALSTATUS'
  | 'INCOMEFREQUENCY'
  | 'TYPEOFEGRESS'
  | 'ASSETTYPE'
  | 'TYPEOFLIABILITIES'
  | 'TYPEOFPHONE'
  | 'TYPEOFREFERENCES'
  | 'CURRENCYTYPE'
  | 'PRODUCT_TYPE'
  | 'PRODUCTCATEGORY'
  | 'PRODUCTS'
  | 'VEHICLE_SEGMENT'
  | 'PERSON_TYPE_1'
  | 'PERSON_TYPE_2'
  | 'CUSTOMER_TYPE'
  | 'CUSTOMER_CLASSIFICATION'
  | 'FUNDING_TYPE'
  | 'REFERENCE_RATE'
  | 'PERSON_TYPE'
  | 'INCOME_TYPE'
  | 'TYPE_SERVICE'
  | 'PLAN_TYPE'
  | 'MARKET'
  | 'TERM'
  | 'TYPES_OF_FOREIGN_OPERATION'
  | 'FIRST_EXTRA_QUOTA'
  | 'UNIT_AMOUNT_FINANTIATION_SEGMENTATION'
  | 'RESPONSE'
  | 'CONTRACT_TYPE'
  | 'REGIME_TYPE'
  | 'CONS_SOCIALSECURITY'
  | 'USER_TYPE'
  | 'STATUS'
  | 'REGION'
  | 'TYPE_SALE'
  | 'KIND_SOCIETY'
  | 'TYPE_CONTRIBUTOR'
  | 'FORM_PAYMENT'
  | 'ECONOMIC_ACTIVITIES'
  | 'UNIT_QUOTA_FINANTIATION_SEGMENTATION'
  | 'DURATION'
  | 'LEGALIZATION_DOCUMENTS'
  | 'LEGALIZATION_DOCUMENTS_PJ'
  | 'CITIES_CO'
  | 'CT_SBS_CITIES'
  | 'CT_SBS_GENDER'
  | 'SCORE_BURO'
  | 'ARMORE_LEVEL'
  | 'EPS'
  | 'PENSION_FUNDS'
  | 'APP_MARKET'
  | 'LIST_INSURERS'
  | 'DEFAULT_VALUES_INSURERS';
/**
 * Catálogos.
 */
export type CataloguePASType =
  | 'type_calculation_insured'
  | 'type_financing'
  | 'type_tariff'
  | 'amortization-credit';
/**
 * Catalogos.
 */
export type CatalogueStateType =
  | 'KeyValueTypeDocuments'
  | 'KeyValueTypeCustomers'
  | 'KeyValueCountries'
  | 'KeyValueValueLevelOfSchooling'
  | 'KeyValueTypeOfWelling'
  | 'KeyValueMaritalStatus'
  | 'KeyValueIncomeFrequency'
  | 'KeyValueCurrencyType'
  | 'KeyValueTypeOfPhone'
  | 'KeyValueAssetType'
  | 'KeyValueTypeOfEgress'
  | 'KeyValueTypeJob'
  | 'KeyValueTypeOfLiabilities'
  | 'KeyValueDepartments'
  | 'KeyValueCities'
  | 'KeyValueBank'
  | 'KeyValueBankAccountType'
  | 'KeyValueDocumentsApprovalStatus'
  | 'KeyValueFamilyRelationships'
  | 'KeyValueGender'
  | 'KeyValueLegalreviewApprovalStatus'
  | 'KeyValueLevel1ApprovalStatus'
  | 'KeyValueReferenceReturnReasons'
  | 'KeyValueTypeAddress'
  | 'KeyValueVerificationkycApprovalStatus'
  | 'KeyValueApplicantType'
  | 'KeyValueTypeOfReferences'
  | 'KeyValueProducts'
  | 'KeyValueProductType'
  | 'KeyValueTerms'
  | 'KeyValueProductCategory'
  | 'KeyValueIncomeType'
  | 'KeyValueEps'
  | 'KeyValuePensionFunds';


  /**
   * Obtener todo.
   */
  export type ALL = 'ALL'