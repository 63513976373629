import { Component, Input } from '@angular/core';

/**
 * Componente de procesos carta.
 */
@Component({
  selector: 'wp-back-office-card-process',
  templateUrl: './card-process.component.html',
})
// eslint-disable-next-line @nrwl/nx/workspace/doc-elements-angular
export class CardProcessComponent {
  /**
   * Entrada.
   */
  // eslint-disable-next-line @nrwl/nx/workspace/doc-class-property
  @Input()
  public data: any;

  /**
   * Crea una instancia de CardProcessComponent.
   */
  constructor() {
    this.data = {};
  }

}
