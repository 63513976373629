<div *ngIf="(arrayTabs?.length || 0) <= 0; else TabComponente">
  <p>Loading</p>
</div>
<ng-template #TabComponente>
  <mat-tab-group class="card-table" animationDuration="0ms">
    <mat-tab *ngFor="let tab of arrayTabs; let index = index" #tabs >
      <ng-template mat-tab-label>
        <div
          class="d-flex justify-content-center align-items-center align-self-baseline">
          <div>{{ tab.name }}</div>
        </div>
      </ng-template>
      <div class="container-fluid px-4 py-5">
        <ng-template
          wpBackOfficeFactoryTabGeneral
          [component]="tab.component"></ng-template>
      </div>
    </mat-tab>
  </mat-tab-group>
</ng-template>
