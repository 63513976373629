/* eslint-disable jsdoc/require-jsdoc */
import { Catalogue } from '@wp-back-office/core/commons-backoffice';

/**
 * Interfaz de mercado.
 */
export interface Market {
  /**
   * Codigo mercado.
   */
  code_market: number;
  /**
   * Descripcion.
   */
  description: string;
  /**
   * Tipo de precio.
   */
  type_price: number;
}
export interface OfficeInput {
  markets: Omit<MarketList, 'description_market'>[];
  code_office_group: number;
  code_bank: number;
  bank_account: string;
  code_bir: string;
  description: string;
  biling_email: string;
  internal_code: string;
  nit: string;
  verification_digit: string;
  kind_society: string;
  country: string;
  state: string;
  city: string;
  type_contributor: string;
  address: string;
  telephone: string;
  cell_phone: string;
  code_ciiu: string;
  notification_email: string;
  form_payment: string;
  payment_thirt_parties: boolean;
  postal_code: string;
  code_core: string;
  account_type: string;
  mixed_payment: number;
  adress_structure: string;
  beneficiaries: Beneficiary[];
  commissions?: Commission[];
}

export interface OfficeOutput {
  status: boolean;
  code: number;
  code_office: string;
  markets: Market[];
  beneficiaries: Beneficiary[];
  commissions?: Commission[];
}

export interface Beneficiary {
  code_service: string;
  first_name: string;
  code_bank: string;
  account_type: string;
  second_name: string;
  first_surname: string;
  second_surname: string;
  identification_type: string;
  identification_number: string;
  country: string;
  state: string;
  city: string;
  adress: string;
  number: string;
  email: string;
  occupation: string;
  adress_structure: string;
  account_number: string;
  description_service: string;
}

export interface Commission {
  code_type_commissions: string;
  value: number;
}
/**
 * Oficina.
 */
export interface Office extends OfficeCommon, OfficeSupplementary {
  /**
   * Descripcion del banco.
   */
  description_bank: string;
  /**
   * Descripcion del grupo.
   */
  description_group: string;
  /**
   * Codigo del grupo.
   */
  code_group: number;
  /**
   * Cuenta de sala.
   */
  room_count: number;
  /**
   * Lista de mercados.
   */
  markets: MarketList[];
}
/**
 * Detalle del concesionario.
 */
export interface OfficeDetail extends OfficeCommon, OfficeSupplementary {
  /**
   * Codigo core.
   */
  code_core: string;
  /**
   * Codigo postal.
   */
  postal_code: string;
  /**
   * Pago mixto.
   */
  mixed_payment: Catalogue | number;
  /**
   * Direccion extructurado.
   */
  adress_structure: string;
  /**
   * Codigo de grupo de oficina.
   */
  code_office_group: Catalogue | number;
  /**
   * Descripcion del banco.
   */
  bank_description: string;
  /**
   * Descripcion de grupo de oficina.
   */
  office_group_description: string;
  /**
   * Lista de mercados.
   */
  markets: MarketItem[];
  /**
   * Fecha de creacion.
   */
  created_date: string;
  /**
   * Fecha de actualizacion.
   */
  updated_date: string;
}
/**
 * Datos de concesionario edicion.
 */
export interface OfficeEditing {
  /**
   * Tipo de socedad.
   */
  kind_society: Catalogue | string;
  /**
   * Tipo de contribuidor.
   */
  type_contributor: Catalogue | string;
  /**
   * Codigo CIIU.
   */
  code_ciiu: Catalogue | string;
  /**
   * Codigo gruo de concesionario.
   */
  code_office_group: Catalogue | string;
  /**
   * Forma de pago.
   */
  form_payment: Catalogue | string;
  /**
   * Tipo de cuenta.
   */
  account_type: Catalogue | string;
}
/**
 * Modelo comun de concesionario.
 */
interface OfficeCommon {
  /**
   * Codigo oficina.
   */
  code_office: number;
  /**
   * Descripcion.
   */
  description: string;
  /**
   * Codigo BIR.
   */
  code_bir: string;
  /**
   * Codigo interno.
   */
  internal_code: string;
  /**
   * NIT.
   */
  nit: string;
  /**
   * Codigo de verificacion.
   */
  verification_digit: string;
  /**
   * Ciudad.
   */
  country: string;
  /**
   * Departamento.
   */
  state: string;
  /**
   * Ciudad.
   */
  city: string;
  /**
   * Direccion.
   */
  address: string;
  /**
   * Telefono.
   */
  telephone: string;
  /**
   * Telefono movil.
   */
  cell_phone: string;
  /**
   * Email de notificacion.
   */
  notification_email: string;
  /**
   * Pago a tercero.
   */
  payment_thirt_parties: Catalogue | number;
  /**
   * Bandera de estado activo.
   */
  enabled: Catalogue | number;
  /**
   * Email de pago.
   */
  biling_email: string;
  /**
   * Codigo de banco.
   */
  code_bank: Catalogue | number;
  /**
   * Cuenta de banco.
   */
  bank_account: string;
}
/**
 * Datos de concesionario suplementario.
 */
interface OfficeSupplementary {
  /**
   * TIpo de contribuidor.
   */
  type_contributor: Catalogue | string;
  /**
   * Tipo de cuenta.
   */
  account_type: Catalogue | string;
  /**
   * Forma de pago.
   */
  form_payment: Catalogue | string;
  /**
   * Codigo CIIU.
   */
  code_ciiu: Catalogue | string;
  /**
   * Tipo de sociedad.
   */
  kind_society: Catalogue | string;
}
/**
 * Modelo de mercado.
 */
export interface MarketList {
  /**
   * Codigo del mercado.
   */
  code_market: number;
  /**
   * Descripcion del mercado.
   */
  description_market: string;
}
/**
 * Mercado por item.
 */
export interface MarketItem {
  /**
   * Codigo del mercado.
   */
  marketCode: number;
  /**
   * Descripcion del mercado.
   */
  market_description: string;
}

/**
 * Interfaz que representa una Sala.
 */
export interface Room {
  /**
   * Descripción de la oficina.
   */
  description_office: string;

  /**
   * Descripción de la zona de la Sala.
   */
  description_roomzone: string;

  /**
   * Código de la oficina.
   */
  code_office: number;

  /**
   * Código de la Sala.
   */
  code_room: number;

  /**
   * Código de la zona de la Sala.
   */
  code_room_zone: number;

  /**
   * Código del BIR (Business Identifier Record).
   */
  code_bir: string;

  /**
   * Descripción de la Sala.
   */
  description: string;

  /**
   * Ciudad de ubicación.
   */
  city: string;

  /**
   * Correo electrónico para facturación.
   */
  biling_email: string;

  /**
   * Correo electrónico para notificaciones.
   */
  notification_email: string;

  /**
   * Tipo de venta de la Sala.
   */
  type_sale: string;

  /**
   * Número de teléfono.
   */
  telephone: string;

  /**
   * Número de teléfono celular.
   */
  cell_phone: string;

  /**
   * Código postal.
   */
  postal_code: string;

  /**
   * Dirección de la Sala.
   */
  address: string;

  /**
   * Estructura de la dirección.
   */
  adress_structure: string;

  /**
   * Comisión del vendedor.
   */
  seller_commission: string;

  /**
   * Habilitado (1 para habilitado, 0 para deshabilitado).
   */
  enabled: number;

  /**
   * Región de ubicación.
   */
  region: string;

  /**
   * País de ubicación.
   */
  country: string;

  /**
   * Estado de ubicación.
   */
  state: string;
  /**
   * Lista de mercados.
   */
  markets: MarketItem[];
}
export interface RoomSegmentation {
  code_office: number;
  description_office: string;
  code_office_group: number;
  description_group: string;
  code_room: number;
  description_room: string;
  state: string;
  city: string;
  markets: MarketList[];
  description_markets: string;
}

export interface RoomBulk {
  code_room: number;
  code_bir: string;
  description: string;
  country: string;
  state: string;
  city: string;
  postal_code: string;
  address: string;
  adress_structure: string;
  enabled: number;
  biling_email: string;
  notification_email: string;
  type_sale: string;
  telephone: string;
  cell_phone: string;
  region: string;
  created_date: Date;
  updated_date: Date;
  code_office: number;
  code_room_zone: number;
  office_description: string;
  office_code: number;
  office_group_code: number;
  office_group_description: string;
  markets: MarketList[];
  'BO_RoomZone.description': string;
  'BO_RoomZone.code_room_zone': number;
}

/**
 * Interfaz que representa un vendedor.
 */
export interface Seller {
  /**
   * Descripción de la oficina.
   */
  officeDescription: string;

  /**
   * Código de la oficina.
   */
  officeCode: number;

  /**
   * Descripción de la sala.
   */
  roomDescription: string;

  /**
   * Código del vendedor.
   */
  code_seller: string;

  /**
   * Correo electrónico del vendedor.
   */
  email: string;

  /**
   * Tipo de documento del vendedor.
   */
  document_type: string;

  /**
   * Número de documento del vendedor.
   */
  document_number: string;

  /**
   * Identificación del vendedor.
   */
  seller: number;

  /**
   * Código de la sala (relacionado con code_room).
   */
  code_room: number;
}
