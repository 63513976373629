import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

import { ControlConfig } from '@wp-back-office/shared/dynamic-components';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';

import { DynamicFormService } from '../../../../services/dynamic-form.service';

/**
 * Control para capturar la fecha.
 */
@Component({
  selector: 'wp-back-office-form-control-date-picker',
  templateUrl: './form-control-date-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormControlDatePickerComponent),
      multi: true,
    },
  ],
})
export class FormControlDatePickerComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  /**
   * Id unico del formulario.
   */
  @Input()
  public uniqueIdForm!: string;
  /**
   * Configuracion del control.
   */
  @Input()
  public set control(value: ControlConfig | undefined) {
    if (value) {
      this.controlConfig = value;
      this._formControl = this.dynamicFormService.setValidators(value);
    }
  }
  /**
   * Form control del campo.
   */
  public _formControl: FormControl;
  /**
   * Destructor observables.
   */
  private destroy$: Subject<boolean>;

  /**
   * Configuracion del control variable.
   */
  public controlConfig!: ControlConfig;

  /**
   * Fecha minima.
   */
  public minDate!: Date;

  /**
   * Fecha maxima.
   */
  public maxDate!: Date;

  /**
   * Tamaño de la pantalla.
   */
  public innerWidth: number;

  /**
   * Crea una instancia de la clase.
   * @param dynamicFormService - Servicio de formularios dinamicos.
   * @param _adapter - Adapter.
   * @param cdRef - Detector de cambios.
   * @param _locale - Locale, date picker.
   */
  constructor(
    public dynamicFormService: DynamicFormService,
    private _adapter: DateAdapter<any>,
    public cdRef: ChangeDetectorRef,
    @Inject(MAT_DATE_LOCALE) private _locale: string
  ) {
    this._formControl = new FormControl();
    this.destroy$ = new Subject();
    this.innerWidth = window.innerWidth;
  }

  /**
   * Resize listener.
   * @param event - Resultado.
   */
  @HostListener('window:resize', ['$event'])
  public onResize(event: any) {
    this.innerWidth = event.target.innerWidth;
  }

  /**
   * Se ejecutar al iniciar el componente.
   */
  public ngOnInit() {
    this._locale = 'es';
    this._adapter.setLocale(this._locale);
    if (this.controlConfig)
      this._formControl = this.dynamicFormService.setValidators(
        this.controlConfig
      );
    this._formControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.onChange(data);
        if (this._formControl.touched) {
          this.onTouch(data);
        }
      });
    this.valideMinMax();
  }

  /**
   * Valida la fecha minima y maxima.
   */
  public valideMinMax() {
    if (this.controlConfig?.datePickerConfig?.minDate) {
      this.minDate = this.controlConfig.datePickerConfig.minDate;
    }

    if (this.controlConfig?.datePickerConfig?.maxDate) {
      this.maxDate = this.controlConfig.datePickerConfig.maxDate;
    }

    if (this.controlConfig?.datePickerConfig?.dateMaxNow &&
      !this.controlConfig?.datePickerConfig?.minDate &&
      !this.controlConfig?.datePickerConfig?.maxDate
    ) {
      this.maxDate = new Date();
    }

    if (this.controlConfig?.datePickerConfig?.dateMinNow &&
      !this.controlConfig?.datePickerConfig?.minDate &&
      !this.controlConfig?.datePickerConfig?.maxDate
    ) {
      this.minDate = new Date();
    }

    if (
      this.controlConfig?.datePickerConfig?.legalAgeIsRequired &&
      !this.controlConfig?.datePickerConfig?.minDate &&
      !this.controlConfig?.datePickerConfig?.maxDate
    ) {
      const minDate = new Date();
      const maxDate = new Date();
      this.minDate = new Date(minDate.setFullYear(minDate.getFullYear() - 75));
      this.maxDate = new Date(maxDate.setFullYear(maxDate.getFullYear() - 18));
    }
  }

  /**
   * Se ejecuta al destruir el componente.
   */
  public ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  /**
   * Verirfica si es cambiado.
   * @param obj - Callback.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  public onChange = (obj: any) => {};

  /**
   * Verirfica si es tocado.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  public onTouch: any = () => {};

  /**
   * Verirfica si es escrito.
   * @param obj - Callback.
   * @throws Error.
   */
  public writeValue(obj: any): void {
    let value: any;
    if (typeof obj === 'undefined' || !obj) {
      value = '';
    } else if (typeof obj === 'object' && obj instanceof Date) {
      value = obj;
    } else if (
      typeof obj === 'string' &&
      obj.length > 0 &&
      moment(obj).isValid()
    ) {
      value = new Date(obj);
    } else {
      value == '';
    }
    this._formControl.patchValue(value);
  }

  /**
   * Verirfica si es cambiado.
   * @param fn - Callback.
   * @throws Error.
   */
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Verirfica si es tocado.
   * @param fn - Callback.
   * @throws Error.
   */
  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  /**
   * Verifica si el campo fue deshabilitado.
   * @param isDisabled - Bandera.
   */
  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this._formControl.disable();
    } else {
      this._formControl.enable();
    }
  }
}
