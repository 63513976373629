import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

/**
 * Componente de cargar archivos.
 */
@Component({
  selector: 'wp-back-office-file-upload',
  templateUrl: './file-upload.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileUploadComponent,
      multi: true,
    },
  ],
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent
  implements ControlValueAccessor, OnInit, OnDestroy
{
  /**
   * Entrada obs.
   */
  @Input()
  public obs$: Subject<any> = new Subject();
  /**
   * Funcion.
   */
  public onChange: any = new Function();
  /**
   * Archivo.
   */
  public file: File | null = null;
  /**
   * Errores.
   */
  public error: string;
  /**
   * Exitoso.
   */
  public success: string;
  /**
   * Destructor sujeto.
   */
  private destroy$ = new Subject();

  /**
   * Crea una instancia de FileUploadComponent.
   * @param host - Elemento referencia.
   */
  public constructor(private host: ElementRef<HTMLInputElement>) {
    this.error = '';
    this.success = '';
  }

  /**
   * Evento de escucha para archivos.
   * @param event - Evento entrada.
   */
  @HostListener('change', ['$event.target.files']) public emitFiles(event: FileList) {
    const file = event && event.item(0);
    this.onChange(file);
    this.file = file;
  }

  /**
   * A callback method that is invoked immediately after the default change detector has checked.
   */
  public ngOnInit(): void {
    this.obs$.pipe(takeUntil(this.destroy$)).subscribe((ev: any) => {
      if (!ev.ok) {
        this.error = ev.error;
        this.success = '';
      }
      if (ev.ok) {
        this.error = '';
        this.success = 'Archivo subido con éxito';
      }
    });
  }

  /**
   * A callback method that performs custom clean-up, invoked immediately before a directive, pipe, or service instance is destroyed.
   */
  public ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  /**
   * Cambiar estado.
   * @throws Error.
   */
  public setDisabledState?(): void {
    throw new Error('Method not implemented.');
  }

  /**
   * Evento para escribir un valor.
   */
  public writeValue() {
    this.host.nativeElement.value = '';
    this.file = null;
  }

  /**
   * Evento de cambio.
   * @param fn - Callback.
   */
  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  /**
   * Evento de tocado.
   * @throws Error.
   */
  public registerOnTouched(): void {
    throw new Error('Method not implemented.');
  }
}
