import { Catalogue } from './catalogue.model';

/**
 * Modelo de paises estado.
 */
export interface CountryState {
  /**
   * Paises.
   */
  countries: Catalogue[];
  /**
   * Ciudades.
   */
  cities: Catalogue[];
  /**
   * Departamentos.
   */
  departments: Catalogue[];
}
