import { MarketItem, MarketList } from './market.model';

/**
 * Modelo Rooms por Oficinas.
 */
export interface RoomsOfficeId<T> {
  /**
   * Item de la Sala para traer codigo.
   */
  item?: T;
  /**
   * Nombre de la Sala.
   */
  name: string;
  /**
   * Tipo de mercado.
   */
  marketType: string;
  /**
   * Grupo.
   */
  group: string;
  /**
   * Zona.
   */
  zone: string;
  /**
   * Estado.
   */
  enabled: boolean;
}

/**
 * Modelo para llenar la tabla de Salas de una Oficina.
 */
export interface RoomTable {
  /**
   *
   */
  description_office: string;
  /**
   *
   */
  description_roomzone: string;
  /**
   *
   */
  code_office: number;
  /**
   *
   */
  code_room: number;
  /**
   *
   */
  code_room_zone: number;
  /**
   *
   */
  code_bir: string;
  /**
   *
   */
  description: string;
  /**
   *
   */
  city: string;
  /**
   *
   */
  biling_email: string;
  /**
   *
   */
  notification_email: string;
  /**
   *
   */
  type_sale: string;
  /**
   *
   */
  telephone: string;
  /**
   *
   */
  cell_phone: string;
  /**
   *
   */
  postal_code: string;
  /**
   *
   */
  address: string;
  /**
   *
   */
  adress_structure: string;
  /**
   *
   */
  seller_commission: string;
  /**
   *
   */
  enabled: number;
  /**
   *
   */
  region: string;
  /**
   *
   */
  country: string;
  /**
   *
   */
  state: string;
}

/**
 * Modelo para llenar la tabla de Salas de una Oficina.
 */
export interface RoomsUserId {
  /**
   * Código de la sala.
   */
  code_room: number;
  /**
   * Nombre de la sala.
   */
  description: string;
  /**
   * Código oficina.
   */
  officeCode: number;
  /**
   * Descripción de la oficina.
   */
  officeDescription: string;
  /**
   * Lista de mercados.
   */
  markets: MarketItem[];
}

/**
 * Modelo para llenar la tabla de Salas de una Oficina.
 */
export interface RoomsUserTable {
  /**
   * Código de la sala.
   */
  roomCode: string;
  /**
   * Nombre de la sala.
   */
  room: string;
  /**
   * Código oficina.
   */
  officeCode: string;
  /**
   * Descripción de la oficina.
   */
  branchOffices: string;
  /**
   * Datos.
   */
  item: RoomsUserId;
}

/**
 * Modelo zonas de las salas.
 */
export interface RoomsZones {
  /**
   * Código de la sala.
   */
  code: string;
  /**
   * Descripcion de la zona.
   */
  description: string;
}

/**
 * Modelo zonas de las salas.
 */
export interface Zones {
  /**
   * Código de la sala.
   */
  code_room_zone: string;
  /**
   * Descripcion de la zona.
   */
  description: string;
}

/**
 * Modelo vendedores por Salas.
 */
export interface RoomSellers {
  /**
   * Nombre del vendedor.
   */
  name: string;
  /**
   * Identificación.
   */
  identification: string;
  /**
   * Concesionario.
   */
  office: string;
  /**
   * Código de la sala.
   */
  room: string;
}

/**
 * Modelo de retorno eliminar sala.
 */
export interface RoomDelete {
  /**
   * Código de la sala eliminada.
   */
  code?: number;
}
/**
 *
 */
export interface Room {
  /**
   *
   */
  city: string;
  /**
   *
   */
  code_office: number;
  /**
   *
   */
  code_office_group: number;
  /**
   *
   */
  code_room: number;
  /**
   *
   */
  description_group: string;
  /**
   *
   */
  description_markets: string;
  /**
   *
   */
  description_office: string;
  /**
   *
   */
  description_room: string;
  /**
   *
   */
  state: string;
}

/**
 *
 */
export interface RoomInput {
  /**
   *
   */
  office_description: string;
  /**
   *
   */
  office_code: number;
  /**
   *
   */
  office_group_description: string;
  /**
   *
   */
  office_group_code: number;
  /**
   *
   */
  code_room: number;
  /**
   *
   */
  code_bir: string;
  /**
   *
   */
  description: string;
  /**
   *
   */
  country: string;
  /**
   *
   */
  state: string;
  /**
   *
   */
  city: string;
  /**
   *
   */
  postal_code: string;
  /**
   *
   */
  address: string;
  /**
   *
   */
  adress_structure: string;
  /**
   *
   */
  enabled: number;
  /**
   *
   */
  biling_email: string;
  /**
   *
   */
  notification_email: string;
  /**
   *
   */
  type_sale: string;
  /**
   *
   */
  telephone: string;
  /**
   *
   */
  cell_phone: string;
  /**
   *
   */
  region: string;
  /**
   *
   */
  code_office: number;
  /**
   *
   */
  code_room_zone: number;
  /**
   *
   */
  markets: MarketItem[];
}
