import { CommonModule } from '@angular/common';
import { DynamicButtonModule } from '../dynamic-button';
import { DynamicFormModule } from '../dynamic-form';
import { DynamicStepperComponent } from './components/dynamic-stepper/dynamic-stepper.component';
import { dynamicStepperReducers } from './store';
import { PrivateDynamicStepperService } from './services/private-dynamic-stepper.service';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { DynamicStepperService } from './services/dynamic-stepper.service';

/**
 * Definición del NgModule.
 */
@NgModule({
  declarations: [DynamicStepperComponent],
  imports: [
    CommonModule,
    DynamicButtonModule,
    DynamicFormModule,
    StoreModule.forFeature('dynamicStepperState', dynamicStepperReducers),
  ],
  exports: [DynamicStepperComponent],
  providers: [PrivateDynamicStepperService, DynamicStepperService],
  schemas: [NO_ERRORS_SCHEMA],
})
/**
 * Modulo del formulario dinámico de steppers.
 */
export class DynamicStepperFormModule {}
