import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { Catalogue } from '@wp-back-office/core/store';
import {
  SelectResponse,
  ControlConfig,
} from '@wp-back-office/shared/dynamic-components';
import { map, Observable, of, startWith, Subject, take, takeUntil } from 'rxjs';
import { DynamicFormService } from '../../../../services/dynamic-form.service';
import { repeatUntil } from '@wp-back-office/core/workflow-sdk';
import { CataloguesStore } from 'libs/core/commons-backoffice/src/lib/services/catalogues-store';

/**
 * Formulario Autocomplete.
 */
@Component({
  selector: 'wp-back-office-form-control-autocomplete',
  templateUrl: './form-control-autocomplete.component.html',
  styleUrls: ['./form-control-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormControlAutocompleteComponent),
      multi: true,
    },
  ],
})
/**
 *
 */
export class FormControlAutocompleteComponent
  implements AfterViewInit, OnDestroy, ControlValueAccessor
{
  /**
   * Id unico del formulario.
   */
  @Input()
  public uniqueIdForm!: string;

  /**
   * Abre el autocomplete.
   */
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger })
  public inputAutoComplete!: MatAutocompleteTrigger;

  /**
   * Configuracion del control.
   */
  @Input()
  public set control(value: ControlConfig | undefined) {
    if (value) {
      this.controlConfig = value;
      this._formControl = this.dynamicFormService.setValidators(value);
    }
  }

  /**
   * Evento de formulario valido.
   */
  @Input()
  public table!: boolean;

  /**
   * Evento de formulario valido.
   */
  @Input()
  public value!: any;

  /**
   * Carga del formulario.
   */
  @Input()
  public loading: boolean;

  /**
   * Valores iniciales.
   */
  @Input()
  public getCatalogue?: boolean = true;

  /**
   * Evento de formulario valido.
   */
  @Output()
  public selectInput: EventEmitter<SelectResponse> =
    new EventEmitter<SelectResponse>();
  /**
   * Evento de formulario valido.
   */
  @Output()
  public infiniteScroll: EventEmitter<SelectResponse> =
    new EventEmitter<SelectResponse>();

  /**
   * Evento de actualizacion de opciones.
   */
  @Output()
  public updateOptions: EventEmitter<any[]> = new EventEmitter<any[]>();

  /**
   * Evento focus.
   */
  @Output()
  public controlFocus: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Evento focusOut.
   */
  @Output()
  public controlFocusOut: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Form control del campo.
   */
  public _formControl: FormControl;

  /**
   * Destructor observables.
   */
  private destroy$: Subject<boolean>;

  /**
   * Configuracion del control variable.
   */
  public controlConfig!: ControlConfig;

  /**
   * Posicion vertical.
   */
  private currentVerticalPosition: number;
  /**
   * Tiempo de espera para el evento scroll.
   */
  public scrollEventTimeout!: boolean;
  /**
   * Opciones flltradas.
   */
  public filteredOptions: Observable<Catalogue[]>;
  /**
   * Opciones sin filtrar.
   */
  public options: Catalogue[];

  /**
   * Crea una instancia de la clase.
   * @param dynamicFormService - Servicio de formularios dinamicos.
   * @param catalogueService - Servicio del catalogo.
   * @param cdRef - Detector de cambios.
   */
  constructor(
    public dynamicFormService: DynamicFormService,
    public cdRef: ChangeDetectorRef,
    private cataloguesStore: CataloguesStore
  ) {
    this.loading = false;
    this._formControl = new FormControl();
    this.destroy$ = new Subject();
    this.currentVerticalPosition = 0;
    this.scrollEventTimeout = true;
    this.filteredOptions = of([]);
    this.options = [];
  }

  /**
   * Ejecuta el evento focus del input.
   * @param event - Evento focus.
   */
  public onFocus(event: FocusEvent): void {
    this.controlFocus.emit(event);
  }

  /**
   * Ejecuta el evento focusOut del input.
   * @param event - Evento focusOut.
   */
  public onFocusOut(event: FocusEvent): void {
    this.controlFocusOut.emit(event);
  }

  /**
   * Se ejecutar al iniciar el componente.
   */
  public ngAfterViewInit() {
    if (this.controlConfig) {
      this._formControl = this.dynamicFormService.setValidators(
        this.controlConfig
      );

      this.initControl();

      this.inputAutoComplete?.autocomplete.opened
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          if (this.loading) {
            this.inputAutoComplete?.closePanel();
          }
        });
    }
  }

  /**
   * Se ejecuta despues de renderizar el componente.
   */
  public initControl(): void {
    this._formControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.onChange(data);
        if (this._formControl.touched) {
          this.onTouch(data);
        }
      });

    this.loadCatalogue();

    if (this.controlConfig?.value) {
      this.writeValue(this.controlConfig.value);
    }

    if (this.value) {
      this.writeValue(this.value);
    }
  }

  /**
   * Cargar opciones.
   */
  public loadCatalogue() {
    // CONSULTA DE CATALOGOS
    if (
      (!this.controlConfig?.keyOptions?.disableLocal &&
        this.controlConfig?.keyOptions?.entity &&
        this.getCatalogue) ||
      (!this.controlConfig?.keyOptions?.disableLocal &&
        this.controlConfig?.keyOptions?.entity &&
        !this.getCatalogue)
    ) {
      this.loading = true;
      this.controlConfig = {
        ...this.controlConfig,
        validators: {
          ...this.controlConfig?.validators,
          loading: true,
        },
      };

      this.cataloguesStore
        .getCatalogueService({
          control: {
            controlConfig: this.controlConfig,
          },
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe((ev: any[]) => {
          const filter = this.controlConfig?.validators?.filterCatalogOptions;
          const controlOptions = filter !== undefined && filter.keyValue !== ''
            ? ev.filter(e => e[filter.keyValue] === filter.equalsTo)
            : ev;
          
          this.controlConfig = {
            ...this.controlConfig,
            validators: {
              ...this.controlConfig?.validators,
              loading: false,
            },
            options: [...controlOptions],
          };
          this.options = [...controlOptions];

          this.updateOptions.emit([...controlOptions]);
          this.valideOptions();
          this.initAutocomplete();
          this.cdRef.detectChanges();
        });
    } else {
      this.options = this.controlConfig?.options || [];
      this.valideOptions();
      this.initAutocomplete();
    }
  }

  /**
   * Inicializa el autoComplete.
   */
  public initAutocomplete() {
    if (this.controlConfig?.autocompleteConfig?.internalFilter) {
      this.filteredOptions = this._formControl.valueChanges.pipe(
        takeUntil(this.destroy$),
        startWith(''),
        map(value => {
          const description =
            typeof value === 'string' ? value : value?.description;
          return description
            ? this.filter(description as string)
            : this.options.slice();
        })
      );
    }
  }

  /**
   * Valida si hay una sola opcion en la lista.
   */
  public valideOptions() {
    if (
      this.controlConfig?.validators?.required &&
      this.options.length === 1 &&
      this.options[0].code &&
      !this.controlConfig?.uniqueOptionAutoFillDeactivated
    ) {
      this._formControl.patchValue(this.options[0]);
    }

    const controlValue = this._formControl.value;
    if (
      controlValue &&
      typeof controlValue === 'object' &&
      controlValue.code &&
      !controlValue.description
    ) {
      repeatUntil(
        () => (this.controlConfig.options || []).length > 0,
        () => {
          const value = this.getOptionFromCode(controlValue);
          if (value && value?.code && value.description) {
            this._formControl.patchValue(value);
          }
          return of();
        },
        { attempts: 10, initial_time: 1000, time: 1000 }
      )
        .pipe(takeUntil(this.destroy$), take(1))
        .subscribe();
    }
  }

  /**
   * Muestra la descripcion del catalogo.
   * @param value -Valor del control.
   * @returns String.
   */
  public displayFn(value: Catalogue): string {
    return value && value.description ? value.description : '';
  }

  /**
   * Filtro interno del _formControl.
   * @param value - Valor a filtrar.
   * @returns - Catalogue[].
   */
  public filter(value: string): Catalogue[] {
    const filterValue = value.toLowerCase();
    setTimeout(() => {
      this.cdRef.detectChanges();
    }, 100);
    return this.options.filter(option =>
      option?.description?.toLowerCase()?.includes(filterValue)
    );
  }

  /**
   * Se ejecuta al destruir el componente.
   */
  public ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  /**
   * Actualiza los dropdown con el id del objeto.
   * @param c1 - Valor inicial.
   * @param c2 - Valor final.
   * @returns Estado booleano.
   */
  public compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.code === c2.code : c1 === c2;
  }

  /**
   * Emite un evento al seleccionar un valor en un select en el output selectInput.
   * @param data - Valor seleccionado.
   * @param controlKey - Nombre del contol.
   */
  public onSelect(
    data: MatAutocompleteSelectedEvent,
    controlKey: string | undefined
  ) {
    this.selectInput.emit({
      keycontrol: controlKey || '',
      value: data.option.value,
    });
  }

  /**
   * Emite un evento al hacer scroll hacia abajo en un select en el output infiniteScroll.
   * @param options - Opciones actuales.
   * @param controlKey - Nombre del control.
   */
  public onInfiniteScroll(options: any, controlKey: string | undefined): void {
    this.infiniteScroll.emit({
      keycontrol: controlKey || '',
      value: {
        code: '',
        description: '',
      },
      options: options,
    });
  }

  /**
   * Verirfica si es cambiado.
   * @param obj - Callback.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @nrwl/nx/workspace/doc-class-property, @typescript-eslint/no-empty-function
  public onChange = (obj: any) => {};

  /**
   * Verirfica si es tocado.
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onTouch: any = () => {};

  /**
   * Verirfica si es escrito.
   * @param obj - Callback.
   * @throws Error.
   */
  public writeValue(obj: any): void {
    if (obj && typeof obj === 'object' && obj?.code && !obj?.description) {
      repeatUntil(
        () => (this.controlConfig.options || []).length > 0,
        () => {
          const value = this.getOptionFromCode(obj);
          if (
            value &&
            typeof obj === 'object' &&
            value?.code &&
            value?.description
          ) {
            this._formControl?.patchValue(value);
          } else {
            this._formControl?.patchValue(obj);
          }
          return of();
        },
        { attempts: 10, initial_time: 1000, time: 1000 }
      )
        .pipe(takeUntil(this.destroy$), take(1))
        .subscribe();
    } else {
      this._formControl?.patchValue(obj);
    }

    setTimeout(() => {
      if (!this.getCatalogue || this.controlConfig.keyOptions?.disableLocal) {
        const options =
          this.controlConfig.options && this.controlConfig.options?.length > 1
            ? this.controlConfig.options
            : [obj];

        this.controlConfig = {
          ...this.controlConfig,
          options,
        };

        this.options = options;
      }
      this.cdRef.detectChanges();
    }, 100);
  }

  /**
   * Verirfica si es cambiado.
   * @param fn - Callback.
   * @throws Error.
   */
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  /**
   * Verirfica si es tocado.
   * @param fn - Callback.
   * @throws Error.
   */
  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  /**
   * Deshabilitar control.
   * @param isDisabled - Bandera.
   */
  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this._formControl.disable();
    } else {
      this._formControl.enable();
    }
  }

  /**
   * A.
   * @param event -.
   * @param options -.
   * @param key -.
   */
  public onScroll(event: any, options: any, key: any) {
    const scrollTop = event.srcElement.scrollTop;
    /**
     * Abajo sin movimiento horizontal.
     */
    if (scrollTop > this.currentVerticalPosition) {
      this._valideBottomPosition(event, options, key);
    }
    this.currentVerticalPosition = scrollTop;
  }

  /**
   * Valida si el contenedor de la tabla está abajo.
   * @param event - Evento Scroll.
   * @param options -.
   * @param key -.
   */
  private _valideBottomPosition(event: any, options: any, key: any): void {
    const obj = event.srcElement;
    if (
      this.difference(obj.scrollTop, obj.scrollHeight - obj.offsetHeight) <= 200
    ) {
      if (this.scrollEventTimeout) {
        this.scrollEventTimeout = false;
        setTimeout(() => {
          this.scrollEventTimeout = true;
        }, 600);
        this.infiniteScroll.emit({
          keycontrol: key || '',
          value: {
            code: '',
            description: '',
          },
          options: options,
        });
      }
    }
  }

  /**
   * Validar diferencia entre dos numeros.
   * @param num1 - Primer numero.
   * @param num2 - Segundo numero.
   * @returns Number.
   */
  public difference(num1: number, num2: number) {
    return num1 > num2 ? num1 - num2 : num2 - num1;
  }

  /**
   * Abre el panel del _formControl.
   */
  public openPanel() {
    this.inputAutoComplete.openPanel();
    this.cdRef.detectChanges();
  }

  /**
   * Calcula el margen inferior de la ultima opcion del autocomplete para siempre hacer scroll.
   * @param options - Opciones visibles del autocomplete.
   * @returns Object.
   */
  public calculateStyle(options: Catalogue[] | null): any {
    if ((options?.length || 0) <= 5) {
      return {
        'margin-bottom.px': 48 * (5 - (options?.length || 0)) + 20,
        'height.px': 0,
      };
    } else {
      return {
        'height.px': 0,
      };
    }
  }

  /**
   * Retorna un catalogo con su code.
   * @param obj - Catalogo.
   * @returns Catalogue | undefined.
   */
  public getOptionFromCode(obj: Catalogue): Catalogue | undefined {
    if (obj && typeof obj === 'object' && obj?.code && !obj?.description) {
      if (!isNaN(Number(obj.code))) {
        return (this.controlConfig.options || []).find(
          opt => Number(opt.code) === Number(obj.code)
        );
      } else {
        return (this.controlConfig.options || []).find(
          opt => `${opt.code}` === `${obj.code}`
        );
      }
    } else {
      return obj;
    }
  }
}
