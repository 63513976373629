/**
 * Modelo para auditar detalle.
 */
export interface AuditDetailModel {
  /**
   * Identificador.
   */
  id: number;
  /**
   * Indice.
   */
  index: number;
  /**
   * Nombre.
   */
  name: string;
  /**
   * Subproceso.
   */
  subProcess: boolean;
  /**
   * Tiempo.
   */
  timestamp: string;
  /**
   * Tipo.
   */
  type: string;
  /**
   * Datos.
   */
  data: any;
  /**
   * Estilo.
   */
  style: string;
}
