/**
 * Modelo del las variables de los cards.
 */
export interface HeaderVar {
  /**
   * Identificador.
   */
  code: string | undefined;
  /**
   * Valor.
   */
  description: string | undefined;
  /**
   * Valor.
   */
  ngStyle?: any;
  /**
   * Valor.
   */
  copy?: boolean;
}
