import { Catalogue } from '../../../catalogue/models';
/**
 * Modelo para error.
 */
export interface ErrorCommon {
  /**
   * Mensaje.
   */
  message: string;
  /**
   * Estado.
   */
  status: number;
}

/**
 * Modelo de informacion personal del cliente.
 */
export interface PersonalInformations {
  /**
   *  Comentario.
   */
  bornCountry?: Catalogue;
  /**
   * Comentario.
   */
  bornDate?: string;
  /**
   * Comentario.
   */
  documentNumber?: string;
  /**
   * Comentario.
   */
  documentType?: Catalogue;
  /**
   * Comentario.
   */
  lastName?: string;
  /**
   * Comentario.
   */
  secondLastName?: string;
  /**
   * Comentario.
   */
  name?: string;
  /**
   * Comentario.
   */
  secondName?: string;
}

/**
 * Modelo informacion de contacto.
 */
export interface ContactInformationData {
  /**
   * Numero telefonico.
   */
  mobileNumber?: string;
  /**
   * Correo electronico.
   */
  email?: string;
  /**
   * Numero telefonico.
   */
  mobileNumberRepeat?: string;
  /**
   * Correo electronico.
   */
  emailRepeat?: string;
}

/**
 * Modelo aplicante paso uno.
 */
export interface ApplicantsStepOne {
  /**
   * Identificador contiene el id de tipo documento y el numero de documento.
   */
  id?: string;
  /**
   * Tipo de aplicante.
   */
  type?: Catalogue;
  /**
   * Informacion personal del cliente.
   */
  personalInformations?: PersonalInformations;
  /**
   * Informacion contacto del cliente.
   */
  contactInformations?: ContactInformationData;
  /**
   * Informacion autocompletada del formulario.
   */
  autoFillInformation?: ApplicantsStepThree;
  /**
   * Informacion de integraciones.
   */
  integrationData?: any;
  /**
   * Metodo de verificacion.
   */
  verificationMethod: 'otp' | 'document';
}

/**
 * Modelo de informacion basica.
 */
export interface BasicInformation {
  /**
   * Arreglo de aplicantes.
   */
  applicants?: ApplicantsStepOne[];
}

/**
 * Data para la validacion de la informacion de contacto.
 */
export interface ContactInfoCheck {
  /**
   * Informacion de contacto a validar.
   */
  codes: string[];
}

/**
 * Payload de envio de codigo de otp.
 */
export interface SendOtpCode {
  /**
   * Email a enviar OTP.
   */
  email: string;
  /**
   * Celular a enviar OTP.
   */
  phone: string;
  /**
   * Tipo de documento.
   */
  documentType: string;
  /**
   * Numero de documento.
   */
  documentNumber: string;
  /**
   * Id del mercado.
   */
  idMarket: number;
  /**
   * Id del proceso.
   */
  idProcess: string;
}

/**
 * Modelo de respuesta al solicitar OTP.
 */
export interface SendOtpResponse {
  /**
   * Datos del email.
   */
  dataEmail: DataEmail;
  /**
   * Datos para mensaje de texto.
   */
  dataSms: DataSMS;
}

/**
 * Datos de email.
 */
export interface DataEmail {
  /**
   * Estado.
   */
  status: string;
  /**
   * Descripcion.
   */
  description: string;
  /**
   * Datos.
   */
  data: DataEmailData;
}

/**
 * Datos de email.
 */
export interface DataEmailData {
  /**
   * Id de envio OTP.
   */
  deliveryId: string;
}

/**
 * Datos de envio SMS.
 */
export interface DataSMS {
  /**
   * Codigo status.
   */
  statusCode: number;
  /**
   * Mensaje de estatus.
   */
  statusMessage: string;
  /**
   * Id de envio OTP.
   */
  messageId: string;
}

/**
 * Payload de verificar codigo OTP.
 */
export interface VerificationOtpCode {
  /**
   * Email a enviar OTP.
   */
  email: string;
  /**
   * Celular a enviar OTP.
   */
  phone: string;
  /**
   * Tipo de documento.
   */
  documentType: string;
  /**
   * Numero de documento.
   */
  documentNumber: string;
  /**
   * Codigo de OTP a validar.
   */
  otp: string;
}
/**
 * Modelo de respuesta al verificar OTP.
 */
export interface VerificationOtpResponse {
  /**
   * Documento del usuario.
   */
  userDocumentNumber: string;
  /**
   * Codigo de expiracion.
   */
  expirationCode: number;
  /**
   * Fecha de expiracion.
   */
  expirationDate: number;
  /**
   * Correo del usuario.
   */
  userEmail: string;
  /**
   * Id del proceso.
   */
  idProcess: string;
  /**
   * Datos.
   */
  data: string;
  /**
   * Codigo OTP.
   */
  otp: string;
  /**
   * Fecha tipo Timestamp.
   */
  timeStamp: number;
  /**
   * Codigo de tipo de documento del usuario.
   */
  userDocumentType: string;
  /**
   * Telefono del usuario.
   */
  userPhone: string;
}

/**
 * Payload de integracion con transunion para obtener score.
 */
export interface ScoreTransunion {
  /**
   * Apellido cliente.
   */
  lastName?: any;
  /**
   * Identificación para consultar en transunion.
   */
  identification?: any;
  /**
   * Tipo de indentificacion.
   */
  identificationType?: any;
  /**
   * Canal para guardar log en DynamoBD.
   */
  chanel?: any;
  /**
   * Forzar consumo a transunion o traer log.
   */
  forceRequest?: any;
}

/**
 * Payload de integracion con experian para obtener score.
 */
export interface ScoreExperian {
  /**
   * Apellido.
   */
  lastName: string;
  /**
   * Documento.
   */
  documentNumber: string;
  /**
   * Tipo de documento.
   */
  documentType: string;
  /**
   * Forzar consumo a experian o traer log.
   */
  forceRequest?: boolean;
}

/**
 * Modelo de informacion general del producto.
 */
export interface GeneralProductInformation {
  /**
   * Tipo producto.
   */
  type?: Catalogue;
  /**
   * Modelo producto.
   */
  model?: Catalogue;
  /**
   * Familia.
   */
  family?: Catalogue;
  /**
   * Referencia.
   */
  reference?: Catalogue;
  /**
   * Linea.
   */
  line?: Catalogue;
  /**
   * Version.
   */
  version?: Catalogue;
  /**
   * Servicio.
   */
  service?: Catalogue;
  /**
   * Accesorios.
   */
  accesories?: Catalogue;
  /**
   * Bandera de fleet.
   */
  fleet?: boolean;
}

/**
 * Modelo de informacion financiera del producto.
 */
export interface FinancialProductInformation {
  /**
   * Codigo del plan.
   */
  planCode?: string;
  /**
   * Tipo de plan.
   */
  planType?: string;
  /**
   * Bandera de alianza.
   */
  allianceEmployee?: boolean;
  /**
   * Valor del vehiculo.
   */
  vehicleValue?: number;
  /**
   * Monto inicial.
   */
  initialFee?: number;
  /**
   * Terminos.
   */
  term?: string;
  /**
   * Soat y tuition.
   */
  soatAndTuition?: string;
  /**
   * Tipo mantenimiento.
   */
  maintenance?: Catalogue;
  /**
   * Resultado de viabilidad.
   */
  viabilityResult?: string;
}

/**
 * Modelo del producto.
 */
export interface Product {
  /**
   * Informacion general del producto.
   */
  generalProductInformation?: GeneralProductInformation;
  /**
   * Informacion financiera del producto.
   */
  financialProductInformation?: FinancialProductInformation;
}

/**
 * Modelo de ingreso.
 */
export interface Income {
  /**
   * Actividad economica.
   */
  economicActivity?: Catalogue;
  /**
   * Frecuencia.
   */
  frecuency?: Catalogue;
  /**
   * Monto.
   */
  monto?: number;
}

/**
 * Modelo de gastos.
 */
export interface Expens {
  /**
   * Actividad economica.
   */
  economicActivity?: Catalogue;
  /**
   * Frecuencia.
   */
  frecuency?: Catalogue;
  /**
   * Monto.
   */
  monto?: number;
  /**
   * Fecha final.
   */
  endDate?: number;
}

/**
 * Modelo de activos.
 */
export interface Active {
  /**
   * Tipo de activo.
   */
  typeActive?: Catalogue;
  /**
   * Monto.
   */
  monto?: number;
}

/**
 * Pasivo.
 */
export interface Passive {
  /**
   * Tipo de activo.
   */
  typePassive?: Catalogue;
  /**
   * Monto.
   */
  monto?: number;
}

/**
 * Modelo de aplicante paso tres.
 */
export interface ApplicantsStepThree {
  /**
   * Identificador contiene el id de tipo documento y el número de documento.
   */
  id: string;
  /**
   * Tipo producto.
   */
  type: Catalogue;
  /**
   * Detalles información personal.
   */
  detailPersonalInformation: {
    /**
     * Información personal.
     */
    personalInformation: any;
    /**
     * Información de residencia.
     */
    residenceInformation: any;
  };
  /**
   * Información de estudio y trabajo.
   */
  workAndStudyInformation: {
    /**
     * Información academica.
     */
    studyInformation: any;
    /**
     *  Información laboral.
     */
    workInformation: any;
  };
  /**
   * Información financiera.
   */
  financialInformation: {
    /**
     * Ingresos.
     */
    incomes: any[];
    /**
     * Comentario.
     */
    incomeComments: string;
    // expenses: any[];
    // assets: any[];
    // passives: any[];
    /**
     * Total activos.
     */
    totalActives: number;
    /**
     * Total gastos.
     */
    totalExpenses: number;
    /**
     * Total.
     */
    totalHeritage: number;
    /**
     * Total ingresos.
     */
    totalIncomes: number;
    /**
     * Total pasivos.
     */
    totalPassives: number;
  };
  /**
   * Información general.
   */
  generalInformation: {
    /**
     * Persona políticamente expuesta.
     */
    politicalExposedPerson: {
      /**
       * Comentario.
       */
      enable: any;
      /**
       * Comentario.
       */
      pepInformation: any;
    };
    /**
     * Operadores foraneos.
     */
    foreignOperations: {
      /**
       * Comentario.
       */
      enable: any;
      /**
       * Comentario.
       */
      operations: any[];
    };
    /**
     * Debito automático.
     */
    automaticDebit: {
      /**
       * Comentario.
       */
      enable: any;
      /**
       * Comentario.
       */
      accountInformation: any;
    };
  };
}

/**
 * Modelo informacion a detalle.
 */
export interface DetailInformation {
  /**
   * Aplicantes.
   */
  applicants?: ApplicantsStepThree[];
}
