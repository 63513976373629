import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { Form } from '../models/form-task.models';
import { taskPjActions } from './actions';

/**
 * Modelo estado de procesos.
 */
export interface DynamicStepperState {
  /**
   * Formularios tareas.
   */
  Forms?: Form[];
}

export const initialState: DynamicStepperState = {
  Forms: undefined,
};

export const dynamicStepperReducers = createReducer(
  initialState,
  on(taskPjActions.setSchema, (state, { forms }) => ({
    ...state,
    Forms: forms,
  })),
  on(taskPjActions.reset, () => initialState)
);

/**
 * Selector de token de acesso.
 * @param state - Estado.
 * @returns Token.
 */
export const featureDynamicStepper = createFeatureSelector<DynamicStepperState>(
  'dynamicStepperState'
);
