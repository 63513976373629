import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Form } from '../../../models/form-task.models';

/**
 * Accciones del payload.
 */
export const taskPjActions = createActionGroup({
  source: 'taskSchemaDynamicActions',
  events: {
    /**
     * Formularios para tareas.
     */
    'Set Schema': props<{
      /**
       * Parametros para envio del servicio.
       */
      forms: Form[];
    }>(),
    reset: emptyProps(),
  },
});
