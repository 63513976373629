/* eslint-disable jsdoc/require-jsdoc */
export interface ProviderProcess {
  provider: string;
  user: string;
  product: string;
  data: Data;
}

export interface Data {
  provider?: Provider;
  listMatch?: any[];
  userInfo?: UserInfo;
}

export interface Provider {
  producto: string;
  providerName: string;
}

export interface UserInfo {
  IdentificadorLinea: string;
  TipoIdentificacion: string;
  CodigoTipoIndentificacion: string;
  NumeroIdentificacion: string;
  NombreTitular: string;
  LugarExpedicion: string;
  FechaExpedicion: string;
  Estado: string;
  NumeroInforme: string;
  RangoEdad: string;
  CodigoCiiu: string;
  CodigoDepartamento: string;
  CodigoMunicipio: string;
  Fecha: string;
  Hora: string;
  Entidad: string;
  RespuestaConsulta: string;
  Score?: Score;
  Consolidado?: Consolidado;
  CuentasVigentes?: Cuentas;
  CuentasExtinguidas?: Cuentas;
  SectorFinancieroAlDia?: SectorFinanciero;
  SectorFinancieroExtinguidas?: SectorFinanciero;
  SectorRealAlDia?: SectorReal;
  SectorRealExtinguidas?: SectorReal;
  Endeudamiento?: Endeudamiento;
  IncomeEstimator?: IncomeEstimator;
  consultationDate?: number;
  expirationDate?: number;
}

export interface Consolidado {
  ResumenPrincipal: ResumenPrincipal;
  Registro: Registro;
}

export interface Registro {
  PaqueteInformacion: string;
  NumeroObligaciones: string;
  TotalSaldo: string;
  ParticipacionDeuda: string;
  NumeroObligacionesDia: string;
  SaldoObligacionesDia: string;
  CuotaObligacionesDia: string;
  CantidadObligacionesMora: string;
  SaldoObligacionesMora: string;
  CuotaObligacionesMora: string;
  ValorMora: string;
}

export interface ResumenPrincipal {
  Registro: Registro[];
}

export interface Cuentas {
  Obligacion: CuentasExtinguidasObligacion[];
}

export interface CuentasExtinguidasObligacion {
  PaqueteInformacion: string;
  IdentificadorLinea: string;
  TipoContrato: string;
  TipoEntidad: string;
  NombreEntidad: string;
  Ciudad: string;
  Sucursal: string;
  NumeroObligacion: string;
  EstadoObligacion: string;
  FechaApertura: string;
  ValorInicial?: string;
  Comportamientos: string;
  FechaCorte: string;
  DiasCartera?: string;
  ChequesDevueltos?: string;
}

export interface Endeudamiento {
  EncabezadoEndeudamiento: EncabezadoEndeudamiento;
  EndeudamientoTrimI: EndeudamientoTrimI;
  EndeudamientoTrimII: EndeudamientoTrimII;
  EndeudamientoTrimIII: EndeudamientoTrimIII;
}

export interface EncabezadoEndeudamiento {
  NumeroEntidadesTrimestreI: string;
  FechaTrimestreI: string;
  NumeroEntidadesTrimestreII: string;
  FechaTrimestreII: string;
  NumeroEntidadesTrimestreIII: string;
  FechaTrimestreIII: string;
  NumeroComprasTrimestreI: string;
  NumeroComprasTrimestreII: string;
  NumeroComprasTrimestreIII: string;
  NumeroReestructuracionesTrimestreI: string;
  NumeroReestructuracionesTrimestreII: string;
  NumeroReestructuracionesTrimestreIII: string;
  NumeroCastigosTrimestreI: string;
  NumeroCastigosTrimestreII: string;
  NumeroCastigosTrimestreIII: string;
}

export interface EndeudamientoTrimI {
  Endeudamiento71: Endeudamiento71Element[];
  Endeudamiento72: Endeudamiento72Element[];
  Endeudamiento73: Endeudamiento73Element[];
}

export interface Endeudamiento71Element {
  Calificacion?: string;
  TipoModena?: string;
  NumeroOperacionesComercial: string;
  NumeroOperacionesConsumo: string;
  NumeroOperacionesVivienda: string;
  NumeroOperacionesMicrocredito: string;
  ValorDeudaComercial: string;
  ValorDeudaConsumo: string;
  ValorDeudaVivienda: string;
  ValorDeudaMicrocredito: string;
  Total: string;
  ParticipacionTotalDeudas: string;
  CubrimientoGarantiaConsumo: string;
  CubrimientoGarantiaComercial?: string;
  CubrimientoGarantiaVivienda?: string;
  CubrimientoGarantiaMicrocredito?: string;
}

export interface Endeudamiento72Element {
  TipoMoneda: string;
  ValorContingencias: string;
  CuotaEsperada: string;
  CumplimientoCuota?: string;
}

export interface Endeudamiento73Element {
  TipoEntidad: string;
  NombreEntidad: string;
  ModalidadCredito: string;
  Calificacion: string;
  TipoMoneda: string;
  NumeroOperadores: string;
  ValorDeuda: string;
  ParticipacionTotalDeudas: string;
  CubrimientoGarantia: string;
  TipoGarantia: string;
  CuotaEsperada: string;
  CumplimientoCuota: string;
}

export interface EndeudamientoTrimII {
  Endeudamiento81: Endeudamiento71Element[];
  Endeudamiento82: Endeudamiento72Element[];
  Endeudamiento83: Endeudamiento73Element[];
}

export interface EndeudamientoTrimIII {
  Endeudamiento91: Endeudamiento71Element[];
  Endeudamiento92: Endeudamiento72Element[];
  Endeudamiento93: Endeudamiento73Element[];
}

export interface IncomeEstimator {
  IngresoEstimado: string;
  CapacidadPago: string;
  CapacidadEndeuda: string;
  CantProductosTotal: string;
  VlrInicialTotal: string;
  SaldoTotal: string;
  CuotaTotal: string;
  CantNoRotativo: string;
  VlrInicialNoRotativo: string;
  SaldoNoRotativo: string;
  CuotaNoRotativo: string;
  CantidadTdc: string;
  CupoTdc: string;
  SaldoTdc: string;
  CuotaTdc: string;
  FechaAdicion: string;
}

export interface Score {
  IndicadorScore: string;
  TipoScore: string;
  CodigoScore: string;
  Puntaje: string;
}

export interface SectorFinanciero {
  Obligacion: SectorFinancieroAlDiaObligacion[];
}

export interface SectorFinancieroAlDiaObligacion {
  PaqueteInformacion: string;
  IdentificadorLinea: string;
  TipoContrato: string;
  EstadoContrato?: string;
  TipoEntidad: string;
  NombreEntidad: string;
  Ciudad: string;
  Sucursal: string;
  NumeroObligacion: string;
  Calidad: string;
  EstadoObligacion: string;
  ModalidadCredito: string;
  LineaCredito: string;
  Periodicidad?: string;
  FechaApertura: string;
  FechaTerminacion?: string;
  ValorInicial: string;
  SaldoObligacion: string;
  ValorMora: string;
  ValorCuota: string;
  TipoMoneda?: string;
  CuotasCanceladas?: string;
  TipoGarantia?: string;
  Comportamientos: string;
  ParticipacionDeuda?: string;
  FechaCorte: string;
  EstadoTitular?: string;
  NumeroCuotasPactadas?: string;
  NumeroCuotasMora?: string;
  Reestructurado?: string;
  NumeroReestructuraciones?: string;
  MarcaTarjeta?: string;
  ClaseTarjeta?: string;
  TipoPago?: string;
  CubrimientoGarantia?: string;
  ProbabilidadNoPago?: string;
}


export interface SectorReal {
  Obligacion: SectorRealAlDiaObligacion[];
}

export interface SectorRealAlDiaObligacion {
  PaqueteInformacion: string;
  IdentificadorLinea: string;
  TipoContrato: string;
  EstadoContrato: string;
  TipoEntidad: string;
  NombreEntidad: string;
  Ciudad: string;
  Sucursal: string;
  NumeroObligacion: string;
  Calidad: string;
  EstadoObligacion: string;
  LineaCredito: string;
  Periodicidad: string;
  FechaApertura: string;
  ValorInicial: string;
  SaldoObligacion: string;
  ValorMora: string;
  ValorCuota: string;
  CuotasCanceladas: string;
  Comportamientos: string;
  FechaCorte: string;
  TipoPago?: string;
  NumeroCuotasPactadas: string;
  NumeroCuotasMora: string;
  ValorCargoFijo: string;
  ClausulaPermanencia: string;
  Reestructurado: string;
  Vigencia: string;
  NumeroMesesContrato?: string;
  NumeroMesesClausula: string;
}

