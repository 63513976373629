<div class="card--data">
  <h4>Información de la tarea</h4>
  <div class="container-card row">
    <div class="general-data container-section">
      <div class="col left">
        <div>
          <div class="item gray">
            <div class="bold">Nombre:</div>
            <div>{{ data.taskName }}</div>
          </div>
          <div class="item">
            <div class="bold">Proceso:</div>
            <div>{{ data.process }}</div>
          </div>
          <div class="item gray">
            <div class="bold">Id proceso:</div>
            <div>{{ data.id }}</div>
          </div>
          <div class="item">
            <div class="bold">Fecha de creación:</div>
            <div>{{ data.dateCreated }}</div>
          </div>
        </div>
      </div>
      <div class="col right">
        <div>
          <div class="item gray">
            <div class="bold">Tipo de documento:</div>
            <div>{{ data.docType.description }}</div>
          </div>
          <div class="item">
            <div class="bold">Número de Identificación:</div>
            <div>{{ data.docIdNumber }}</div>
          </div>
          <div class="item gray">
            <div class="bold">Fecha limite:</div>
            <div>{{ data.dateExpiration }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
