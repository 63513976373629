import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { UpdateDataTaskService } from '@wp-back-office/core/workflow-sdk';
import { DynamicPipe } from '../../pipes/dynamic-pipe.pipe';
import { UnformatPipe } from '../../pipes/unformat.pipe';
import validationsTask from '../../validations/tasks.validations';

@Component({
  selector: 'input-text-task',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
})
export class InputTextComponent implements OnInit {
  @Input() label: any;
  @Input() key = '';
  @Input() actionsTable = false;

  @Input() pipe: any;
  @Input() validation!: string;

  validationsTask: any = validationsTask;

  enabledInputs: string[] = [];

  stateDisabled = true;
  showInput = false;

  textInput: any = '';
  textControl: FormControl = new FormControl();

  constructor(
    private updateDataTaskService: UpdateDataTaskService,
    private dynamicPipe: DynamicPipe,
    private unformatPipe: UnformatPipe,
    public formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.textInput =
      this.pipe !== undefined
        ? this.dynamicPipe.transform(
            this.label,
            this.pipe.name,
            this.pipe.options
          )
        : this.label;

    const validations =
      this.validation !== undefined
        ? this.validationsTask[this.validation].validations.map(
            (val: any) => val.validation
          )
        : [];

    this.textControl = new FormControl(this.textInput, validations);

    if (this.updateDataTaskService.searchUpdateKey(this.key)) {
      this.showInput = true;
    }
  }

  focusInput() {
    if (this.pipe !== undefined)
      this.textInput = this.unformatPipe.transform(
        this.textInput,
        this.pipe.name
      );
  }

  successChange() {
    this.stateDisabled = true;

    this.updateDataTaskService.dataUpdateTask.next({
      key: this.key,
      value: this.textInput,
    });

    if (this.pipe !== undefined)
      this.textInput = this.dynamicPipe.transform(
        this.textInput,
        this.pipe.name,
        this.pipe.options
      );
  }

  changeInput() {
    this.stateDisabled = false;
  }
}
