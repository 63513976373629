import { ElementRef, Type } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { ControlConfig } from './form-config.model';

/**
 * Modelo de entrada para eventos.
 */
export interface InputModel {
  /**
   * Key.
   */
  key?: string;
  /**
   * Componente.
   */
  component: Type<any>;
  /**
   * Configuracion del dialog.
   */
  dialogConfig: MatDialogConfig<any>;
}

/**
 * Configuracion de la tabla.
 */
export interface TableConfig {
  /**
   * Configuracion de las columnas.
   */
  columns?: TableColumns[];
  /**
   * Datos de la tabla.
   */
  data?: any[];
  /**
   * Configuracion de la tabla.
   */
  options?: TableOptions;
}

/**
 * Configuracion de las columnas.
 */
export interface TableColumns {
  /**
   * Id de la columna.
   */
  columnDef: string;
  /**
   * Texto visible de la columna.
   */
  header: string;
  /**
   * Celda.
   */
  cell?: (element: any) => string | number;
  /**
   * Valida si la columna va fija al inicio.
   */
  stickyStart?: boolean;
  /**
   * Valida si la columna va fija al final.
   */
  stickyEnd?: boolean;
  /**
   * Opciones para editar la columna en caso de tener valores tipo Catalogo.
   */
  editOptions?: EditOption[];
  /**
   * Opciones para filtrar la columna en caso de tener valores tipo Catalogo.
   */
  filterOptions?: EditOption[];
  /**
   * Agregar estilos en la tabla con ngStyle.
   */
  ngStyle?: any;
  /**
   * Agregar estilos en la tabla con ngStyle.
   */
  diableHeaderNgStyle?: boolean;
  /**
   * Valida si se filtra en la columna, para deshabilitar debe estar en false, de lo contrario lo activa.
   */
  filter?: boolean;
  /**
   * Valida si la columna es editable.
   */
  isEditable?: boolean;
  /**
   * Valida si la columna es requerida.
   */
  editValidators?: EditValidator;
  /**
   * Mascaras de la columna.
   */
  mask?: Mask;
  /**
   * Mascaras de la columna.
   */
  copy?: boolean;
  /**
   * Acciones custom.
   */
  customActions?: TableButtons[];
  /**
   * Enlace de la celda.
   */
  link?: CellLink;
}

/**
 * Interface de enlace en la celda.
 */
export interface CellLink {
  /**
   * Habilitar el enlace.
   */
  enable: boolean;
  /**
   * Texto del enlace.
   */
  text?: string;
}

/**
 * Mascaras.
 */
export interface Mask {
  /**
   * Tipos de fechas.
   */
  date?: dateTypes;
  /**
   * Currency.
   */
  currency?: boolean;
  /**
   * Muestra chips en lugar de iconos en celdas con vaores boleanos.
   */
  booleanChips?: BooleanChips;
  /**
   * Color del boton ("primary" | "accent" | "warn" | undefined).
   */
  chipsColor?: ElementColor;
  /**
   * Codigo html.
   */
  html?: {
    /**
     * Clases.
     */
    classes: string;
  };
}

/**
 * Color de los chips.
 */
export interface ElementColor {
  /**
   * Color primario.
   */
  primary?: ColumnValidators;
  /**
   * Color de acento.
   */
  accent?: ColumnValidators;
  /**
   * Color de error.
   */
  warn?: ColumnValidators;
  /**
   * Custom.
   */
  custom?: string;
  /**
   * Custom class.
   */
  customClass?: string;
}

/**
 * Validacion columnas.
 */
export interface ColumnValidators {
  /**
   * Columna a validar.
   */
  columnDef: string;
  /**
   * Valor a validar con (===).
   */
  equalsTo?: any;
  /**
   * Valor a validar con (!==).
   */
  differentFrom?: any;
}

/**
 * Tipos de fechas.
 */
export type dateTypes = 'UNIXTIMESTAMP' | 'TIMEZONE';

/**
 * Tipo de validador.
 */
export interface EditValidator {
  /**
   * Es requerido.
   */
  required?: boolean;
  /**
   * Tipo de input.
   */
  type?: InputType;
  /**
   * Valor mínimo del campo tipo número.
   */
  min?: number;
  /**
   * Valor máximo del campo tipo número.
   */
  max?: number;
  /**
   * Valor máximo del campo tipo número.
   */
  controlConfig?: ControlConfig;
  /**
   * Seleccion multiple en selects.
   */
  multiple?: boolean;
  /**
   * Valida si el campo se puede ocultar, lo hace agregando el campo (canBeHidden) en la configuracion de la columna y en el row {nombre: 'Pepito', canBeHidden: true}.
   */
  canBeHidden?: boolean;
}

/**
 * Valida si se filtra en la columna, para deshabilitar debe estar en false, de lo contrario lo activa.
 */
export interface BooleanChips {
  /**
   * Texto que se muestra cuando el valor es true.
   */
  trueValue: string;
  /**
   * Texto que se muestra cuando el valor es false.
   */
  falseValue: string;
}

/**
 * Opciones para editar la columna en caso de tener valores tipo Catalogo.
 */
export interface EditOption {
  /**
   * Codigo de la opcion.
   */
  code: any;
  /**
   * Descripcion de la opcion.
   */
  description: string;
}

/**
 * Botones de la tabla.
 */
export interface TableButtons {
  /**
   * Color del boton ("primary" | "accent" | "warn" | undefined).
   */
  color: ThemePalette;
  /**
   * Icono del boton.
   */
  icon?: string;
  /**
   * ToolTip del boton.
   */
  toolTip?: string;
  /**
   * Validacion para mostrar u ocultar el boton.
   */
  ngIf?: ColumnValidators;
  /**
   * Valida si el evento es externo.
   */
  external?: boolean;
  /**
   * Accion de boton dentro de la columna.
   */
  action?: any;
}

/**
 * Botones de la tabla.
 */
export interface TableActions {
  /**
   * Botón que ejecuta el evento disable externo.
   */
  disable?: TableButtons;

  /**
   * Botón que ejecuta el evento create externo.
   */
  create?: TableButtons;

  /**
   * Botón que ejecuta el evento delete externo.
   */
  delete?: TableButtons;

  /**
   * Botón que ejecuta el evento enable externo.
   */
  enable?: TableButtons;

  /**
   * Botón que ejecuta el evento view externo.
   */
  view?: TableButtons;

  /**
   * Botón que ejecuta el evento edit externo.
   */
  edit?: TableButtons;
  /**
   * Array de botones.
   */
  customActions?: TableButtons[];
}

/**
 * Paginador de la tabla.
 */
export interface Paginator {
  /**
   * Valida si el paginador se va a desactivar.
   */
  disabled?: boolean;
  /**
   * Posicion del paginador ("top" | "bottom").
   */
  position?: PaginatorPosition;
  /**
   * Tamaño del rango de la pagina.
   */
  size?: number;
  /**
   * Tamaño del rango de la pagina en opciones.
   */
  sizeOptions?: number[];
}

/**
 * Configuracion, opciones de la tabla.
 */
export interface TableOptions {
  /**
   * Estilos de las acciones.
   */
  actionNgStyle?: any;
  /**
   * Botones de la tabla.
   */
  actions?: TableActions;
  /**
   * Clases de la tabla.
   */
  classes?: string;
  /**
   * Paginador de la tabla.
   */
  paginator?: Paginator;
  /**
   * Valida si el header va en posicion fija.
   */
  stickyHeader?: boolean;
  /**
   * Valida si las acciones van en posicion fija.
   */
  stickyActions?: boolean;
  /**
   * Opciones del filtro de la tabla.
   */
  filter?: FilterOptions;
  /**
   * Valida si las columnas se pueden editar directamente.
   */
  editableColumn?: boolean;
  /**
   * Valida si las columnas se pueden eliminar directamente.
   */
  removableColumn?: {
    /**
     * Bandera de eliminacion externa.
     */
    external?: boolean;
    /**
     * Habilita un dialog para confirmar la elimmina del row.
     */
    confirmation?: boolean;
    /**
     * Texto personalizado.
     */
    confirmationText?: string;
  };
  /**
   * Texto mostrado cuando no hay datos en la tabla.
   */
  noDataText?: string;
  /**
   * Valida si se oculta el texto cuando no hay datos en la tabla.
   */
  noDataHidden?: boolean;
  /**
   * Tamaños de la tabla.
   */
  sizes?: TableSizes;
  /**
   * Seleccion en la tabla.
   */
  selection?: boolean;
  /**
   * Unique.
   */
  uniqueColumnDef?: string;
}

/**
 * Opciones del filtro de la tabla.
 */
export interface FilterOptions {
  /**
   * Filtro por columnas.
   */
  filterByColumn?: boolean;
  /**
   * Filtro por columnas con un select.
   */
  filterByColumnSelect?: boolean;
  /**
   * Filtro personalizado externo, dehabilita el filtro nativo de la tabla.
   */
  internalFilter?: boolean;
}

/**
 * Tamaños de la tabla.
 */
export interface TableSizes {
  /**
   * Estilos de la tabla.
   */
  ngStyle?: any;
  /**
   * Habilita el scroll vertical sin importar la cantidad de datos por pagina para el infinite scroll.
   */
  alwaysScrollVertical?: boolean;
}

/**
 * Referencia a la tabla.
 */
export interface ElementTable {
  /**
   * ElementRef.
   */
  _elementRef: ElementRef;
}

/**
 * Modelo para emitir un evento.
 */
export interface EventEmit {
  /**
   * Evento del mouse.
   */
  event: MouseEvent;
  /**
   * Datos a emitir.
   */
  data: any;
}

/**
 * Retorno de la emision de la tabla.
 */
export interface FilterTableEmit {
  /**
   * Valor del input.
   */
  filterValue: string;
  /**
   * Columna seleccionada.
   */
  selectedColumn: string | null;
  /**
   * KeyCode de la columna.
   */
  keyCode: string;
}

/**
 * Modelo del resultado de un evento del boton.
 */
export interface TableButtonAction<T = any> {
  /**
   * Row del boton.
   */
  row: T;
  /**
   * Valida si el valor fue editado.
   */
  edited?: boolean;
  /**
   * Index.
   */
  index?: number;
  /**
   * Nueva data creada.
   */
  newData?: any;
  /**
   * Nueva data creada.
   */
  columnDef?: any;
  /**
   * Acción del botón.
   */
  action?: any;
}

/**
 * Margenes de la tabla.
 */
export interface TableMargins {
  /**
   * Margen interior de la tabla.
   */
  'margin-bottom.px': number;
}
/**
 * Posicion del paginador.
 */
export type PaginatorPosition = 'top' | 'bottom';

/**
 * Tipos de datos para la validacion typeOf.
 */
export type TypeOf =
  | 'string'
  | 'number'
  | 'bigint'
  | 'boolean'
  | 'symbol'
  | 'undefined'
  | 'object'
  | 'array'
  | 'fileArray'
  | 'date'
  | 'null'
  | 'function';

/**
 * Tipos de inputs.
 */
export type InputType =
  | 'email'
  | 'text'
  | 'number'
  | 'float'
  | 'password'
  | 'currency'
  | 'autocomplete';

/**
 * Configuracion de la tabla.
 */
export interface MatTableConfig {
  /**
   * Id de la columna.
   */
  columnDef: string;
  /**
   * Texto visible de la columna.
   */
  header: string;

  /**
   * Celda.
   */
  cell?: (element: any) => string;
}

/**
 * Seleccion de la tabla.
 */
export interface TableSelection<T = any> {
  /**
   * Seleccionados.
   */
  selected: T[];
  /**
   * Deseleccionados.
   */
  deselected: T[];
}

/**
 * Opciones para agregar elementos a la tabla.
 */
export interface AddOptions {
  /**
   * Hacer scroll al final.
   */
  scrollDown?: boolean;
  /**
   * Hacer scroll al final.
   */
  keppPosition?: boolean;
}
