import { Component, Input, OnInit } from '@angular/core';
import { UpdateDataTaskService } from '@wp-back-office/core/workflow-sdk';
import { CatalogueTasksService } from '@wp-back-office/core/commons-backoffice';
import { takeUntil, Subject } from 'rxjs';

@Component({
  selector: 'app-editable-input-task',
  templateUrl: './editable-input-task.component.html',
  styleUrls: ['./editable-input-task.component.scss'],
})
export class EditableInputTaskComponent implements OnInit {
  @Input() label: any;
  @Input() key = '';
  @Input() catalogue: any;

  @Input() actionsTable = false;

  enabledInputs: string[] = [];

  editableInput = false;
  stateDisabled = true;
  showInput = false;

  textInput: any = '';
  listCatalogue: any[] = [];
  selectItemDropdown = '';

  /**
   * Destructor sujeto
   */
  private destroy$ = new Subject();

  constructor(
    private updateDataTaskSerrvice: UpdateDataTaskService,
    private catalogueService: CatalogueTasksService
  ) {
    this.enabledInputs = this.updateDataTaskSerrvice.updateInputs;
  }

  ngOnInit(): void {
    this.textInput = this.label || this.label.description;
    this.enabledInputs.forEach((input: string) => {
      if (input === this.key) {
        this.showInput = true;
        this.editableInput = true;
      }
    });

    if (this.catalogue !== undefined) {
      this.listCatalogue = [this.label];
      this.selectItemDropdown = this.label.code;
    }
  }

  /**
   * A callback method that performs custom clean-up, invoked immediately before a directive, pipe, or service instance is destroyed.
   */
  public ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  successInput() {
    this.stateDisabled = true;

    this.updateDataTaskSerrvice.dataUpdateTask.next({
      key: this.key,
      value: this.textInput,
    });
  }

  changeInput() {
    this.stateDisabled = false;

    if (this.catalogue !== undefined) {
      this.catalogueService
        .getCatalogueTasks(
          this.catalogue.entity,
          this.catalogue.entityId,
          this.catalogue.parent
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe((ev: any[]) => {
          this.listCatalogue = [this.label, ...ev];
        });
    }
  }

  selectDropdown() {
    this.textInput = this.listCatalogue.find(
      (ev: any) => ev.code === this.selectItemDropdown
    );
  }
}
