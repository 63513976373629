import {
  Component,
  Input,
  ElementRef,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { catchError, takeUntil } from 'rxjs/operators';
import { Location } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';

import { WorkflowService } from './../../services/workflow.service';
import { Subject } from 'rxjs';

/**
 * AGREGUE DOCUMENTACION.
 */
@Component({
  selector: 'wp-back-office-actions-human-task',
  templateUrl: './actions-human-task.component.html',
})
export class ActionsHumanTaskComponent implements OnDestroy {
  /**
   * Destructor sujeto.
   */
  private destroy$ = new Subject();
  /**
   * AGREGUE DOCUMENTACION.
   */
  @Input() public id: any;
  /**
   * AGREGUE DOCUMENTACION.
   */
  @Input() public outputTask: any;
  /**
   * AGREGUE DOCUMENTACION.
   */
  @ViewChild('mymodal') public modal!: ElementRef;
  /**
   * AGREGUE DOCUMENTACION.
   */
  public messageModal = '';
  /**
   * AGREGUE DOCUMENTACION.
   */
  public loadingComplete = false;
  /**
   * AGREGUE DOCUMENTACION.
   */
  public loadingCancel = false;
  /**
   * AGREGUE DOCUMENTACION.
   */
  public loadingSave = false;
  /**
   * Entrada.
   * @returns Boolean.
   */
  @Input()
  public validationTask = () => true;

  /**
   * Crea una instancia de ActionsHumanTaskComponent.
   * @param humantaskService - Servico de tareas humanas.
   * @param _location - Ubicacion.
   * @param modalService - Servicio modal.
   * @param router - Router.
   */
  constructor(
    private humantaskService: WorkflowService,
    private _location: Location,
    private modalService: NgbModal,
    private router: Router,
  ) {}

  /**
   * A callback method that performs custom clean-up, invoked immediately before a directive, pipe, or service instance is destroyed.
   */
  public ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  /**
   * Completar una tarea.
   * @returns Boolean.
   */
  public completeTask() {
    if (this.validationTask !== undefined) {
      if (this.validationTask() === false) return false;
    }

    this.loadingComplete = true;

    this.humantaskService
      .successTask(this.outputTask)
      .pipe(
        catchError(err => {
          this.loadingComplete = false;
          this.messageModal = 'Error!!';

          this.modalService.open(this.modal);
          return err;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(ev => {
        const { nextTask, idExecution, taskToken } = ev;
        const { process, state } = nextTask;
        const { date, name } = state;
        this.router.navigate(
          [
            'dashboard',
            'origination',
            'process',
            process,
            'task',
            name,
            idExecution,
            date,
            taskToken,
          ],
          {
            queryParams: {
              process,
            },
          }
        );
      });
    return true;
  }

  /**
   * Navegar Atrás.
   */
  public closeTask() {
    this._location.back();
  }

  /**
   * Completar tarea.
   */
  public successTask() {
    this.modalService.dismissAll();
    this._location.back();
  }
}
