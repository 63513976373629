import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Subject, switchMap, take, takeUntil, tap, of } from 'rxjs';
import {
  ControlConfig,
  DynamicFormComponent,
  DynamicDialogMessageService,
  SelectResponse,
} from '@wp-back-office/shared/dynamic-components';
import {
  filterNonNullable,
  repeatUntil,
} from '@wp-back-office/core/workflow-sdk';
import { PrivateDynamicStepperService } from '../../services/private-dynamic-stepper.service';
import { selecTaskSchema } from '../../store/selectors/contents/task.selectors';
import { Form, FormTypePJ } from '../../models/form-task.models';

/**
 * Componente dinámico para la administración del paso a paso de los formularios.
 */
@Component({
  selector: 'wp-back-office-dynamic-stepper',
  templateUrl: 'dynamic-stepper.component.html',
})
export class DynamicStepperComponent implements AfterViewInit, OnDestroy {
  /**
   * Formulario dinámico.
   */
  @ViewChild('dynamicFormID', {
    static: false,
    read: DynamicFormComponent,
  })
  public dynamicForm!: DynamicFormComponent;

  /**
   * Formulario a obtener.
   */
  @Input()
  public formularyType?: FormTypePJ;

  /**
   * Indice del paso actual.
   */
  @Input()
  public stepIndex!: number;

  /**
   * Cantidad de pasos en el Stepper.
   */
  @Input()
  public stepsLength!: number;

  /**
   * Activa los botones.
   */
  @Input()
  public viewButtons: boolean;

  /**
   * Activa los botones.
   */
  @Input()
  public readonly: boolean;

  /**
   * Evento de siguiente paso.
   */
  @Output()
  public nextStep: EventEmitter<void>;

  /**
   * Evento de paso anterior.
   */
  @Output()
  public backStep: EventEmitter<void>;

  /**
   * Evento a ejecutar al completar el formulario.
   */
  @Output()
  public loadedFormulary: EventEmitter<void>;

  /**
   * Evento a ejecutar al completar la carga del store con el formulario.
   */
  @Output()
  public changeDynamicForm: EventEmitter<FormGroup>;

  /**
   * Evento de formulario valido.
   */
  @Output()
  public infiniteScroll: EventEmitter<SelectResponse> =
    new EventEmitter<SelectResponse>();

  /**
   * Payload del formulario.
   */
  public dynamicFormConfiguration: ControlConfig[];

  /**
   * Destructor sujeto.
   */
  private destroy$ = new Subject();

  /**
   * Constructor.
   * @param storeProcess - NgRx store.
   * @param dynamicStepperService - DynamicStepperService.
   * @param cdRef - ChangeDetectorRef.
   * @param dynamicDialogMessageService - DynamicDialogMessageService.
   */
  constructor(
    private storeProcess: Store,
    public dynamicStepperService: PrivateDynamicStepperService,
    public cdRef: ChangeDetectorRef,
    public dynamicDialogMessageService: DynamicDialogMessageService
  ) {
    this.viewButtons = true;
    this.readonly = false;
    this.nextStep = new EventEmitter();
    this.backStep = new EventEmitter();
    this.loadedFormulary = new EventEmitter();
    this.changeDynamicForm = new EventEmitter<FormGroup>();
    this.dynamicFormConfiguration = [];
  }

  /**
   * Ciclo de vida AfterViewInit.
   */
  public ngAfterViewInit(): void {
    if (this.formularyType !== undefined) {
      this.storeProcess
        .pipe(
          select(selecTaskSchema),
          takeUntil(this.destroy$),
          filterNonNullable(),
          tap(formInformation => this.getFormInformation(formInformation)),
          take(1),
          switchMap(() => {
            return repeatUntil(
              () =>
                Object.keys(this.dynamicForm.formGroupGeneric.controls).length >
                0,
              () => of(undefined),
              {
                attempts: 100,
                initial_time: 100,
                time: 100,
              }
            );
          })
        )
        .subscribe(() => {
          this.loadedFormulary.emit();
          this.dynamicDialogMessageService.closeLoader();
        });
    }
  }

  /**
   * Ciclo de vida OnDestroy.
   */
  public ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  /**
   * Obtiene el formulario de información jurídica.
   * @param forms - Formularios a renderizar.
   */
  private getFormInformation(forms: Form[]): void {
    this.dynamicFormConfiguration = this.getDynamicFormConfiguration(
      forms,
      this.readonly
    );
    this.cdRef.detectChanges();
  }

  /**
   * Obtiene un formulario y su configuracion.
   * @param fomSchemasData - Formularios.
   * @param isReadonly - Si es de solo lectura.
   * @returns Configuración del formulario dinámico.
   */
  private getDynamicFormConfiguration(
    fomSchemasData: Form[],
    isReadonly = false
  ): ControlConfig[] {
    const formSchema = fomSchemasData.find(
      formSchema => this.formularyType === formSchema.type
    );
    if (isReadonly) {
      return formSchema
        ? [
            ...formSchema.schema.map(item => ({
              ...item,
              validators: {
                ...item.validators,
                readonly: true,
              },
            })),
          ]
        : [];
    } else {
      return formSchema ? [...formSchema.schema] : [];
    }
  }

  /**
   * Emite el evento de cambio para el siguiente mat stepper.
   */
  public onNextStep(): void {
    this.nextStep.emit();
  }

  /**
   * Emite el evento de cambio para el anterior mat stepper.
   */
  public onBackStep(): void {
    this.backStep.emit();
  }
  /**
   * Asigna un valor inicial a un campo en el formulario.
   * @returns Valor del formulario.
   */
  public get formValue() {
    return { ...this.dynamicForm.formGroupGeneric.getRawValue() };
  }

  /**
   * Asigna un valor inicial a un campo en el formulario.
   */
  public set patchValue(
    formValue:
      | Record<
          string,
          string | number | Record<string, string | number> | undefined
        >
      | any
  ) {
    this.dynamicForm.formGroupGeneric.patchValue(formValue);
  }

  /**
   * Emite el evento de cambio para el formulario dinámico.
   * @param dynamicForm - Formulario dinámico.
   */
  public onChangeDynamicForm(dynamicForm: FormGroup): void {
    this.changeDynamicForm.emit(dynamicForm);
  }
  /**
   * Valida si el formulario es valido.
   * @returns - Boolean.
   */
  public get valid(): boolean {
    return !!this.dynamicForm?.formGroupGeneric?.valid;
  }

  /**
   * Obtiene el observable de cambios por parametro.
   * @param key - Valor del parametro.
   * @returns - Observable.
   */
  public valueChangeByKey(key: string) {
    return repeatUntil(
      () => this.dynamicForm !== undefined,
      () => this.dynamicForm.formGroupGeneric?.controls[key].valueChanges,
      {
        attempts: 1000,
        initial_time: 100,
        time: 100,
      }
    );
  }
  /**
   * Cambia a solo lectura el parametro.
   * @param key - Valor del parametro.
   */
  public readonlyByKey(key: string) {
    const index = this.getIndexByKey(key);
    if (index >= 0) {
      const control = this.dynamicForm.controlsInput[index];
      this.dynamicForm.updateControlByIndex(
        {
          ...control,
          validators: control.validators
            ? {
                ...control.validators,
                readonly: true,
              }
            : undefined,
        },
        index
      );
    }
  }

  /**
   * Habilita o deshabilita un campo.
   * @param key - Valor del parametro.
   * @param disabled - Valor del parametro.
   */
  public enableOrDisabledByKey(key: string, disabled: boolean) {
    const index = this.getIndexByKey(key);
    if (index >= 0) {
      const control = this.dynamicForm.controlsInput[index];
      this.dynamicForm.updateControlByIndex(
        {
          ...control,
          validators: control.validators
            ? {
                ...control.validators,
                disabled,
              }
            : undefined,
        },
        index
      );
    }
  }

  /**
   * Cambia a solo lectura el parametro.
   * @param key - Valor del parametro.
   * @param required - Indica si es requerido.
   */
  public requiredByKey(key: string, required: boolean) {
    const index = this.getIndexByKey(key);
    if (index >= 0) {
      const control = this.dynamicForm.controlsInput[index];
      this.dynamicForm.updateControlByIndex(
        {
          ...control,
          validators: control.validators
            ? {
                ...control.validators,
                required,
              }
            : undefined,
        },
        index
      );
    }
  }

  /**
   * Habilita o deshabilita un campo.
   * @param label - Valor del parametro.
   * @param replaceLabel - Valor del parametro.
   */
  public replaceSectionNameByLabel(label: string, replaceLabel: string) {
    const index = this.dynamicForm.controlsInput.findIndex(
      control => control.label === label && control.type === 'Section'
    );
    if (index >= 0) {
      const control = this.dynamicForm.controlsInput[index];
      this.dynamicForm.updateControlByIndex(
        {
          ...control,
          label: replaceLabel,
        },
        index
      );
    }
  }

  /**
   * Emite el cambio en el scroll del form.
   * @param scrollChanges - Cambios del scroll.
   */
  public onInfiniteScroll(scrollChanges: SelectResponse) {
    this.infiniteScroll.emit(scrollChanges);
  }

  /**
   * Obtiene el indice de un campo por key.
   * @param key - Key del campo.
   * @returns - Indice del campo.
   */
  public getIndexByKey(key: string) {
    return this.dynamicForm.controlsInput.findIndex(
      control => control.key === key
    );
  }
}
