/**
 * Modelo catalogo.
 */
export interface Catalogue {
  /**
   * Identificador.
   */
  code: string;
  /**
   * Valor.
   */
  description: string;
}
