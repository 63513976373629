<wp-back-office-dynamic-header-table
  [title]="title"
  [subtitle]="subtitle"
  [isCreate]="isCreate"
  *ngIf="!addButtonPosition || addButtonPosition === 'top'"
  (create)="onAdd()">
</wp-back-office-dynamic-header-table>
<wp-back-office-dynamic-table
  [tableConfig]="appTableConfig"
  [inputView]="inputView"
  [inputEdit]="inputEdit"
  (dataChanged)="dataChanged.emit($event)"
  (edit)="onEdit($event)">
</wp-back-office-dynamic-table>

<div
  class="d-flex justify-content-center"
  *ngIf="addButtonPosition === 'bottom'">
  <wp-back-office-dynamic-button
    [buttonSelector]="'mat-flat-button'"
    (ButtonClick)="onAdd()"
    class="mx-2 table-button-bellow"
    [text]="addButtonTitle || 'Agregar'"
    [iconStart]="'add'"
    [color]="'accent'">
  </wp-back-office-dynamic-button>
</div>
