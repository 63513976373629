/**
 * Modelo de informacion de ingreso.
 */
export interface SignInInformation {
  /**
   * Nombre de usuario.
   */
  username?: string;
  /**
   * Token.
   */
  tokenCaptcha?: string;
  /**
   * Contraseña.
   */
  password?: string;
  /**
   * Correo electronico.
   */
  email?: string;
  /**
   * Tenant identificador.
   */
  tenantId?: string;
}
