<div *ngIf="showInput">
  <mat-form-field *ngIf="label" class="input-tasks-default"
    [ngClass]="{'table-actions': actionsTable}">
    <input matInput [(ngModel)]="textInput" [disabled]="stateDisabled" (focus)="focusInput()" (focusout)="successChange()">
    <div class="actions">
      <mat-icon (click)="changeInput()" aria-hidden="false" aria-label="Example home icon">edit</mat-icon>
    </div>
  </mat-form-field>
</div>

<div *ngIf="!showInput">
  <div>
    {{textInput}}
  </div>
</div>