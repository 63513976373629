import { ThemePalette } from '@angular/material/core';
import { Catalogue } from '@wp-back-office/core/commons-backoffice';
import { KeyOptions } from '@wp-back-office/core/store';
import { TableColumns, TableOptions } from '../../dynamic-form';
import { TabCreateList } from '../../dynamic-tabs';

/**
 * Configuracion del control.
 */
export interface ControlConfig {
  /**
   * Nombre del label.
   */
  topLabel?: string;
  /**
   * Nombre del label.
   */
  label?: string;
  /**
   * Labels tipo chip.
   */
  chipLabels?: string[];
  /**
   * Valor por defecto del campo.
   */
  value?: any;
  /**
   * Opciones del control = {code: string , description: string}.
   */
  options?: ControlOption[];
  /**
   * Valida si no hay mas opciones para cargar.
   */
  disableInfiniteScroll?: boolean;
  /**
   * Opciones del control (Dropdown) = {code: string , description: string}.
   */
  categories?: Category[];
  /**
   * Lave o identificador del control.
   */
  key?: string;
  /**
   * Tipo de control.
   */
  type?: controType;
  /**
   * Tamaño en columnas.
   */
  col?: SizeConfigTarget;
  /**
   * Icono para la entrada esta en la documentacion de angular material.
   */
  icon?: string;
  /**
   * Mensajes hint para controles.
   */
  hint?: string;
  /**
   * Clases del hint para controles.
   */
  hintClass?: string;
  /**
   * Ocultar el control.
   */
  hidden?: boolean;
  /**
   * Tooltip del boton.
   */
  tooltip?: string;
  /**
   * Tooltip del boton.
   */
  placeholder?: string;
  /**
   * Validaciones de control checkbox.
   */
  checkBoxOptions?: CkeckBoxConfig;
  /**
   * Validaciones del control.
   */
  validators?: ValidatorsConfig;
  /**
   * Accion del input.
   */
  action?: ButtonAction;
  /**
   * Accion del input.
   */
  fileOptions?: FileOptions;
  /**
   * Opciones del campo GeographicLocation...
   */
  geographicLocationOptions?: GeographicLocationOptions;
  /**
   * Opciones del campo para catalogos...
   */
  keyOptions?: KeyOptions;
  /**
   * Opciones code verification.
   */
  codeVerificationConfig?: CodeVerificationConfig;
  /**
   * Opciones code verification.
   */
  datePickerConfig?: DatePickerConfig;
  /**
   * Clases css.
   */
  classes?: string;
  /**
   * Apariencia del input.
   */
  appearance?: 'fill' | 'outline';
  /**
   * Opciones del autocomplete.
   */
  autocompleteConfig?: AutocompleteConfig;
  /**
   * Controles del campo de dependencias...
   */
  dependencesControls?: DependenciesControls[];
  /**
   * Opciones del campo de tabla.
   */
  tableControlConfig?: TableControlConfig;
  /**
   * Activar Autollenado cuando haya solo una opcion.
   */
  uniqueOptionAutoFillDeactivated?: boolean;

  /**
   * Configuracion del control tipo Radio.
   */
  radioConfig?: RadioConfig;

  /**
   * Bandera de carga.
   */
  loading?: boolean;

  /**
   * Valor del progress bar del 0 al 100.
   */
  progressValue?: number;
}

/**
 * Configuracion del control tipo Radio.
 */
export interface RadioConfig {
  /**
   * Valida si es horizontal.
   */
  horizontal?: boolean;
  /**
   * Layout de botones.
   */
  buttonLayout?: boolean;
}
/**
 * Controles de dependencias.
 */
export interface DependenciesControls {
  /**
   * Clave del control.
   */
  key: string;
  /**
   * Nombre del label.
   */
  label: string;
  /**
   * Tipo del control.
   */
  type: DependenciesType;
  /**
   * Entidad a consultar.
   */
  entity: string;
  /**
   * Depende de.
   */
  dependsOn?: string;
  /**
   * Nombre del label.
   */
  topLabel?: string;
  /**
   * Validaciones del control.
   */
  validators?: ValidatorsConfig;
  /**
   * Icono para la entrada esta en la documentacion de angular material.
   */
  icon?: string;
  /**
   * Mensajes hint para controles.
   */
  hint?: string;
  /**
   * Tooltip del boton.
   */
  tooltip?: string;
  /**
   * Tamaño en columnas.
   */
  col?: SizeConfigTarget;
  /**
   * Opciones del control = {code: string , description: string}.
   */
  options?: ControlOption[];
}

/**
 * Opciones del control.
 */
export interface ControlOption extends TabCreateList {
  /**
   * Labels tipo chip.
   */
  chipLabels?: string[];
  /**
   * Si es booleana.
   */
  bool?: boolean;
}

/**
 * Tipo del control.
 */
export type DependenciesType = 'DropDown' | 'AutoComplete';

/**
 * Opciones del autocomplete.
 */
export interface AutocompleteConfig {
  /**
   * Filtro interno.
   */
  internalFilter?: boolean;
}

/**
 * Configuracion del date picker.
 */
export interface DatePickerConfig {
  /**
   * Fecha minima.
   */
  minDate?: Date;
  /**
   * Fecha maxima.
   */
  maxDate?: Date;
  /**
   * Indica si el campo fecha requiere edad legal.
   */
  legalAgeIsRequired?: boolean;
  /**
   * Indica si la fecha máxima es la fecha actual.
   */
  dateMaxNow?: boolean;
  /**
   * Indica si la fecha minima es la fecha actual.
   */
  dateMinNow?: boolean;
}

/**
 * Opciones del campo GeographicLocation...
 */
export interface GeographicLocationOptions {
  /**
   * Label.
   */
  labelCountry?: string;
  /**
   * Label.
   */
  labelState?: string;
  /**
   * Label.
   */
  labelCity?: string;
  /**
   * Mostrar el campo Country.
   */
  hideCountry?: boolean;
  /**
   * Mostrar el campo Country.
   */
  hideStateAndCity?: boolean;
  /**
   * ReadOnly.
   */
  readOnlyCountry?: boolean;
  /**
   * ReadOnly.
   */
  readOnlyState?: boolean;
  /**
   * ReadOnly.
   */
  readOnlyCity?: boolean;
  /**
   * ReadOnly.
   */
  bornDate?: boolean;
}

/**
 * Opcioones del boton file.
 */
export interface FileOptions {
  /**
   * Mostrar una previsualizacion de la imagen.
   */
  showImagePreview?: boolean;
  /**
   * Permitir cortar la imagen.
   */
  cropper?: boolean;
  /**
   * Nombre por defecto del archivo.
   */
  fileDefaultName?: string;
  /**
   * Opciones del control file tipo drag and drop.
   */
  dragAndDrop?: DragFileConfig;
  /**
   * Barra de progreso.
   */
  progressBar?: boolean;
  /**
   * Url de imagen por defecto.
   */
  defaultImage?: string;
}

/**
 * Opciones del control file tipo drag and drop.
 */
export interface DragFileConfig {
  /**
   * Modo del progress bar.
   */
  mode?: 'determinate' | 'indeterminate';
}

/**
 * Acciones del boton del campo.
 */
export interface ButtonAction {
  /**
   * Icono del boton.
   */
  icon: string;
  /**
   * Valor que retorna el boton.
   */
  returnValue: any;
  /**
   * Color del boton.
   */
  color: ThemePalette;
  /**
   * Tooltip del boton.
   */
  tooltip?: string;
}

/**
 * Acciones del boton del campo.
 */
export interface ButtonFormConfig {
  /**
   * Icono del boton.
   */
  label?: string;
  /**
   * Color del boton.
   */
  color?: ThemePalette;
  /**
   * Tooltip del boton.
   */
  tooltip?: string;
}

/**
 * Categorias de las opciones del campo de tipo ButtonToggleGroup.
 */
export interface Category {
  /**
   * Nombre de la categoria.
   */
  name: string;
  /**
   * Opciones de la categoria.
   */
  options: Option[];
}

/**
 * Opciones del select.
 */
export interface Option {
  /**
   * Codigo.
   */
  code?: string;
  /**
   * Descripcion.
   */
  description?: string;
  /**
   * Valida si un valor de una lista multiple está seleccionado.
   */
  checked?: boolean;
}

/**
 * Resoluciones de pantalla por columnas.
 */
export interface SizeConfigTarget {
  /**
   * Resolucion xs.
   */
  xs?: number;
  /**
   * Resolucion sm.
   */
  sm?: number;
  /**
   * Resolucion md.
   */
  md?: number;
  /**
   * Resolucion lg.
   */
  lg?: number;
  /**
   * Resolucion xl.
   */
  xl?: number;
  /**
   * Resolucion xl.
   */
  xxl?: number;
}

/**
 * Configuracion de validaciones.
 */
export interface ValidatorsConfig {
  /**
   * Min.
   */
  min?: number;
  /**
   * Max.
   */
  max?: number;
  /**
   * Bandera para correo electronico.
   */
  email?: boolean;
  /**
   * MaxLength.
   */
  minLength?: number;
  /**
   * MinLength.
   */
  maxLength?: number;
  /**
   * Extension del archivo a validar.
   */
  accept?: string;
  /**
   * Campo requerido true | false.
   */
  required?: boolean;
  /**
   * Mascara de validacion campo.
   */
  pattern?: string | RegExp;
  /**
   * Campo deshabilitado true | false.
   */
  disabled?: boolean;
  /**
   * Campo de lectura true | false.
   */
  readonly?: boolean;
  /**
   * Carga de multiples archivos true | false.
   */
  multiple?: boolean;
  /**
   * Bandera de carga.
   */
  loading?: boolean;
  /**
   * Mensaje de error.
   */
  customErrorMessage?: string;
  /**
   * Mensaje de error.
   */
  disablePaste?: boolean;
  /**
   * Valida si es un input de tipo chipList.
   */
  chipList?: boolean;
  /**
   * Transformacion del texto.
   */
  textTransform?: TextTransform;
  /**
   * DropDownUndefinedOption.
   */
  dropDownUndefinedOption?: boolean;
  /**
   * Valida si la opcion seleccionada esta en la lista de opciones del dropdown o autocomplete.
   */
  validListOption?: boolean;
  /**
   * Validacion para mostrar u ocultar el campo.
   */
  ngIf?: {
    /**
     * Columna a validar.
     */
    keyValue: string;
    /**
     * Valor a validar con (===).
     */
    equalsTo?: any;
    /**
     * Valor a validar con (!==).
     */
    differentFrom?: any;
  };
  /**
   * Valida si dos campos coinciden.
   */
  match?: string;
  /**
   * Precision en el caso de valores financieros.
   */
  precision?: number;
  /**
   * Filtro de opciones para los catálogos.
   */
  filterCatalogOptions?: {
    /**
     * Parámetro a validar.
     */
    keyValue: string;
    /**
     * Valor a validar con (===).
     */
    equalsTo?: boolean | string | number;
  };
  /**
   * Conserva el ordenamiento por defecto de la respuesta del catálogo.
   */
  defaultOrder?: 'code' | 'description' | 'entityCode' | 'id';
}

/**
 * Configuracion de validaciones.
 */
export interface CkeckBoxConfig {
  /**
   * Link.
   */
  link?: string;
  /**
   * Link de texto.
   */
  linkText?: string;
  /**
   * Tipo de target url.
   */
  linkTarget?: LinkTarget;
  /**
   * Terminos y condiciones true | false.
   */
  onlyAcceptTrue?: boolean;
  /**
   * ReadOnly.
   */
  readOnly?: boolean;
  /**
   * Validador.
   */
  validator?: ValidatorsConfig;
}

/**
 * Configuracion de codigo verificacion.
 */
export interface CodeVerificationConfig {
  /**
   * Link.
   */
  text?: string;
  /**
   * Codigo de verificacion.
   */
  codeVerificationQuantity?: number;
  /**
   * Contador en segundos.
   */
  countDown?: number;
  /**
   * Limite de reintentos.
   */
  retryLimit?: number;
}

/**
 * Interfaz del control de direcciones.
 */
export interface AddressValue {
  /**
   * Tipo de direccion.
   */
  type: Catalogue;
  /**
   * Via.
   */
  via: Catalogue;
  /**
   * Numero.
   */
  num: string;
  /**
   * Referencia.
   */
  ref: string;
  /**
   * Sector.
   */
  sector: Catalogue;
  /**
   * Cruce.
   */
  crossing: string;
  /**
   * Referencia.
   */
  ref1: string;
  /**
   * Sector.
   */
  sector1: Catalogue;
  /**
   * Distancia.
   */
  dist: string;
  /**
   * Complemento.
   */
  complement: string;
  /**
   * Nomenclatura.
   */
  nomenclature: string;
}

/**
 * Interfaz del contador regresivo.
 */
export interface TimeOut {
  /**
   * Llave del campo.
   */
  key: any;
}

/**
 * Configuracion de codigo verificacion.
 */
export interface TableControlConfig {
  /**
   * Tipo de componente.
   */
  title: string;

  /**
   * Tipo de componente.
   */
  columns: TableColumns[];

  /**
   * Opciones de la tabla.
   */
  options?: TableOptions | undefined;

  /**
   * Tipo de componente.
   */
  controls: ControlConfig[];

  /**
   * Tipo de componente.
   */
  subtitle: string;

  /**
   * Tipo de componente.
   */
  formTitle: string;

  /**
   * Boton de crear o no.
   */
  isCreate: boolean;

  /**
   * Posicion de boton de agregar.
   */
  addButtonPosition?: 'top' | 'bottom' | 'hidden';

  /**
   * Titulo del boton de agregar.
   */
  addButtonTitle?: string;
}

/**
 * Tipos para los apuntadores url.
 */
export type LinkTarget = '_blank' | '_self' | '_parent' | '_top';

/**
 * Transformacion de texto.
 */
export type TextTransform = 'uppercase' | 'lowercase' | 'number' | 'text' | 'alphanumeric';
/**
 * Tipos de controles.
 */
export type controType =
  | 'Section'
  | 'Divider'
  | 'DropDown'
  | 'DropDownFilter'
  | 'AutoComplete'
  | 'Text'
  | 'TextArea'
  | 'Number'
  | 'Email'
  | 'DatePicker'
  | 'DateTime'
  | 'Currency'
  | 'Password'
  | 'GeographicLocation'
  | 'Dependent'
  | 'AddressLocation'
  | 'CodeVerification'
  | 'CheckBox'
  | 'File'
  | 'ButtonToggleGroup'
  | 'Radio'
  | 'TableControl';

/**
 * Justificacion de los campos.
 */
export type justify =
  | 'justify-content-start'
  | 'justify-content-end'
  | 'justify-content-center'
  | 'justify-content-between'
  | 'justify-content-around';

/**
 * Configuracion de formularios.
 */
export interface DialogFormConfig {
  /**
   * Controles del formulario.
   */
  controls: ControlConfig[];
  /**
   * Titulo del formulario.
   */
  title: string;
  /**
   * Valor de la formulario.
   */
  value?: any;
  /**
   * Valor de la formulario.
   */
  row?: any;
  /**
   * Cerrar el modal.
   */
  onlyClose?: boolean;
}
