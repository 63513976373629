import { Catalogue } from '@wp-back-office/core/store';
import { ComponentType } from '@angular/cdk/portal';

/**
 * Configuracion de tabs.
 */
export interface MatTabGroupConfig {
  /**
   * Tabulaciones.
   */
  matTabs?: MatTabConfig[];
  /**
   * Index seleccionado.
   */
  selectIndex?: number;
  /**
   * No repetir los taps.
   */
  noRepeatLabel?: NoRepeat;
  /**
   * Entrada custom de adicion de tabs.
   */
  modalAdd?: ModalAdd;
}

/**
 * Modelo de configuracion de un tab .
 */
export interface ModalAdd {
  /**
   * Nombre del tab.
   */
  title?: string;
  /**
   * Componente de entrada.
   */
  catalogue?: any[];
  /**
   * Componente de entrada.
   */
  catalogueList?: CatalogueList[];
  /**
   * Key del campo que contiene el componente a seleccionar.
   */
  componentKey?: string;
  /**
   * Key del campo que contiene el tipo de tab a crear.
   */
  tabKey?: string;
}

/**
 * Catalogo.
 */
export interface CatalogueList {
  /**
   * Nombre del campo.
   */
  label?: string;
  /**
   * Nombre del campo.
   */
  key?: string;
  /**
   * Componente de entrada.
   */
  catalogue?: TabCreateList[];
}

/**
 * Lista de tabs.
 */
export interface TabCreateList extends Catalogue {
  /**
   * Componente.
   */
  component?: ComponentType<any>;
}

/**
 * Modelo de configuracion de un tab.
 */
export interface MatTabConfig {
  /**
   * Nombre del tab.
   */
  name: string;
  /**
   * Bandera de creacion inicial.
   */
  createdInitial?: boolean;
  /**
   * Bandera de creacion del tab.
   */
  canBeCreated?: boolean;
  /**
   * Bandera de creacion multiple.
   */
  isMultiple?: boolean;
  /**
   *Identificador entrada valido para formularios.
   */
  typeID?: Catalogue;
  /**
   * Data del componente.
   */
  tabData?: any;
  /**
   * Componente de entrada.
   */
  typeIDList?: CatalogueList[];
  /**
   * Componente de entrada.
   */
  component?: ComponentType<any>;
  /**
   * Configuracion de boton.
   */
  buttons?: ButtonConfig[];
}

/**
 * Modelo no repetir.
 */
export interface NoRepeat {
  /**
   * Bandera para habilitar validacion.
   */
  isEnable?: boolean;
  /**
   * Mensaje de error.
   */
  errorText?: string;
}

/**
 * Modelo de configuracion del boton.
 */
export interface ButtonConfig {
  /**
   * Tipo de boton.
   */
  typeButton: typeButton;
  /**
   * Bandera para habilitar boton.
   */
  isEnable?: boolean;
  /**
   * Nombre del boton.
   */
  name?: string;
  /**
   * Titulo del card de agregar.
   */
  title?: string;
  /**
   * Titulo del card de agregar.
   */
  subtitle?: string;
}

/**
 * Tipo del boton.
 */
export type typeButton = 'ADD' | 'DELETE';
