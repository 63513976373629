/**
 * Modelo de tema colores.
 */
export interface ThemeColors {
  /**
   * Color primario.
   */
  primary: string;
  /**
   * Color acento.
   */
  accent: string;
  /**
   * Color secundario.
   */
  secundary: string;
  /**
   * Color peligro o alertas.
   */
  warn: string;
  /**
   * Color secundario de contraste.
   */
  accentContrast: string;
}

/**
 * Modelo de tema raiz.
 */
export interface ThemeRoot {
  /**
   * Tema.
   */
  themeColors: ThemeColors;
  /**
   * Logo de pie de pagina.
   */
  logoFooter: string;
  /**
   * Codigo de mercado.
   */
  idMarket: string;
  /**
   * Descripcion del tema.
   */
  description: string;
  /**
   * Logo.
   */
  logo: string;
}
