<div *ngIf="showInput">
  <mat-form-field *ngIf="label && catalogue === undefined" class="input-tasks-default">
    <input matInput [(ngModel)]="textInput" [disabled]="stateDisabled">
    <div class="actions">
      <mat-icon (click)="changeInput()" aria-hidden="false" aria-label="Example home icon">edit</mat-icon>
      <mat-icon (click)="successInput()" aria-hidden="false" aria-label="Example home icon">done</mat-icon>
    </div>
  </mat-form-field>

  <mat-form-field *ngIf="catalogue" class="input-tasks-default dropdown" [ngClass]="{'table-actions': actionsTable}">
    <mat-select [(value)]="selectItemDropdown" (selectionChange)="selectDropdown()" [disabled]="stateDisabled">
      <mat-option *ngFor="let cat of listCatalogue; index as i;" [value]="cat.code">
        {{cat.description | uppercase}}
      </mat-option>
    </mat-select>
    <div class="actions">
      <mat-icon (click)="changeInput()" aria-hidden="false" aria-label="Example home icon">edit</mat-icon>
      <mat-icon (click)="successInput()" aria-hidden="false" aria-label="Example home icon">done</mat-icon>
    </div>
  </mat-form-field>
</div>

<div *ngIf="!showInput">
  <div *ngIf="catalogue">
    {{label.description}}
  </div>
  <div *ngIf="label && catalogue === undefined">
    {{label}}
  </div>
</div>