import { Catalogue } from '@wp-back-office/core/commons-backoffice';

/**
 * AGREGUE DOCUMENTACION.
 */
export interface Feature {
  /**
   * AGREGUE DOCUMENTACION.
   */
  code_type_options: number;
  /**
   * AGREGUE DOCUMENTACION.
   */
  description: string;
}
/**
 * Interfaz featureInput.
 */
export interface FeatureInput extends Omit<Feature, 'description'> {
  /**
   * AGREGUE DOCUMENTACION.
   */
  code_options: number;
  /**
   * AGREGUE DOCUMENTACION.
   */
  value: number;
  /**
   * AGREGUE DOCUMENTACION.
   */
  code_financial_plan: number;
  /**
   * AGREGUE DOCUMENTACION.
   */
  characteristic_description: string;
  /**
   * AGREGUE DOCUMENTACION.
   */
  amortization_description: string;
  /**
   * AGREGUE DOCUMENTACION.
   */
  amortization_tracts: number;
}
/**
 * Interfaz FeatureValue.
 */
export interface FeatureValue {
  /**
   * AGREGUE DOCUMENTACION.
   */
  code_options?: number;
  /**
   * AGREGUE DOCUMENTACION.
   */
  code_amoritization_characteristics?: number;
  /**
   * AGREGUE DOCUMENTACION.
   */
  code_type_options: number;
  /**
   * AGREGUE DOCUMENTACION.
   */
  characteristic_description?: string;
  /**
   * AGREGUE DOCUMENTACION.
   */
  description?: string;
  /**
   * AGREGUE DOCUMENTACION.
   */
  value: string | Catalogue;
}
