import { ControlConfig } from '@wp-back-office/shared/dynamic-components';

// eslint-disable-next-line jsdoc/require-jsdoc
export const FormMocks: { schema: ControlConfig[]; type: string }[] = [
  {
    schema: [
      {
        type: 'File',
        key: 'file',
        label: 'Selecciona el documento',
        validators: {
          required: true,
          accept: 'image/jpeg,image/png,application/pdf',
          loading: true,
        },
        icon: 'description',
        col: {
          xxl: 12,
          xl: 12,
          lg: 12,
          md: 12,
          sm: 12,
          xs: 12,
        },
        tooltip: 'Formatos permitidos: JPG, PNG y PDF',
        fileOptions: {
          dragAndDrop: {
            mode: 'determinate',
          },
          progressBar: true,
        },
        progressValue: 0,
      },
    ],
    type: 'DOCUMENTS_FILE_FORM_PJ',
  },
];
