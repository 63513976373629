/**
 * Modelo de tareas.
 */
export interface Task {
  /**
   * Identificador.
   */
  id: number;
  /**
   * Titulo.
   */
  title: string;
}
