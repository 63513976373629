import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unformat'
})
export class UnformatPipe implements PipeTransform {

  mapFunctions: any = {
    currency: (val: string) => val.replace(/[^0-9.]/g, '')
  }

  transform(value: string, pipeToken: any): unknown {

    const newFormat = this.mapFunctions[pipeToken](value);

    return newFormat !== undefined ? newFormat : null;
  }

}
