import { Catalogue } from '@wp-back-office/core/store';

/**
 *{AGREGUE DOCUMENTACION}.
 */
export interface InputProcess {
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  documentNumber: string;
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  documentType: Catalogue;
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  email?: string;
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  personalInformations?: any;
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  legalInformations?: any;
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  phone?: string;
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  type?: Catalogue;
}

/**
 *{AGREGUE DOCUMENTACION}.
 */
export interface SuccessTask {
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  idProcess: string;
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  process: string;
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  taskToken: string;
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  date: string;
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  taskResult: any;
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  publisher: boolean;
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  newApplicants?: any[];
}

/**
 *{AGREGUE DOCUMENTACION}.
 */
export interface TaskStart {
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  taskInformation: any;
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  tokenCaptcha: string;
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  publisher: boolean;
}
