/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Catalogue } from '@wp-back-office/core/commons-backoffice';
import { ErrorHttp } from '@wp-back-office/process/mvp-origination';
import { ControlConfig } from '@wp-back-office/shared/dynamic-components';
import { Room } from 'libs/app/access-manager/src/lib/store/reducers';

/**
 * Modelo de grids angular.
 */
export interface Col {
  /**
   * Resolucion pequeña.
   */
  sm: number;
  /**
   * Resolucion pequeña.
   */
  lg: number;
  /**
   * Resolucion pequeña.
   */
  xs: number;
  /**
   * Resolucion pequeña.
   */
  xl: number;
  /**
   * Resolucion pequeña.
   */
  md: number;
}
/**
 * Llave de opciones.
 */
export interface KeyOptions {
  /**
   * Entidad.
   */
  entity: string;
  /**
   * Entidad.
   */
  disableLocal?: boolean;
  /**
   * Depende de.
   */
  dependsOn?: string;
}
/**
 * Modelo de keyValue con datos adicionales.
 */
export interface Option {
  /**
   * Codigo o identificador.
   */
  code: string;
  /**
   * Descripcion o nombre.
   */
  description: string;
  /**
   * Datos.
   */
  data: string;
}

/**
 * Modelo para formularios tareas pendientes.
 */
export interface Form {
  /**
   * Esquemas.
   */
  schema: ControlConfig[];
  /**
   * Version.
   */
  version?: string;
  /**
   * Descripcion.
   */
  description?: string;
  /**
   *  Tipo.
   */
  type?: string;
  /**
   *  Tipo (Dato del back).
   */
  code?: string;
  /**
   * Key para completar la modificacion (Componente de modificaciones).
   */
  completeKey?: string;
  /**
   * Key para completar la modificacion (Componente de modificaciones).
   */
  applicantCompleteKey?: string;
  /**
   * Payload inicial del formulario (Componente de modificaciones).
   */
  inputPayload?: any;
  /**
   * Payload inicial del formulario (Componente de modificaciones).
   */
  inputEditPayload?: any;
  /**
   * Payload final del formulario (Componente de modificaciones).
   */
  outputPayload?: any;
  /**
   * Cambios del formulario (Componente de modificaciones).
   */
  formChanges?: any;
  /**
   * Valid.
   */
  valid?: boolean;
}

/**
 * Modificaciones.
 */
export interface Modifications {
  /**
   * Modificaciones.
   */
  items: ModificationItem[];
  /**
   * Cantidad de items.
   */
  count: number;
}

/**
 * Item de modificacion.
 */
export interface ModificationItem {
  /**
   * Codigo.
   */
  code: string;
  /**
   * Formularios.
   */
  forms: Form[];
  /**
   * Nombre del proceso.
   */
  nameProcess: string;
  /**
   * Descripcion.
   */
  description: string;
  /**
   * Nombre de la tarea.
   */
  taskName: string;
}

/**
 * Modelo de usuario creador.
 */
export interface UserCreator {
  /**
   * Identificador.
   */
  sub: string;
  /**
   * Nombre de usuario.
   */
  username: string;
  /**
   * Codigo mercado.
   */
  marketCode: number;
}

/**
 * Modelo informacion de contacto.
 */
export interface ContactInformation {
  /**
   * Numero de celular.
   */
  mobileNumber: string;
  /**
   * Correo electronico.
   */
  email: string;
}

/**
 * Modelo de informacion personal.
 */
export interface PersonalInformation {
  /**
   * Tipo de documento.
   */
  documentType: KeyValue;
  /**
   * Numero de documento.
   */
  documentNumber: string;
  /**
   * Lugar de expedicion.
   */
  expeditionPlace: string;
  /**
   * Nombres.
   */
  names: string;
  /**
   * Apellidos.
   */
  lastNames: string;
  /**
   * Lugar de nacimiento.
   */
  birthCountry: KeyValue;
  /**
   * Departamento.
   */
  state: KeyValue;
  /**
   * Ciudad.
   */
  city: KeyValue;
  /**
   * Bandera pep.
   */
  PEP: boolean;
}

/**
 * Modelo Informacion del aplicante.
 */
export interface Information {
  /**
   * Bandera verificaion de email.
   */
  hasEmailVerification: boolean;
  /**
   * Informacion de contacto.
   */
  contactInformation: ContactInformation;
  /**
   * Bandera verificacion telefonica.
   */
  hasMobileVerification: boolean;
  /**
   * Informacion personal.
   */
  personalInformation: PersonalInformation;
}

/**
 * Modelo aplicante.
 */
export interface Applicant {
  /**
   * Tipo de aplicante.
   */
  type: KeyValue;
  /**
   * Informacion del aplicante.
   */
  information: Information;
}

/**
 * Modelo de aplicantes iniciales.
 */
export interface InitialApplication {
  /**
   * Aplicantes.
   */
  applicants: Applicant[];
}

/**
 * Modelo de validaciones.
 */
export interface Validations {
  /**
   * Validacion de regla De Decision Buro De Credito.
   */
  reglaDeDecisionBuroDeCredito: Flags;
  /**
   * Validacion de politicas internas.
   */
  politicasInternas: Flags;
  /**
   * Validacion lista de control Mobilize Financial Service.
   */
  listaDeControlRCI: Flags;
}

/**
 * Modelo de banderas proovedores.
 */
export interface Flags {
  /**
   * Bandera de registro.
   */
  register: boolean;
}

/**
 * Modelo de provedores.
 */
export interface Providers {
  /**
   * Integracion Buro De Credito Basica.
   */
  integracionBuroDeCreditoBasica: Flags;
  /**
   * Obtener Cliente Core.
   */
  ObtenerClienteCore: Flags;
}

/**
 * Informacion general de la tarea.
 */
export interface TaskInformation {
  /**
   * Usuario creador.
   */
  userCreator: UserCreator;
  /**
   * Aplicacion inicial.
   */
  initialApplication: InitialApplication;
  /**
   * Bandera persona natural.
   */
  personaNatural: boolean;
  /**
   * Canal.
   */
  saleChannel: string;
  /**
   * Reglas y validaciones.
   */
  validations: Validations;
  /**
   * Fecha de creación.
   */
  creationDate: number;
  /**
   * Tenant identificador.
   */
  tenantId: string;
  /**
   * Fecha formateada.
   */
  dataFormatted: any;
  /**
   * Canal identificacion.
   */
  chanel: string;
  /**
   * Proovedores.
   */
  providers: Providers;
}

/**
 * Modelo llave valor.
 */
export interface KeyValue {
  /**
   * Identificador.
   */
  code: string;
  /**
   * Descripcion o nombre.
   */
  description: string;
}

/**
 * Modelo de aplicantes.
 */
export interface Applicants {
  /**
   * Tipo de aplicante.
   */
  type: KeyValue;
  /**
   * Tipo de documento.
   */
  documentType: KeyValue;
  /**
   * Numero de documento.
   */
  documentNumber: string;
  /**
   * Dígito de verificacion.
   */
  legalStatus: string;
  /**
   * Nombre de la compania.
   */
  companyName?: string;
  /**
   * Numero de telefono.
   */
  phone?: string;
  /**
   * Correo electronico.
   */
  email?: string;
  /**
   * Correo electronico.
   */
  sellerInfo?: Room;
  /**
   * Tipo producto.
   */
  productType?: Catalogue;
}

/**
 * Modelo de pendientes.
 */
export interface FormTask {
  /**
   * Identificador.
   */
  id: string;
  /**
   * Nombre de la tarea.
   */
  taskName: string;
  /**
   * Informacion de tareas.
   */
  taskInformation: TaskInformation;
  /**
   * Proceso.
   */
  process: string;
  /**
   * Fecha de creación.
   */
  dateCreated: number;
  /**
   * Fecha de expiración.
   */
  dateExpiration: string;
  /**
   * Aplicantes.
   */
  applicants: Applicants[];
  /**
   * Formularios.
   */
  forms: Form[];
  /**
   * Campos editables.
   */
  editableFields: any[];
  /**
   * Informacion.
   */
  payload: any;
}

/**
 * Modelo respuesta formularios.
 */
export interface FormTaskSchemaState {
  /**
   * Objecto.
   */
  taskPendingInfo: FormTask;
  /**
   * Bandera de cargado.
   */
  loaded: boolean;
  /**
   * Bandera de cargando.
   */
  loading: boolean;
  /**
   * Errores.
   */
  error?: ErrorHttp;
}
