/**
 *
 */
interface PathDetectOptions {
  /**
   *
   */
  path?: string;
  /**
   *
   */
  deletedValue?: undefined | null | '';
}
/**
 * Detecta los cambios en un dato y retorna la ruta y el cambio.
 * @param obj1 - Objeto previo.
 * @param obj2 - Objecto actual.
 * @param path - Ruta.
 * @param deletedValue - Valor eliminado por defecto.
 * @param options
 * @returns - Array de cambios.
 */
export function pathDetectChanges(
  obj1: any,
  obj2: any,
  options?: PathDetectOptions
): [string, any][] {
  const path = options?.path || '';
  const deletedValue = options?.deletedValue || undefined;
  const changes: [string, any][] = [];

  /**
   * Funcion para crear ruta.
   * @param key - Clave.
   * @param isArray - Bandera si es array.
   * @returns - String.
   */
  const buildPath = (key: string | number, isArray: boolean): string =>
    isArray ? `${path}[${key}]` : path ? `${path}.${key}` : `${key}`;

  if (
    typeof obj1 !== typeof obj2 ||
    Array.isArray(obj1) !== Array.isArray(obj2)
  ) {
    changes.push([path, obj2]);
    return changes;
  }

  if (typeof obj1 !== 'object' || obj1 === null || obj2 === null) {
    if (obj1 !== obj2) {
      changes.push([path, obj2]);
    }
    return changes;
  }

  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    const maxLength = Math.max(obj1.length, obj2.length);
    for (let i = 0; i < maxLength; i++) {
      const newPath = buildPath(i, true);

      if (i >= obj2.length) {
        changes.push([newPath, deletedValue]);
      } else if (i >= obj1.length) {
        changes.push([newPath, obj2[i]]);
      } else {
        changes.push(
          ...pathDetectChanges(obj1[i], obj2[i], {
            deletedValue,
            path: newPath,
          })
        );
      }
    }
  } else {
    const keys = new Set([
      ...Object.keys(obj1 || {}),
      ...Object.keys(obj2 || {}),
    ]);
    keys.forEach(key => {
      const newPath = buildPath(key, false);
      changes.push(
        ...pathDetectChanges(obj1[key], obj2[key], {
          deletedValue,
          path: newPath,
        })
      );
    });
  }

  return changes;
}
