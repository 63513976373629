import { Component, HostListener, Input, OnInit } from '@angular/core';
import { DocumentsTasksService } from '@wp-back-office/core/commons-backoffice';
import {
  UpdateDataTaskService,
  WorkflowService,
} from '@wp-back-office/core/workflow-sdk';
import { Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-input-document',
  templateUrl: './input-document.component.html',
  styleUrls: ['./input-document.component.scss'],
})
export class InputDocumentComponent implements OnInit {
  @Input() document!: any;
  @Input() key = '';
  @Input() actionsTable = false;
  @Input() label: any;

  enabledInputs: string[] = [];

  urlDocument = '';
  stateDisabled = true;
  showInput = false;
  editableInput = false;

  textInput: any = '';

  subjectFile$: Subject<any> = new Subject<any>();
  dataDocument: any;
  loadingFile = false;
  errorFile = false;
  successFile: boolean | null = null;
  onChange: any = new Function();
  file: File | null = null;
  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    const file = event && event.item(0);
    this.selectFile(file);
    this.file = file;
  }
  /**
   * Destructor sujeto
   */
  private destroy$ = new Subject();

  constructor(
    private documentsService: DocumentsTasksService,
    private workflowService: WorkflowService,
    private updateDataTaskSerrvice: UpdateDataTaskService
  ) {
    this.enabledInputs = this.updateDataTaskSerrvice.updateInputs;
  }

  ngOnInit(): void {
    this.enabledInputs.forEach((input: string) => {
      if (this.key.startsWith(input)) {
        this.showInput = true;
        this.editableInput = true;
      }
    });

    if (this.document !== undefined) {
      this.documentsService
        .getTemporyUrls([this.document.document.key])
        .pipe(takeUntil(this.destroy$))
        .subscribe(ev => (this.urlDocument = ev));
    }
  }

  changeDocument() {
    this.stateDisabled = false;

    this.workflowService
      .getDataUploadFile(
        this.document.options.id,
        this.document.options.process,
        this.document.document
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((ev: any) => {
        this.dataDocument = ev.documents[0];
      });
  }

  selectFile(file: any) {
    this.loadingFile = true;
    const formFile = this.createBodyFiles(this.dataDocument, file);
    this.workflowService
      .uploadFile({
        url: this.dataDocument.url,
        body: formFile,
      })
      .pipe(
        catchError((err: any) => {
          this.loadingFile = false;
          this.errorFile = true;
          return err;
        })
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((ev: any) => {
        this.loadingFile = false;
        this.errorFile = false;
        this.successFile = true;

        this.textInput = {
          ...this.document.document,
          version: ev.headers.get('x-amz-version-id'),
        };
        this.successChange();
      });
  }

  createBodyFiles(item: any, file: any) {
    const formData = new FormData();
    formData.append('Content-Type', `${item.fields['x-amz-meta-type']}`);
    Object.entries(item.fields).forEach(([k, v]) => {
      formData.append(k, String(v));
    });
    formData.append('file', file);

    return formData;
  }

  successChange() {
    this.stateDisabled = true;
    this.updateDataTaskSerrvice.dataUpdateTask.next({
      key: this.key,
      value: this.textInput,
    });
  }

  /**
   * A callback method that performs custom clean-up, invoked immediately before a directive, pipe, or service instance is destroyed.
   */
  public ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }
}
