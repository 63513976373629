<ng-template [ngIf]="controlConfig && controlConfig.key && controlConfig.type">
  <mat-form-field
    class="{{ controlConfig.classes || '' }}"
    [appearance]="controlConfig.appearance || 'outline'"
    ngDefaultControl>
    <mat-label [attr.for]="controlConfig.key">
      {{ controlConfig.label || '' }}
    </mat-label>

    <ng-container *ngIf="!controlConfig.validators?.chipList">
      <input
        matInput
        [min]="
          controlConfig.type === 'Number' || controlConfig.type === 'Currency'
            ? controlConfig.validators?.min
            : undefined
        "
        [max]="
          controlConfig.type === 'Number' || controlConfig.type === 'Currency'
            ? controlConfig.validators?.max
            : undefined
        "
        [type]="showEye ? 'text' : typeControl"
        [aria-describedby]="controlConfig.label || ''"
        [placeholder]="controlConfig.placeholder || controlConfig.label || ''"
        [formControl]="_formControl"
        [id]="uniqueIdForm + controlConfig.key"
        (focus)="onFocus($event)"
        (focusout)="onFocusOut($event)"
        (keydown.space)="
          controlConfig.type === 'Email' ? $event.preventDefault() : null
        "
        (paste)="(controlConfig.validators?.disablePaste ? false : true)"
        [readonly]="
          controlConfig.validators?.readonly
            ? controlConfig.validators?.readonly
            : false
        "
        [wpBackOfficeTextTransform]="controlConfig.validators?.textTransform"
        [wpBackOfficePresition]="controlConfig.validators?.precision" />
    </ng-container>
    <mat-error
      *ngIf="!controlConfig.validators?.chipList"
      [innerHTML]="
        dynamicFormService.errorMessage(_formControl, controlConfig)
      ">
    </mat-error>

    <ng-container *ngIf="controlConfig.validators?.chipList">
      <mat-chip-list #chipList multiple [formControl]="_formControl">
        <mat-chip
          *ngFor="let keyword of valideArray(_formControl.value); index as i"
          [selected]="keyword"
          [value]="keyword"
          (removed)="removeKeyword(i)">
          {{ keyword }}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
        <input
          [matChipInputFor]="chipList"
          (matChipInputTokenEnd)="addKeywordFromInput($event)"
          matInput
          [type]="showEye ? 'text' : typeControl"
          [aria-describedby]="controlConfig.label || ''"
          [(ngModel)]="chipInput"
          [placeholder]="controlConfig.placeholder || controlConfig.label || ''"
          [id]="controlConfig.key"
          (focus)="onFocus($event)"
          (focusout)="onFocusOut($event)"
          (paste)="(controlConfig.validators?.disablePaste ? false : true)"
          [readonly]="
            controlConfig.validators?.readonly
              ? controlConfig.validators?.readonly
              : false
          "
          [wpBackOfficeTextTransform]="
            controlConfig.validators?.textTransform
          " />
      </mat-chip-list>
    </ng-container>
    <mat-error *ngIf="controlConfig.validators?.chipList" class="d-flex">
      <div
        *ngIf="
          _formControl.hasError('notArray') ||
          (controlConfig.validators?.required &&
            valideArray(_formControl.value).length === 0)
        ">
        Escriba y presiona <b>ENTER</b> para agregar a la lista.
      </div>
      <div
        *ngIf="!_formControl.hasError('notArray')"
        [innerHTML]="
          dynamicFormService.errorMessage(_formControl, controlConfig)
        "></div>
    </mat-error>
    <mat-hint
      *ngIf="
        controlConfig.validators?.chipList && chipInput && chipInput !== ''
      ">
      Escriba y presiona <b>ENTER</b> para agregar a la lista.
    </mat-hint>
    <mat-icon matSuffix *ngIf="controlConfig?.icon">
      {{ controlConfig.icon || '' }}
    </mat-icon>
    <div matSuffix *ngIf="typeControl === 'password'">
      <div *ngIf="_formControl.value" class="d-flex justify-content-end mr-1">
        <div
          class="show__password"
          (click)="showEye ? (showEye = false) : (showEye = true)">
          <mat-icon>
            {{ showEye ? 'visibility_off' : 'visibility' }}
          </mat-icon>
        </div>
      </div>
    </div>
  </mat-form-field>
  <div
    class="input-spinner"
    *ngIf="controlConfig.validators?.loading || controlConfig?.loading">
    <mat-spinner color="primary" [diameter]="25"> </mat-spinner>
  </div>
</ng-template>
