// MODULE
export * from './dynamic-tabs.module';

// DIRECTIVES
export * from './directives/factory-tab.directive';

// MODELS
export * from './models/tabs-config.model';

// COMPONENTS
export * from './components/dynamic-tabs/dynamic-tabs.component';
export * from './components/dynamic-tabs-general/dynamic-tabs-general.component';
export * from './components/dialogs/add-tab/add-tab.component';
