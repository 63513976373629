/**
 * Modelo constantes logueo.
 */
export interface ConstantsLogin {
  /**
   * Logo de aplicaciones.
   */
  applicationLogo: string;
  /**
   * Logo aplicaciones mobile.
   */
  applicationLogoMobile: string;
  /**
   * Fondo del login.
   */
  backgroundLogin: string;
  /**
   * Fondo del login 1.
   */
  backgroundLogin1: string;
  /**
   * Icono de usuario.
   */
  iconUser: string;
  /**
   * Icono de contraseña.
   */
  iconPassword: string;
  /**
   * Iconos de mostrar info.
   */
  iconShowEyes: string;
  /**
   * Icono de ocultar info.
   */
  iconHideEyes: string;
  /**
   * Texto de derechos reservados.
   */
  textCopyright: string;
}
