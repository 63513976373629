import { Component } from '@angular/core';

/**
 * Componente de tareas.
 */
@Component({
  selector: 'wp-back-office-human-task-sdk',
  template: ` <p>human-task-sdk works!</p> `,
  styles: [],
})
export default class HumanTaskSdkComponent {
  /**
   * Crea una instancia de  HumanTaskSdkComponent.
   */
}
