/**
 * Modelo catalogo.
 */
export interface Catalogue {
  /**
   * Identificador.
   */
  code: string;
  /**
   * Valor.
   */
  description: string;
}
/**
 * Modelo Catalogo Extendido.
 */
export type CatalogueExt<T> = Catalogue & T;
