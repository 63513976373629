<div *ngIf="document && showInput" class="input-tasks-default href" [ngClass]="{'table-actions': actionsTable}">
    <div *ngIf="stateDisabled"><a [href]="urlDocument" target="_blank">
            <div [ngClass]="{'txt-success': successFile === true, 'txt-error': errorFile === true}">{{label}}</div>
        </a></div>
    <div class="file" *ngIf="!stateDisabled">
        <div *ngIf="!loadingFile">
            <label for="input-file">Seleccione archivo...</label>
            <input id="input-file" class="input-file" type="file" [accept]="document.document.type">
        </div>
        <div *ngIf="loadingFile" class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div class="actions">
        <mat-icon (click)="changeDocument()" aria-hidden="false" aria-label="Example home icon">edit</mat-icon>
    </div>
</div>

<div *ngIf="!showInput">
    <div *ngIf="document">
        <a [href]="urlDocument" target="_blank">{{label}}</a>
    </div>
</div>