import { Catalogue } from "../../../catalogue/models";

/**
 * Modelo informacion.
 */
export interface Info {
  /**
   * Nombre usuario.
   */
  name: string;
  /**
   * Fecha.
   */
  date: Date;
}

/**
 * Modelo de siguiente tarea.
 */
export interface NextTask {
  /**
   * Proceso.
   */
  process: string;
  /**
   * Estado.
   */
  state: Info;
}

/**
 * Modelo respuestar iniciar.
 */
export interface ResponseStart {
  /**
   * Identificador de ejecucion.
   */
  idExecution: string;
  /**
   * Siguiente tarea.
   */
  nextTask: NextTask;
  /**
   * Token de tarea.
   */
  taskToken: string;
}

/**
 * Modelo.
 */
export interface applicants{
  /**
   * Tipo de aplicantes.
   */
  type: Catalogue;
  /**
   * Modelo de informacion personal.
   */
  personalInformations : any;
}
