/* eslint-disable jsdoc/require-description */
import { Catalogue } from '@wp-back-office/core/commons-backoffice';
import { FeatureValue } from './feature.model';
import { Segment } from './segment.model';
import { ComissionsReceived } from './service.model';

/**
 * Interfaz de item para plan financiero.
 */
export interface FinancialPlanItem
  extends Omit<
    FinancialPlanOutput,
    | 'rbp_option_assignment'
    | 'characteristics'
    | 'services'
    | 'tracts'
    | 'segmentations'
  > {
  /**
   * Estado del plan.
   */
  isActive: boolean;
}

/**
 * Interfaz de entrada para plan financiero.
 */
export interface FinancialPlanInput {
  /**
   *
   */
  description: string;
  /**
   *
   */
  name: string;
  /**
   *
   */
  date_start: Date;
  /**
   *
   */
  date_limit: string | number | Date;
  /**
   *
   */
  date_disbursement_limit: Date;
  /**
   *
   */
  code_type_price: number;
  /**
   *
   */
  code_amortization: number;
  /**
   *
   */
  code_priority_financial_plan: number;
  /**
   *
   */
  characteristics: CharacteristicInput[];
  /**
   *
   */
  tracts: Omit<TractInput, 'id'>[];
  /**
   *
   */
  segmentations: SegmentationInput[];
  /**
   *
   */
  code_rbp_options: number;
  /**
   *
   */
  rbp_option_value: number;
  /**
   *
   */
  services: Omit<ServiceReference, 'code_services'>[];
  /**
   *
   */
  models: string;
  /**
   *
   */
  vh_type_services: string;
  /**
   *
   */
  families: string;
  /**
   *
   */
  versions: string;
}

/**
 *
 */
export interface Tester003 {
  /**
   *
   */
  status: boolean;
  /**
   *
   */
  code: number;
  /**
   *
   */
  body: FinancialPlanOutput;
}

/**
 *
 */
export interface FinancialPlanOutput
  extends Omit<
    FinancialPlanInput,
    'code_rbp_options' | 'rbp_option_value' | 'services'
  > {
  /**
   *
   */
  code_financial_plan: number;
  /**
   *
   */
  updated_date: Date;
  /**
   *
   */
  created_date: Date;
  /**
   *
   */
  rbp_option_assignment: RbpOptionAssignment;
  /**
   *
   */
  services: Omit<ServiceReference, 'code'>[];
}

/**
 *
 */
export interface FinancialPlanDetail extends FinancialPlanOutput {
  /**
   *
   */
  type_price_description: string;
  /**
   *
   */
  prices: Price[];
  /**
   *
   */
  characteristics: CharacteristicDetail[];
  /**
   *
   */
  services: ServiceReferenceDetail[];
  /**
   *
   */
  tracts: TractInput[];
  /**
   *
   */
  segmentations: SegmentationDetail[];
  /**
   *
   */
  amortization_description: string;
  /**
   *
   */
  payment_frecuency_descripton: string;
  /**
   *
   */
  amortization_extra_fields_to_cotization: AmortizationExtraFieldsToCotization[];
  /**
   *
   */
  length_payment_frecuency?: number;
  /**
   *
   */
  models: string;
  /**
   *
   */
  vh_type_services: string;
  /**
   *
   */
  families: string;
  /**
   *
   */
  versions: string;
}

/**
 *
 */
export interface AmortizationExtraFieldsToCotization {
  /**
   *
   */
  code_amortization: number;
  /**
   *
   */
  code_extra_fields: number;
  /**
   *
   */
  description_extra_fields: string;
  /**
   *
   */
  key: string;
}
/**
 *
 */
export interface LogFinancialPlan {
  /**
   *
   */
  financialPlan: FinancialPlanDetail;
  /**
   *
   */
  date: Date;
  /**
   *
   */
  user_id: string;
  /**
   *
   */
  ip_client: string;
  /**
   *
   */
  full_name_user: string;
  /**
   *
   */
  user_email: string;
}
/**
 *
 */
export interface CharacteristicInput {
  /**
   *
   */
  code_options?: number;
  /**
   *
   */
  code_type_options: number;
  /**
   *
   */
  value: number;
  /**
   *
   */
  required: boolean;
}

/**
 *
 */
export interface CharacteristicDetail extends CharacteristicInput {
  /**
   *
   */
  characteristic_description: string;
  /**
   *
   */
  amortization_description: string;
  /**
   *
   */
  amortization_tracts: number;
  /**
   *
   */
  code_amoritization_characteristics: number;
}

/**
 *
 */
export interface TractInput {
  /**
   *
   */
  id?: number;
  /**
   *
   */
  code_tract?: number;
  /**
   *
   */
  code_option_tract?: number | null;
  /**
   *
   */
  description?: number | '';
  /**
   *
   */
  initial_term: number;
  /**
   *
   */
  final_term: number;
  /**
   *
   */
  client_rate?: number;
}
/**
 *
 */
export interface TractForm
  extends Pick<TractInput, 'id' | 'code_tract' | 'code_option_tract'> {
  /**
   *
   */
  initial_term: string | '';
  /**
   *
   */
  final_term: string | '';
  /**
   *
   */
  client_rate?: string;
  /**
   *
   */
  description?: Catalogue | any;
  /**
   *
   */
  edit?: boolean;
}

/**
 *
 */
export interface SegmentationInput {
  /**
   *
   */
  code_segmentation: number;
}

/**
 *
 */
export interface SegmentationDetail extends SegmentationInput {
  /**
   *
   */
  segmentation_description: string;
  /**
   *
   */
  segmentation_name: string;
}

/**
 *
 */
export interface ServiceReference {
  /**
   *
   */
  code: number;
  /**
   *
   */
  code_services: number;
  /**
   *
   */
  code_type_financing_service: number;
  /**
   *
   */
  subvention?: number | '';
  /**
   *
   */
  services_subvention?: any[];
}
/**
 *
 */
export interface ServiceForm {
  /**
   *
   */
  code: number;
  /**
   *
   */
  subvention?: any;

  /**
   *
   */
  rate?: string;
  /**
   *
   */
  type_financing_service: Catalogue | '';
  /**
   *
   */
  description: string;
  /**
   *
   */
  comissions_receiveds?: ComissionsReceived[];
  /**
   *
   */
  edit: boolean;
  /**
   *
   */
  service_type: number;
  /**
   *
   */
  canBeHidden?: boolean;
}
/**
 *
 */
export interface ServiceReferenceDetail extends Omit<ServiceReference, 'code'> {
  /**
   *
   */
  description: string;
  /**
   *
   */
  tariff: number;
  /**
   *
   */
  iva: number;
  /**
   *
   */
  service_type: number;
  /**
   *
   */
  automatic_protection: number;
}

/**
 *
 */
export interface RbpOptionAssignment {
  /**
   *
   */
  code_rbp_assignment: number;
  /**
   *
   */
  code_rbp_options: number;
  /**
   *
   */
  value: number;
  /**
   *
   */
  description?: string;
}

/**
 *
 */
export interface Price {
  /**
   *
   */
  code_price: number;
  /**
   *
   */
  code_model: number;
  /**
   *
   */
  type_price: number;
  /**
   *
   */
  type_currency: number;
  /**
   *
   */
  subvention: number;
  /**
   *
   */
  value: number;
  /**
   *
   */
  code_vehicle: number;
  /**
   *
   */
  model_description: string;
  /**
   *
   */
  code_version: number;
  /**
   *
   */
  code_fasecolda: string;
  /**
   *
   */
  gearbox_type: string;
  /**
   *
   */
  weight: number;
  /**
   *
   */
  cylinder_capacity: number;
  /**
   *
   */
  passenger_capacity: number;
  /**
   *
   */
  load_capacity: number;
  /**
   *
   */
  doors: number;
  /**
   *
   */
  transmission: string;
  /**
   *
   */
  air_conditioning: number;
  /**
   *
   */
  horsepower: number;
  /**
   *
   */
  code_family: number;
  /**
   *
   */
  version_description: string;
  /**
   *
   */
  code_line: number;
  /**
   *
   */
  family_description: string;
  /**
   *
   */
  line_description: string;
  /**
   *
   */
  code_brand: number;
  /**
   *
   */
  brand_description: string;
}

/**
 *
 */
export interface FinancialPlan {
  /**
   *
   */
  detail?: DetailFinancialPlan;
  /**
   *
   */
  amortization?: Catalogue;
  /**
   *
   */
  type_price?: Catalogue;
  /**
   *
   */
  features?: FeatureFinancialPlan;
  /**
   *
   */
  tracts: TractForm[];
  /**
   *
   */
  segments: Segment[];
  /**
   *
   */
  services: ServiceForm[];
  /**
   *
   */
  models: string;
  /**
   *
   */
  vh_type_services: string;
  /**
   *
   */
  families: string;
  /**
   *
   */
  versions: string;
}
/**
 *
 */
export type DetailFPCatalogueEnum = 'rbp_options' | 'priority_financial_plan';
/**
 *
 */
export type DetailFPDateEnum =
  | 'date_start'
  | 'date_limit'
  | 'date_disbursement_limit';
/**
 *
 */
type DetailFPCatalogue = {
  [key in DetailFPCatalogueEnum]: Catalogue;
};
/**
 *
 */
type DetailFPDate = {
  [key in DetailFPDateEnum]: Date;
};
/**
 *
 */
export interface DetailFinancialPlan extends DetailFPCatalogue, DetailFPDate {
  /**
   *
   */
  description: string;
  /**
   *
   */
  name: string;
  /**
   *
   */
  rbp_options_value: number;
  /**
   *
   */
  is_valid: boolean;
}

/**
 *
 */
export interface FeatureFinancialPlan {
  /**
   *
   */
  items: FeatureValue[];
  /**
   *
   */
  is_valid: boolean;
}
