import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe para filtrar arreglos.
 */
@Pipe({
  name: 'filterArray',
  pure: false,
})
// eslint-disable-next-line @nrwl/nx/workspace/doc-elements-angular
export class FilterArrayPipe implements PipeTransform {
  /**
   * Transformacion de datos.
   * @param items - Arreglo.
   * @param callback - Filtro callback.
   * @returns Arreglo filtrado.
   */
  public transform(items: any[], callback: (item: any) => boolean): any {
    if (!items || !callback) {
      return items;
    }
    return items.filter(item => callback(item));
  }
}
