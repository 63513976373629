import { Injectable } from '@angular/core';
import { DynamicStepperState } from '../store';
import { Store } from '@ngrx/store';
import { taskPjActions } from '../store/actions';
import { Form } from '../models/form-task.models';

/**
 * Servicio del dynamic stepper.
 */
@Injectable()
export class DynamicStepperService {
  /**
   * Crea una instancia de PrivateDynamicStepperService.
   * @param store - Store.
   */
  constructor(private store: Store<DynamicStepperState>) {}
  /**
   * Inicializa el formulario.
   * @param params - Parametros.
   */
  public initialForm(params: Form[]): void {
    this.store.dispatch(taskPjActions.reset());
    this.store.dispatch(
      taskPjActions.setSchema({
        forms: params,
      })
    );
  }
  /**
   * Resetea el formulario.
   */
  public reset(): void {
    this.store.dispatch(taskPjActions.reset());
  }
}
