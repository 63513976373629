/**
 * Modelo de rutas.
 */
export interface RouteParams {
  /**
   * Objetivo.
   */
  target?: string;
  /**
   * Proceso.
   */
  process?: string;
  /**
   * Tipo de parametros.
   */
  typeParams?: string;
  /**
   * Paginacion.
   */
  pagination?: Page;
  /**
   * Tabla.
   */
  table: Table;
}

/**
 * Modelo de pagina.
 */
export interface Page {
  /**
   * Tamaño de la pagina.
   */
  sizePage?: number;
  /**
   * Pagina actual.
   */
  currentPage?: number;
}

/**
 * Modelo Tabla.
 */
export interface Table {
  /**
   * Encabezados.
   */
  headers?: string[];
  /**
   * Datos.
   */
  data?: any;
}

/**
 * Modelo formateado para la tarea.
 */
export interface FormatedTask {
  /**
   * Identificador.
   */
  id?: string;
  /**
   * Identificador.
   */
  externalId?: string;
  /**
   * Procesos.
   */
  process?: string;
  /**
   * Nombre.
   */
  name?: string;
  /**
   * Fecha de creación.
   */
  dateCreated?: string;
  /**
   * Fecha de expiración.
   */
  dateExpiration?: string;
  /**
   * Referencias.
   */
  href?: string[];
  /**
   * PaginatioToken.
   */
  lastKey?: string;
  /**
   * Aplicantes.
   */
  applicants?: any[];
  /**
   * Tags.
   */
  tags?: string[] | string;
  /**
   * Titular.
   */
  titular?: any;
  /**
   * Documento del titular.
   */
  documentNumber: number;
}
/**
 * Modelo chores.
 */
export interface Chores {
  /**
   * Identificador.
   */
  id: string;
  /**
   * Nombre.
   */
  externalId: string;
  /**
   * Nombre.
   */
  taskName?: string;
  /**
   * Nombre.
   */
  name?: string;
  /**
   * Proceso.
   */
  process: string;
  /**
   * Token de la tarea.
   */
  taskToken: string;
  /**
   * Fecha de creación.
   */
  dateCreated: string;
  /**
   * Fecha de expiración.
   */
  dateExpiration: string;
  /**
   * PaginatioToken.
   */
  lastKey?: string;
  /**
   * Aplicantes.
   */
  applicants?: any[];
  /**
   * Tags.
   */
  tags?: string[] | string;
  /**
   * Titular.
   */
  titular?: any;
}
