/**
 * Modelo de auditar procesos.
 */
export interface AuditProcessModel {
  /**
   * Total.
   */
  total: Total;
  /**
   * Maximo.
   */
  max_score: number;
  /**
   * Hits.
   */
  hits: [];
}

/**
 * Modelo de total.
 */
export interface Total {
  /**
   * Valor.
   */
  value: number;
  /**
   * Relación.
   */
  relation: string;
}
