/**
 * Tipos de SnackBar.
 */
export type SnackBarType = 'success' | 'error' | 'info' | 'warning';

/**
 * Tipos de botones.
 */
export type buttonSelector =
  | 'mat-button'
  | 'mat-raised-button'
  | 'mat-flat-button'
  | 'mat-icon-button'
  | 'mat-stroked-button'
  | 'mat-fab'
  | 'mat-mini-fab';
