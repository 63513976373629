import { createAction, props } from '@ngrx/store';
import { Catalogue, CatalogueType } from '../../../models/contents/catalogue.models';

export const KeyValueProductType = createAction(
  '[KeyValueProductType] Cargar productos por tipo',
  props<{
    /**
     * Ruta.
     */
    path: CatalogueType;
  }>()
);

export const KeyValueProductTypeSuccess = createAction(
  '[KeyValueProductType] Crear productos por tipo Exitoso',
  props<{
    /**
     * Llave Valor.
     */
    keyValue: Catalogue[];
  }>()
);

export const KeyValueProductTypeError = createAction(
  '[KeyValueProductType] Cargar productos por tipo Error',
   props<{
    /**
     * Payload.
     */
    payload: any;
  }>()
);
