import { Injectable } from '@angular/core';
import { DynamicFormComponent } from '../../dynamic-form';

/**
 * Servicio del dynamic stepper.
 */
@Injectable()
export class PrivateDynamicStepperService {
  /**
   * Validación.
   * @param component - Componente.
   * @returns Boolean.
   */
  public isValid(component?: DynamicFormComponent): boolean {
    if (component) {
      return (
        component &&
        component.formGroupGeneric &&
        component.formGroupGeneric.valid
      );
    } else {
      return true;
    }
  }
}
