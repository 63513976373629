import { Validators } from '@angular/forms';


export default {
  consolidatedIncome: {
    validations: [
      {
        messageError: 'Test de error.',
        validators: Validators.required
      }
    ]
  }
}