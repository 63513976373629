<h2 mat-dialog-title>{{ title }}</h2>
<mat-divider></mat-divider>
<mat-dialog-content class="pt-4 pb-3">
  <ng-template wpBackOfficeFactoryDialogComponent></ng-template>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="end">
  <button mat-button color="primary" mat-dialog-close>Cancelar</button>
  <button
    mat-button
    color="accent"
    [mat-dialog-close]="true"
    *ngIf="!disabledSubmit">
    Guardar
  </button>
</mat-dialog-actions>
